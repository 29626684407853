import { black } from '@/constants/Colors';
import { tituloH1 } from '@/constants/Dimens';
import { Typography } from '@mui/material';


//Definimos los tipos de datos que va a recibir el componente en forma de interfaz
interface TituloH1Props {
    texto: string;
    color?: string;
    fontSize?: string;
}

function TituloSeccionH2({ texto, color, fontSize = tituloH1 }: TituloH1Props) {

    //Tamaño de texto a 25 px
    //Color de texto a primario

    const style: React.CSSProperties = {
        fontSize: fontSize,
        color: color ? color : black,
        fontWeight: '300',
        userSelect: 'none',
        marginTop: '10px',
        marginBottom: '10px'
    };

    
    return (
        <Typography component="h2" variant="h5" sx={{ mt: 1 }} style={style}>
            {texto}
        </Typography>
    );

}

export default TituloSeccionH2;
