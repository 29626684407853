import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { primary, red } from '../../constants/Colors';
import { textoBotones } from '../../constants/Dimens';
import { useTranslation } from 'next-i18next';
import TextFieldCustom from './TextFieldCustom';

let setOpenExt: (arg: boolean) => void;
let setParamsExt: (arg: AlertParams) => void;

const AlertDialog: React.FC<AlertParams> = () => {

    const { t } = useTranslation();

    const [open, setOpen] = React.useState(false);
    const [props, setParams] = React.useState<AlertParams>({});

    const [valueText, setValueText] = React.useState<string>('');

    setOpenExt = setOpen;
    setParamsExt = (params: AlertParams) => {
        setParams(params);
    };

    const boton: React.CSSProperties = {
        color: primary,
        //Texto sin mayusculas
        textTransform: 'none',
        fontWeight: 'bold',
        fontSize: textoBotones
    };

    const botonCancelar = {
        color: red
    };

    const handleClose = (event: {}, reason?: string) => {
        if (props.disableCancelable && reason === 'backdropClick') {
            return;
        }
        setOpen(false);
        if (props.onCancel) {
            props.onCancel();
        }
    };


    const handleAgree = () => {
        setOpen(false);
        if (props.onAccept) {
            if (props.labelValueText !== undefined) {
                props.onAccept(valueText);
                //vaciar el campo de texto
                setValueText('');
            } else {
                props.onAccept();
            }
        }
    };

    return (
        <React.Fragment>
            <Dialog style={{ zIndex: 999 }}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                        maxWidth: '500px', // Establecer el ancho máximo según tus necesidades
                        minWidth: '300px',
                    },
                }}>
                <DialogTitle id="alert-dialog-title">
                    <div style={{ userSelect: 'none' }}>
                        {props.title}
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div style={{ userSelect: 'none' }}>
                            {props.content}
                        </div>
                    </DialogContentText>
                    {props.labelValueText !== undefined && (
                        <TextFieldCustom
                            setValue={setValueText}
                            label={props.labelValueText ?? ''}
                            required
                            fullWidth
                            multiline
                            minRows={4}
                            showError={false}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    {props.showCancelButton && (
                        <Button onClick={handleClose} style={{ ...boton, ...botonCancelar }}>
                            {props.cancelText || t("common:opciones.cancelar")}
                        </Button>
                    )}
                    <Button onClick={handleAgree} style={boton}>
                        {props.acceptText || t("common:opciones.aceptar")}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export interface AlertParams {
    title?: string;
    content?: string | React.ReactNode;
    cancelText?: string;
    acceptText?: string;
    showCancelButton?: boolean;
    disableCancelable?: boolean;
    onCancel?: () => void;
    onAccept?: (texto?: any) => void;
    labelValueText?: string;

}

export default AlertDialog;

export const mostrarAlerta = (params: AlertParams) => {
    setOpenExt(true);
    setParamsExt(params);
    // Puedes acceder a los atributos pasados a través de params en lugar de props
};
