// components/ArticleList.tsx
import React from 'react';
import { useTranslation } from 'next-i18next';
import ImageCustom from '../elements/ImageCustom';
import Texto from '../elements/Texto';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import { black, primary, verde, verdePastel, white } from '@/constants/Colors';
import { Card } from '@mui/material';
import { textoMediano, textosPeque } from '@/constants/Dimens';
const Premios: React.FC = () => {
  const { t } = useTranslation();

  const [yearActual, setYearActual] = React.useState<number>(new Date().getFullYear());

  return (
    <section>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: "column", padding: '10px', gap: "10px" }}>
        <Card style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: "column", padding: "10px" }}>
          <ImageCustom src="/images/icons/actua_upm.png" alt={t("common:footer.alt_logo_actua_upm")} width={160} height={55} />
          <Texto fontSize={textosPeque} color={primary}>{t("common:footer.proyecto_premiado")}</Texto>
        </Card>
        <Card style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: "row", padding: "10px" }}>
          <LocalFloristIcon style={{ color: verde, fontSize: 30 }} />
          <Texto fontSize={textosPeque} color={primary}>{t("common:footer.texto_energia_renovable")}</Texto>
        </Card>
      </div>
    </section>
  );
};

export default Premios;
