interface Offer {
    "@type": SchemaType;
    gtin13: string;
    price: string;
    priceCurrency: string;
    availability: SchemaAvailability;
    priceValidUntil: string;
    itemCondition: SchemaCondition;
    url: string;
    sku: string;
    shippingDetails: ShippingDetails;
}

interface Person {
    "@type": SchemaType;
    name: string;
}

interface Thing {
    "@type": SchemaType;
    name: string;
}

interface ShippingDetails {
    "@type": SchemaType;
    shippingDestination: ShippingDestination[];
}

interface ShippingDestination {
    "@type": SchemaType;
    addressCountry: string;
}

interface Product {
    "@type": SchemaType;
    "@id": string;
    productID: string;
    image: string;
    name: string;
    description: string;
    brand: string;
    url: string;
    sippingDetails?: ShippingDetails;
    offers: Offer;
    gtin13: string;
    sku: string;
    aggregateRating?: AggregateRating;
    review?: Review[];
}

interface Review {
    "@type": SchemaType;
    author?: Person;
    datePublished: string;
    reviewBody: string;
    reviewRating: ReviewRating;
}

interface ReviewRating {
    "@type": SchemaType;
    ratingValue: string;
    bestRating: number;
    worstRating: number;
}

interface Book {
    "@context": ContextSchema;
    "@type": SchemaType;
    "@id": string;
    image: string;
    isbn: string;
    name: string;
    description: string;
    bookFormat: SchemaBookFormat;
    author: Person;
    about: Thing;
    publisher: Person;
    url: string;
}

interface AggregateRating {
    "@type": SchemaType;
    ratingValue: string;
    reviewCount: string;
    bestRating: number;
    worstRating: number;
}

export interface LDJsonScript {
    "@context": ContextSchema;
    "@graph": (any)[];
}

export enum ContextSchema {
    Schema = "https://schema.org"
}

export enum SchemaType {
    Product = "Product",
    Book = "Book",
    Offer = "Offer",
    Person = "Person",
    Thing = "Thing",
    ShippingDetails = "OfferShippingDetails",
    DefinedRegion = "DefinedRegion",
    AggregateRating = "AggregateRating",
    Review = "Review",
    ReviewRating = "Rating"
}

export enum SchemaBookFormat {
    Hardcover = "https://schema.org/Hardcover",
    Paperback = "https://schema.org/Paperback",
    Ebook = "https://schema.org/EBook",
    Audiobook = "https://schema.org/Audiobook",
    AudioCD = "https://schema.org/AudioCD"
}

export enum SchemaAvailability {
    InStock = "https://schema.org/InStock",
    OutOfStock = "https://schema.org/OutOfStock",
    Discontinued = "https://schema.org/Discontinued"
}

export enum SchemaCondition {
    NewCondition = "https://schema.org/NewCondition",
    UsedCondition = "https://schema.org/UsedCondition"
}
