import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'next-i18next';
import { AppBar, IconButton, Slide, Toolbar } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

let setOpenExt: (arg: boolean) => void;
let setParamsExt: (arg: DialogCustomParams) => void;

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const DialogCustom: React.FC<DialogCustomParams> = () => {

    const { t } = useTranslation();

    const [open, setOpen] = React.useState(false);
    const [props, setParams] = React.useState<DialogCustomParams>({
        title: '',
        children: <div></div>
    });

    setOpenExt = setOpen;
    setParamsExt = (params: DialogCustomParams) => {
        setParams(params);
    };


    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Dialog style={{ zIndex: 999 }}
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        {props.title}
                    </Toolbar>
                </AppBar>
                <div style={{ overflowY: 'auto' }}>
                    {props.children}
                </div>
            </Dialog>
        </React.Fragment>
    );
};

export interface DialogCustomParams {
    title?: string;
    children?: React.ReactNode;
}

export default DialogCustom;

export const mostrarDialog = (params: DialogCustomParams) => {
    setOpenExt(true);
    setParamsExt(params);
    // Puedes acceder a los atributos pasados a través de params en lugar de props
};