import { black, primaryDark } from '@/constants/Colors';
import { textoBotonMovilHeader, textos, textosPeque, tituloH1 } from '@/constants/Dimens';
import { Card, Typography } from '@mui/material';
import Link from 'next/link';
import LinkCustom from './LinkCustom';

//Definimos los tipos de datos que va a recibir el componente en forma de interfaz
interface Props {
    title?: string;
    href?: string;
    onClick?: (a: any) => void;
    children?: React.ReactNode;
    color?: string;
    icon?: JSX.Element;
    ref?: any;
    width?: string;
    fontSize?: string;
    height?: string;
    target?: string;
}

function BotonBarraMovil({ children, title, color = primaryDark, href, icon, onClick, target = "", ref, width = "50px", height = "50px", fontSize = textoBotonMovilHeader }: Props) {
    const style: React.CSSProperties = {
        fontSize: fontSize,
        color: color,
        fontWeight: '500',
        userSelect: 'none',
        textDecoration: 'none',
        textAlign: 'left',
        cursor: 'pointer',
        padding: '5px',
    };


    return (
        href !== undefined ? (
            <div style={style} ref={ref}>
                <LinkCustom title={String(title)} href={href} target={target}  >
                    <Card style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: width, height: height }}>
                        {icon && <div style={{
                            display: 'flex', flexDirection: 'row', alignItems: 'center',
                            alignContent: "center", marginTop: "10px", height: "40px"
                        }}>{icon}</div>}
                        <div style={{
                            textAlign: 'center', color: color,
                            fontWeight: '500', fontSize: fontSize,
                        }}>
                            {children}
                        </div>
                    </Card>
                </LinkCustom>
            </div>
        ) : (
            <div style={style} onClick={onClick} ref={ref} >
                <Card style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: width, height: height }}>
                    {icon && <div style={{
                        display: 'flex', flexDirection: 'row', alignItems: 'center',
                        alignContent: "center", marginTop: "10px", height: "40px"
                    }}>{icon}</div>}
                    <div style={{
                        textAlign: 'center', color: color,
                        fontWeight: '500', fontSize: fontSize,
                    }}>
                        {children}
                    </div>
                </Card>
            </div>
        )
    );
}

export default BotonBarraMovil;