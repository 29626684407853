import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'next-i18next';
import { AppBar, IconButton, Slide, Toolbar } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

let setOpenExt: (arg: boolean) => void;
let setParamsExt: (arg: DialogActivityParams) => void;

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />;
});


const DialogActivity: React.FC<DialogActivityParams> = () => {

    const { t } = useTranslation();

    const [open, setOpen] = React.useState(false);
    const [props, setParams] = React.useState<DialogActivityParams>({
        title: '',
        children: <div></div>
    });

    setOpenExt = setOpen;
    setParamsExt = (params: DialogActivityParams) => {
        setParams(params);
    };


    const handleClose = () => {
        if (props.onClose) {
            props.onClose();
        }
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Dialog style={{ zIndex: 999 }}
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        {props.title}
                    </Toolbar>
                </AppBar>
                <div style={{ overflowY: 'auto' }}
                    onClick={(e) => {
                        if ((e.target as HTMLElement).tagName === 'A') {
                            handleClose(); // Cierra el dialog al hacer clic en un enlace
                        }
                    }}>
                    {props.children}
                </div>
            </Dialog>
        </React.Fragment>
    );
};

export interface DialogActivityParams {
    title?: string;
    children?: React.ReactNode;
    onClose?: () => void;
}

export default DialogActivity;

export const mostrarDialogAcitivity = (params: DialogActivityParams) => {
    setOpenExt(true);
    setParamsExt(params);
    // Puedes acceder a los atributos pasados a través de params en lugar de props
};