export function filtarMoneda(moneda: string): string {
    let retorno = moneda;
    if (moneda === "EUR") {
        retorno = "€";
    }
    if (moneda === "ARS") {
        retorno = "$";
    }
    if (moneda === "BOP") {
        retorno = "b$";
    }
    if (moneda === "BOB") {
        retorno = "b$";
    }
    if (moneda === "BRL") {
        retorno = "R$";
    }
    if (moneda === "CLP") {
        retorno = "$";
    }
    if (moneda === "COP") {
        retorno = "$";
    }
    if (moneda === "SVC") {
        retorno = "$";
    }
    if (moneda === "CRC") {
        retorno = "₡";
    }
    if (moneda === "CUC") {
        retorno = "$";
    }
    if (moneda === "USD") {
        retorno = "$";
    }
    if (moneda === "HTG") {
        retorno = "G";
    }
    if (moneda === "HNL") {
        retorno = "L";
    }
    if (moneda === "MXN") {
        retorno = "$";
    }
    if (moneda === "NIO") {
        retorno = "C$";
    }
    if (moneda === "PAB") {
        retorno = "B/.";
    }
    if (moneda === "PYG") {
        retorno = "₲";
    }
    if (moneda === "PEN") {
        retorno = "S/";
    }
    if (moneda === "DOP") {
        retorno = "RD$";
    }
    if (moneda === "GTQ") {
        retorno = "Q";
    }
    if (moneda === "UYU") {
        retorno = "$";
    }
    if (moneda === "VEF" || moneda === "VEB") {
        retorno = "Bs";
    }
    return retorno;
}

/*function filtraMoneda($moneda){
    $retorno = $moneda;
    if($moneda == "EUR"){//España
        $retorno = "€";
    }
    if($moneda == "ARS"){//Argentina
        $retorno = "$";
    }
    if($moneda == "BOP"){//Bolivia
        $retorno = "b$";
    }
    if($moneda == "BOB"){ //Bolivia
        $retorno = "b$";
    }
    if($moneda == "BRL"){ //Brasil
        $retorno = "R$";
    }
    if($moneda == "CLP"){ //Chile
        $retorno = "$";
    }
    if($moneda == "COP"){ //Colombia
        $retorno = "$";
    }
    if($moneda == "SVC"){//El salvador
        $retorno = "$";
    }
    if($moneda == "CRC"){//Costa Rica
        $retorno = "₡";
    }
    if($moneda == "CUC"){//Cuba
        $retorno = "$";
    }
    if($moneda == "USD"){//Puerto rico y Ecuador
        $retorno = "$";
    }
	
    if($moneda == "HTG"){ //Haiti
        $retorno = "G";
    }
    if($moneda == "HNL"){//Honduras
        $retorno = "L";
    }
    if($moneda == "MXN"){//Mexico
        $retorno = "$";
    }
    if($moneda == "NIO"){//Nicaragua
        $retorno = "C$";
    }
    if($moneda == "PAB"){//Panamá
        $retorno = "B/.";
    }
    if($moneda == "PYG"){//Paraguay
        $retorno = "₲";
    }
    if($moneda == "PEN"){//Perú
        $retorno = "S/";
    }
    if($moneda == "DOP"){//Rep Dominicana
        $retorno = "RD$";
    }
    if($moneda == "GTQ"){//Guinea Ecuatorial
        $retorno = "Q";
    }
    if($moneda == "UYU"){ //Uruguay
        $retorno = "$";
    }
    if($moneda == "VEF" || $moneda == "VEB"){ //Venezuela
        $retorno = "Bs";
    }
	
    return $retorno;
}*/