// components/ArticleList.tsx
import React from 'react';
import LinkCustom from '@/components/elements/LinkCustom';
import { useTranslation } from 'next-i18next';
import { white } from '@/constants/Colors';
import Texto, { FontStyle, TextAlign } from '../elements/Texto';

const FraseYCopyRight: React.FC = () => {
  const { t } = useTranslation();

  const [yearActual, setYearActual] = React.useState<number>(new Date().getFullYear());

  return (
    <section>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: "column", padding: '10px' }}>
        <Texto color={white} fontStyle={FontStyle.italic}>{t("common:footer.texto_especial")}</Texto>
        <Texto textAlign={TextAlign.center} color={white}>{t("common:footer.copyright", { fecha: yearActual })}</Texto>
      </div>
    </section>
  );
};

export default FraseYCopyRight;
