// context/ServiceContext.tsx
import HeadComponent from '@/components/elements/Head';
import { HeadObj } from '@/objects/Page';
import AlertDialog, { mostrarAlerta as mostrarAlertaFunc, AlertParams } from '@/components/elements/Dialog';
import React, { createContext, useContext } from 'react';
import FooterComponent from '@/components/footer/FooterComponent';
import BeseifWidget, { LoadWebfParams, mostrarWebAlert as mostrarWebAlertFunc } from '@/components/widgets/LoadWebWidget';
import DialogCustom, { DialogCustomParams, mostrarDialog as mostrarDialogFunc } from '@/components/elements/DialogCustom';
import CustomSnackbar, { SnackbarParams, mostrarSnackbar as mostrarSnackFunc } from '@/components/elements/SnackBarCustom';
import DialogAcitvity, { DialogActivityParams, mostrarDialogAcitivity as mostrarDialogAcitivityFunc } from '@/components/elements/DialogActivity';

// Crear el contexto
const UtilsContext = createContext<{
    mostrarAlerta: (params: AlertParams) => void;
    mostrarWebAlert: (params: LoadWebfParams) => void;
    mostrarDialog: (params: DialogCustomParams) => void;
    mostrarDialogActivity: (params: DialogActivityParams) => void;
    mostrarSnackbar: (params: SnackbarParams) => void;
} | undefined>(undefined);

export const UtilsProvider: React.FC<{ children: React.ReactNode, headObj: HeadObj, pathActual?: string }> = ({ children, headObj, pathActual }) => {
    return (
        <UtilsContext.Provider
            value={
                {
                    mostrarAlerta,
                    mostrarWebAlert,
                    mostrarDialog,
                    mostrarDialogActivity,
                    mostrarSnackbar
                }
            }>
            <HeadComponent headObj={headObj} pathActual={pathActual} />
            {children}
            <AlertDialog />
            <CustomSnackbar />
            <BeseifWidget />
            <DialogAcitvity />
            <DialogCustom />
            <FooterComponent />
        </UtilsContext.Provider>
    );
};

export const useUtils = () => {
    const context = useContext(UtilsContext);
    if (!context) {
        throw new Error('useUtils must be used within a UtilsProvider');
    }
    return context;
};

// Exportar también la función mostrarAlerta para su uso directo
export const mostrarAlerta = mostrarAlertaFunc;
export const mostrarWebAlert = mostrarWebAlertFunc;
export const mostrarDialog = mostrarDialogFunc;
export const mostrarSnackbar = mostrarSnackFunc;
export const mostrarDialogActivity = mostrarDialogAcitivityFunc;

