
import { AmazonResponsePAAPI } from '@/objects/Amazon';
import { AxiosInstance, AxiosResponse } from 'axios';

//Buscar pacientes
export default class AmazonQueries {

    private axiosInstance: AxiosInstance

    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance
    }

    public async buscarAmazonPAAPI(busqueda: string): Promise<AxiosResponse<AmazonResponsePAAPI>> {
        try {
            const url = `/php/libs/amazon-paapi-v5/obtener_libros_amazon.php?busqueda=${busqueda}`;
            const response: AxiosResponse<AmazonResponsePAAPI> = await this.axiosInstance.get(url);
            return response;
        } catch (error: any) {
            console.log('Error en comprobarPaisMoneda', error);
            return error;
            //throw new Error(error.message);
        }
    }

    public async obtenerLibroAmazonRandom(): Promise<AxiosResponse<AmazonResponsePAAPI>> {
        try {
            const url = `/php/libs/amazon-paapi-v5/obtener_libros_amazon_inicio.php`;
            const response: AxiosResponse<AmazonResponsePAAPI> = await this.axiosInstance.get(url);
            return response;
        } catch (error: any) {
            console.log('Error en obtener libro Amazon Random', error);
            return error;
            //throw new Error(error.message);
        }
    }
}
