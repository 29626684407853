import ImageCustom, { TypeObjectFit } from './ImageCustom';

//Definimos los tipos de datos que va a recibir el componente en forma de interfaz
interface TituloH1Props {
    title?: string;
    alt: string;
    width?: number;
    height?: number;
    style?: React.CSSProperties;
    src: string;
}

function ImageRound({ title, alt, width = 55, height = 55, style, src }: TituloH1Props) {
    return (
        <ImageCustom style={{ ...style, borderRadius: '50%' }} src={src} title={title} alt={alt} width={width} height={height} objectFit={TypeObjectFit.CONTAIN} />
    );
}

export default ImageRound;