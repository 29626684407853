import { black } from '@/constants/Colors';
import { textos, tituloH1 } from '@/constants/Dimens';
import { Typography } from '@mui/material';

//Definimos los tipos de datos que va a recibir el componente en forma de interfaz

export enum FontStyle {
    normal = "normal",
    italic = "italic",
    oblique = "oblique"
}

export enum TextAlign {
    center = "center",
    start = "start",
    end = "end",
    left = "left",
    right = "right",
    justify = "justify",
    matchParent = "match-parent",
    inherit = "inherit",
    initial = "initial",
    unset = "unset"
}
interface TituloH1Props {
    children?: React.ReactNode;
    color?: string;
    fontSize?: string;
    fontStyle?: FontStyle;
    textAlign?: TextAlign;
    fontWeight?: string;
    html?: boolean;
}

function Texto({ children, color = black, fontSize = textos, fontWeight = "400", html = false, fontStyle = FontStyle.normal, textAlign = TextAlign.left }: TituloH1Props) {
    //Tamaño de texto a 25 px
    //Color de texto a primario

    const style: React.CSSProperties = {
        fontSize: fontSize,
        color: color,
        fontWeight: fontWeight,
        fontStyle: fontStyle,
        textAlign: textAlign,
        userSelect: 'none',
        textDecoration: 'none'
    };

    if (html) {
        return (
            <Typography style={style} dangerouslySetInnerHTML={{ __html: children as string }} />
        );
    }

    return (
        <Typography style={style}>
            {children}
        </Typography>
    );
}

export default Texto;