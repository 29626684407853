import React, { useEffect, useRef } from 'react';

export const Snowfall: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const createSnowflake = () => {
      const snowflake = document.createElement('div');
      snowflake.className = 'snowflake';
      snowflake.style.left = Math.random() * 100 + 'vw';
      snowflake.style.animationDuration = Math.random() * 3 + 2 + 's';
      snowflake.style.opacity = Math.random().toString();

      container.appendChild(snowflake);

      // Eliminar copos de nieve una vez caigan
      setTimeout(() => {
        snowflake.remove();
      }, 5000);
    };

    const interval = setInterval(createSnowflake, 300);

    return () => clearInterval(interval);
  }, []);

  return <div className="snowfall-container" ref={containerRef}></div>;
};
