export const primary = '#795548';
export const secondary = '#0090DD';
export const primaryDark = '#703200';
export const primaryLight = '#A98274';

export const marronTransparente = 'rgba(121, 85, 72, 0.9)';

export const backgroundWhite = '#EEEEEE';
export const white = '#FFFFFF';
export const whiteSmoke = '#F5F5F5';
export const azul = '#3D5AFE';
export const azulClaro = '#4FC3F7';
export const black = '#000000';
export const gray = '#4F4F4F';
export const lightGray = '#707070';
export const darkGray = '#333333';
export const amarilloPastel = '#FFD952';
export const amarilloOscuro = '#9B870C';
export const rojoPastel = '#FF8091';
export const rojoOscuro = '#141414';
export const verdePastel = '#97D8B4';
export const verde = '#19dd8f';
export const verdeOscurecido = '#068600';
export const verdeOscuro = '#2E2E2E';
export const purple = '#6200EE';
export const moradoFuerte = '#141414';
export const moradoSuave = '#9FA8DA';
export const oro = '#d4af37';
export const oroOscuro="#92781f";

export const transparenteCode = '#nnnnnn';
export const transparente = 'transparent';

export const red = '#B71C1C';
export const rojoerror = '#8D1124';
export const orange = '#c94904';
export const verderecomendacion = '#026174';

export const whiteAll= '#FFFFFF';
export const blackAll= '#000000';