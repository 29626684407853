import React, { } from 'react';
import HeaderGeneral from './HeaderGeneral';
import { useRouter } from 'next/router';

function HeaderProvider() {
  //Obten el path actual
  const router = useRouter()

  const getHeaderComponent = () => {
    if (router.pathname === '/map/[lat]/[lon]') {
      return undefined;
    } else {
      return <HeaderGeneral />;
    }
  };

  return (
      getHeaderComponent()
  );

}


export default HeaderProvider;
