import { mostrarAlerta } from "@/context/UtilsContext";
import { TFunction } from "next-i18next";
import { cargarMensajeAppMovil, guardarMensajeAppMovil } from "./LocalStorageLoaded";
import { urlAPPAndroid, urlAPPIOS } from "./BaseURL";
import { white, red } from "@/constants/Colors";

export function abrirAlertInicioSesion(t: TFunction) {
    mostrarAlerta({
        title: t('common:opciones.atencion'),
        content: t('common:errores.para_utilizar_esta_funcion_debes_iniciar_sesion'),
        acceptText: t('common:errores.iniciar_sesion'),
        showCancelButton: false,
        disableCancelable: true,
        onAccept: () => {
            //Abrir login en window 
            window.open('/login', '_self');
        }
    });
}

export function abrirAlertPremium(t: TFunction) {
    mostrarAlerta({
        title: t('common:opciones.atencion'),
        content: t('common:errores.para_utilizar_esta_funcion_debes_ser_usuario_premium'),
        acceptText: t('common:errores.hacerse_premium'),
        showCancelButton: true,
        onAccept: () => {
            window.location.href = window.location.origin + "/dashboard/premium"
        }
    });
}

export function abrirAlertaMovil(t: TFunction) {
    //Si el dispositivo con el que se ha conectado es un Android:
    if (!cargarMensajeAppMovil() && (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i))) {
        guardarMensajeAppMovil();
        mostrarAlerta({
            title: t('common:opciones.atencion'),
            content: t('common:mensajes_plataforma.dispositivo_movil'),
            acceptText: t('common:mensajes_plataforma.descargar_app'),
            showCancelButton: true,
            onAccept: () => {
                if (navigator.userAgent.match(/Android/i)) {
                    window.open(urlAPPAndroid, '_blank');
                } else if (navigator.userAgent.match(/iPhone/i)) {
                    window.open(urlAPPIOS, '_blank');
                }
            }
        });
    }
}

export function crearNotificacionHeader(numeroNotificaciones: number) {
    const notificaciones = document.getElementById("notificaciones_header_web");
    const divBadge = document.createElement("div");
    divBadge.id = "badge_notificaciones";
    divBadge.style.height = '25px';
    divBadge.style.width = '25px';
    divBadge.style.borderRadius = '50%';
    divBadge.style.backgroundColor = red;
    divBadge.style.color = white;
    divBadge.style.position = 'absolute';
    divBadge.style.display = 'flex';
    divBadge.style.alignItems = 'center';
    divBadge.style.justifyContent = 'center';
    divBadge.style.fontSize = '12px';
    divBadge.style.top = '0';
    divBadge.style.right = '0';
    divBadge.innerHTML = numeroNotificaciones > 99 ? '99+' : numeroNotificaciones.toString();
    if (notificaciones != null) {
        notificaciones!.appendChild(divBadge);
    }
    const notificaciones_movil = document.getElementById("notificaciones_header_movil");
    if (notificaciones_movil) {
        //Copia el badge en movil para poder modificarlo sin afectar al de web
        const divBadgeMovil = divBadge.cloneNode(true) as HTMLElement;
        //Cambia el tamaño del badge en movil
        divBadgeMovil.style.height = '20px';
        divBadgeMovil.style.width = '20px';
        divBadgeMovil.style.fontSize = '10px';
        divBadgeMovil.style.top = '-10';
        divBadgeMovil.style.right = '-10';
        if (notificaciones_movil != null) {
            notificaciones_movil.appendChild(divBadgeMovil);
        }
    }
}

//Funcion para comprobar si una pagina se carga en movil y no deberia mostrar ni anuncios ni alertas
export function cargarenAPPMovil(pathActual?: string): boolean {
    console.log("Path actual cargado header etc  " + pathActual);

    const palabrasClave = [
        "map",
        "cookies",
        "terminos-y-condiciones",
        "type_app",
        "legal",
        "sobre-nosotros",
        "preguntas-frecuentes",
        "map-buscar",
        "dashboard_perfil"
    ];

    if (pathActual !== undefined && palabrasClave.some(palabra => pathActual.includes(palabra))) {
        console.log("No carga anuncios ni alertas");
        return false;
    }
    return true;
}

//Header para el path que incluya dashboard
export function cargarStripeHeader(pathActual?: string): boolean {
    console.log("Path actual cargado Stripe header etc  " + pathActual);

    const palabrasClave = [
        "dashboard"
    ];

    if (pathActual !== undefined && palabrasClave.some(palabra => pathActual.includes(palabra))) {
        console.log("Carga stripe en este path");
        return true;
    }
    return false;
}


//TODO:@DEPRECATED
export function noCargarAnunciosPorPath(pathActual?: string): boolean {
    console.log("Path actual cargado header etc  " + pathActual);

    const palabrasClave = [
        "dashboard/perfil"
    ];

    if (pathActual !== undefined && palabrasClave.some(palabra => pathActual.includes(palabra))) {
        console.log("No carga anuncios en este path");
        return true;
    }
    return false;
}



//Lista de idiomas disponibles
export const idiomasDisponibles = ['es', 'en'];
