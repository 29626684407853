
import { AmazonResponsePAAPI } from '@/objects/Amazon';
import { PostMisLeidosSync, SincronizarLibrosLeidos } from '@/objects/MisLeidos';
import { AxiosInstance, AxiosResponse } from 'axios';

//Buscar pacientes
export default class MisLeidosQueries {

    private axiosInstance: AxiosInstance

    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance
    }

    //Crea cuenta en MisLeidos
    public async sincronizarMisLeidos(postMisLeidosSincronizar: PostMisLeidosSync): Promise<AxiosResponse<string>> {
        try {
            const url = `/php/APP_LISTAS/sincronizar_quierolibros_mis_leidos.php`;
            const response: AxiosResponse<string> = await this.axiosInstance.post(url, postMisLeidosSincronizar);
            return response;
        } catch (error: any) {
            console.log('Error en sincronizarMisLeidos', error);
            return error;
            //throw new Error(error.message);
        }
    }

    //Sincroniza el libro leido en MisLeidos
    public async sincronizarLibroMisLeidos(id_usuario: number, objEnviarSincronizar: SincronizarLibrosLeidos): Promise<AxiosResponse<string>> {
        try {
            //TODO: Verificar si es necesario validar los campos
            /*  if (autor == null) {
                autor = "";
            }

            if (editorial == null) {
                editorial = "";
            }*/
            const url = `/php/APP_LISTAS/set_libro_leido_desde_quierolibros.php?id_usuario=${id_usuario}`;
            const response: AxiosResponse<string> = await this.axiosInstance.post(url, objEnviarSincronizar);
            return response;
        } catch (error: any) {
            console.log('Error en sincronizarLibroMisLeidos', error);
            return error;
            //throw new Error(error.message);
        }
    }

    //Comprueba si tienes activada la sincronización de MisLeidos
    public async obtenerSincronizacionMisLeidos(id_usuario: number, email_user: string): Promise<AxiosResponse<number>> {
        try {
            //true = 1, false = 0
            const url = `/php/APP_LISTAS/obtener_sincronizado_con_quierolibros.php?id_usuario=${id_usuario}&email=${email_user}`;
            const response: AxiosResponse<number> = await this.axiosInstance.get(url);
            return response;
        } catch (error: any) {
            console.log('Error en obtenerSincronizacionMisLeidos', error);
            return error;
            //throw new Error(error.message);
        }
    }
    //Sincroniza todos los libros con mis Leidos
    public async sincronizarTodosLosLibros(id_usuario: number): Promise<AxiosResponse<string>> {
        try {
            const url = `/php/APP_LISTAS/sincronizar_libros_subidos.php?id_usuario=${id_usuario}`;
            const response: AxiosResponse<string> = await this.axiosInstance.get(url);
            return response;
        } catch (error: any) {
            console.log('Error en sincronizarTodosLosLibros', error);
            return error;
            //throw new Error(error.message);
        }
    }

    //Desactiva la sincronizacion con MisLeidos
    public async desSincronizarMisLeidos(id_usuario: number, email_user: string): Promise<AxiosResponse<string>> {
        try {
            const url = `/php/APP_LISTAS/desincronizar_quierolibros_mis_leidos.php?id_usuario=${id_usuario}&email=${email_user}`;
            const response: AxiosResponse<string> = await this.axiosInstance.get(url);
            return response;
        } catch (error: any) {
            console.log('Error en desSincronizarMisLeidos', error);
            return error;
            //throw new Error(error.message);
        }
    }
}
