import React from 'react';
import LinkCustom from '../elements/LinkCustom';
import { useTranslation } from 'next-i18next';
import { white } from '@/constants/Colors';
import TituloSeccionH2 from '../elements/TituloSeccionH2';
import Texto from '../elements/Texto';
import { textos } from '@/constants/Dimens';
import { mostrarAlerta } from '@/context/UtilsContext';
import { emailPlataforma } from '@/util/BaseURL';

function LinksDeInteres() {
  const { t } = useTranslation();

  function contactar() {
    mostrarAlerta({
      title: t('common:footer.title_contactar'),
      content: <Texto html>{t('common:footer.para_contactar', { email: emailPlataforma })}</Texto>,
      acceptText: t('common:opciones.cerrar'),
    });
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
      <TituloSeccionH2 fontSize={textos} color={white} texto={t(`common:footer.links`)} />
      <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: "100%", paddingLeft: "10px" }}>
        <div style={{ cursor: "pointer" }} onClick={contactar}>
          <Texto fontWeight='600' color={white}>{t(`common:footer.contactar`)}</Texto>
        </div>
        <LinkCustom
          color={white}
          href={`https://blog.quierolibros.com`}
          title={t(`common:footer.title_blog_noticias`)}
        >
          {t(`common:footer.blog_noticias`)}
        </LinkCustom>
        <LinkCustom
          color={white}
          href={`/preguntas-frecuentes`}
          title={t(`common:footer.title_preguntas_frecuentes`)}
        >
          {t(`common:footer.preguntas_frecuentes`)}
        </LinkCustom>
        <LinkCustom
          color={white}
          href={`/sobre-nosotros`}
          title={t(`common:footer.title_sobre_nosotros`)}
        >
          {t(`common:footer.sobre_nosotros`)}
        </LinkCustom>
        <LinkCustom
          color={white}
          href={`/terminos-y-condiciones`}
          title={t(`common:footer.title_condiciones_uso_y_privacidad`)}
        >
          {t(`common:footer.condiciones_uso_y_privacidad`)}
        </LinkCustom>
        <LinkCustom
          color={white}
          href={`/legal`}
          title={t(`common:footer.title_legal`)}
        >
          {t(`common:footer.legal`)}
        </LinkCustom>
        <LinkCustom
          color={white}
          href={`/cookies`}
          title={t(`common:footer.title_politica_cookies`)}
        >
          {t(`common:footer.politica_cookies`)}
        </LinkCustom>
      </section>
    </div>
  );
}

export default LinksDeInteres;
