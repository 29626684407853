
import { GetPuntuacion } from '@/objects/Puntuacion';
import { DataCodeResponse, GetPaisMonedaResponse, LoginDataResponse, LoginUserGoogle, RegistroUsuario, ResultadoCodigo, SetPassword, UserDataResponse, loginUser } from '@/objects/User';
import { AxiosInstance, AxiosResponse } from 'axios';

//Buscar pacientes
export default class UsersQueries {

    private axiosInstance: AxiosInstance
    //Este se utiliza para las llamadas que requieren token
    private axiosInstanceToken: AxiosInstance
    // private userData: LoginDataResponse

    constructor(axiosInstance: AxiosInstance, axiosInstanceToken: AxiosInstance) {
        this.axiosInstance = axiosInstance
        this.axiosInstanceToken = axiosInstanceToken
        //   this.userData = userData
    }

    public async iniciarSesion(objIniciarSesion: loginUser): Promise<AxiosResponse<LoginDataResponse>> {
        try {
            const url = `/php/login/conexionLoginPost.php`;
            const response: AxiosResponse<LoginDataResponse> = await this.axiosInstance.post(url, JSON.stringify(objIniciarSesion));
            return response;
        } catch (error: any) {
            console.log('Error en iniciarSesion', error);
            return error;
            //throw new Error(error.message);
        }
    }

    public async iniciarSesionGoogle(objIniciarSesion: LoginUserGoogle): Promise<AxiosResponse<LoginDataResponse>> {
        try {
            const url = `/php/login/conexionLoginGoogleNew_post.php`;
            const response: AxiosResponse<LoginDataResponse> = await this.axiosInstance.post(url, JSON.stringify(objIniciarSesion));
            return response;
        } catch (error: any) {
            console.log('Error en iniciarSesionGoogle', error);
            return error;
            //throw new Error(error.message);
        }
    }

    public async registroUsuario(obRegistro: RegistroUsuario): Promise<AxiosResponse<string>> {
        try {
            const url = `/php/registro/registro_v2.php`;
            const response: AxiosResponse<string> = await this.axiosInstance.post(url, JSON.stringify(obRegistro));
            return response;
        } catch (error: any) {
            console.log('Error en registroUsuario', error);
            return error;
            //throw new Error(error.message);
        }
    }

    public async recuperarContra(email_usuario: string): Promise<AxiosResponse<string>> {
        try {
            const url = `/php/login/recordar_contra_service.php?emailUser=${email_usuario.toLowerCase()}&type_app=1`;

            const response: AxiosResponse<string> = await this.axiosInstance.get(url);
            return response;
        } catch (error: any) {
            console.log('Error en recuperarContra', error);
            return error;
            //throw new Error(error.message);
        }
    }

    //obtiene datos de usuario usando el token
    public async obtenerDatosUsuario(): Promise<AxiosResponse<UserDataResponse>> {
        try {
            const url = `php/login/getUserData.php`;

            const response: AxiosResponse<UserDataResponse> = await this.axiosInstanceToken.get(url);
            return response;
        } catch (error: any) {
            console.log('Error en obtener datos usuario', error);
            return error;
            //throw new Error(error.message);
        }
    }

    public async comprobarPaisMoneda(): Promise<AxiosResponse<GetPaisMonedaResponse>> {
        try {
            const url = `/php/actions/comprobar_pais_new.php`;
            const response: AxiosResponse<GetPaisMonedaResponse> = await this.axiosInstance.get(url);
            return response;
        } catch (error: any) {
            console.log('Error en comprobarPaisMoneda', error);
            return error;
            //throw new Error(error.message);
        }
    }

    public async obtenerPuntuacion(id_user_articulo: number): Promise<AxiosResponse<GetPuntuacion>> {
        try {
            const url = `php/puntuaciones/obtener_puntuacion.php?id_user_articulo=${id_user_articulo}`;
            const response: AxiosResponse<GetPuntuacion> = await this.axiosInstance.get(url);
            return response;
        } catch (error: any) {
            console.log('Error en obtenerPuntuacion', error);
            return error;
            //throw new Error(error.message);
        }
    }

    public async setPaisMonedaPerfil(idUser: number, pais: string, moneda: string): Promise<AxiosResponse<string>> {
        try {
            const url = `/php/actions/setPais_Moneda.php?id_user=${idUser}&pais=${pais}&moneda=${moneda}`;
            const response: AxiosResponse<string> = await this.axiosInstanceToken.get(url);
            return response;
        } catch (error: any) {
            console.log('Error en setPaisMonedaPerfil', error);
            return error;
            //throw new Error(error.message);
        }
    }

    //Guarda los datos del perfil
    public async guardarDatosPerfil(idUser: number, nombre: string, telefono: string, avisoEmailChat: number, avisoEmailGeneral: number, avisoEmailAlertas: number): Promise<AxiosResponse<string>> {
        try {
            var query = "?idUser=" + idUser + "&name=" + nombre + "&phone=" + telefono + "&aviso_email_chat=" + avisoEmailChat
                + "&aviso_email_general=" + avisoEmailGeneral + "&aviso_email_alertas=" + avisoEmailAlertas;

            const url = `/php/actions/setPerfil_token.php${query}`;
            const response: AxiosResponse<string> = await this.axiosInstanceToken.get(url);
            return response;
        } catch (error: any) {
            console.log('Error en guardarDatosPerfil', error);
            return error;
            //throw new Error(error.message);
        }
    }

    public async cambiarContra(setPassword: SetPassword): Promise<AxiosResponse<string>> {
        try {
            const url = `/php/actions/setPass.php`;
            const response: AxiosResponse<string> = await this.axiosInstanceToken.post(url, setPassword);
            return response;
        } catch (error: any) {
            console.log('Error en cambiarContra', error);
            return error;
            //throw new Error(error.message);
        }
    }

    public async darDeBaja(idUsuario: number): Promise<AxiosResponse<string>> {
        try {
            const stringBusqueda = "?id_user=" + idUsuario + "&type_app=" + "1";

            const url = `/php/actions/darDeBaja.php${stringBusqueda}`;
            const response: AxiosResponse<string> = await this.axiosInstanceToken.get(url);
            return response;
        } catch (error: any) {
            console.log('Error en darDeBaja', error);
            return error;
            //throw new Error(error.message);
        }
    }

    public async canjearCodigo(idUsuario: number, codigo: string): Promise<AxiosResponse<DataCodeResponse>> {
        try {
            const stringBusqueda = "?id_user=" + idUsuario + "&codigo=" + codigo;

            const url = `/php/gamificacion/canjearCodigo.php${stringBusqueda}`;
            const response: AxiosResponse<DataCodeResponse> = await this.axiosInstanceToken.get(url);
            return response;
        } catch (error: any) {
            console.log('Error en canjearCodigo', error);
            return error;
            //throw new Error(error.message);
        }
    }


}
