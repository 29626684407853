// components/ArticleList.tsx
import React from 'react';
import { useTranslation } from 'next-i18next';
import { white } from '@/constants/Colors';
import Texto, { FontStyle } from '../elements/Texto';
import TituloSeccionH2 from '../elements/TituloSeccionH2';
import { textos } from '@/constants/Dimens';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkCustom from '../elements/LinkCustom';
import XIcon from '@mui/icons-material/X';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import HomeMiniIcon from '@mui/icons-material/HomeMini';

const RedesSociales: React.FC = () => {
  const { t } = useTranslation();

  const [yearActual, setYearActual] = React.useState<number>(new Date().getFullYear());

  return (
    <section>
      <TituloSeccionH2 fontSize={textos} color={white} texto={t("common:footer.redes_sociales")} />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: "row", padding: '10px', gap:"10px" }}>
        <LinkCustom href="https://www.facebook.com/quieroLibrosAPP" title={t("common:footer.title_facebook")}>
          <FacebookIcon style={{ color: white, fontSize: 40 }} />
        </LinkCustom>
        <LinkCustom href="https://twitter.com/QuieroLibros_" title={t("common:footer.title_twitter")}>
          <XIcon style={{ color: white, fontSize: 40 }} />
        </LinkCustom>
        <LinkCustom href="https://play.google.com/store/apps/details?id=com.quierolibros" title={t("common:footer.title_app_android")}>
          <AndroidIcon style={{ color: white, fontSize: 40 }} />
        </LinkCustom>
        <LinkCustom href="https://apps.apple.com/es/app/quierolibros-libros-usados/id1661699849" title={t("common:footer.app_ios")}>
          <AppleIcon style={{ color: white, fontSize: 40 }} />
        </LinkCustom>
        <LinkCustom href="https://www.amazon.es/Quiero-Libros-segunda-mano/dp/B081B6Z99Q" title={t("common:footer.title_alexa")}>
          <HomeMiniIcon style={{ color: white, fontSize: 40 }} />
        </LinkCustom>
      </div>
    </section>
  );
};

export default RedesSociales;
