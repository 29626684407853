import { Article, ArticleImages, CategoriasString, CategoriasStringServer, Comentario, GetPuntuacionLibro, SubCategoriasID, SubCategoriasString } from "@/objects/Articles";
import { baseURL, baseURLImages, urlWeb } from "./BaseURL";
import { ContextSchema, LDJsonScript, SchemaAvailability, SchemaBookFormat, SchemaCondition, SchemaType } from "@/objects/JSONLD";
import { TFunction } from "next-i18next";
import { cargarDatosUsuario } from "./LocalStorageLoaded";
import { esPremium } from "./StringsUtil";

export const getRandomHeight = (id_article: number) => {
    const min = 190;
    const max = 250;

    // Crear un generador de números pseudoaleatorios con la semilla id_article
    const pseudoRandom = (seed: number) => {
        seed = Math.sin(seed) * 10000;
        return seed - Math.floor(seed);
    };

    // Usar el generador de números pseudoaleatorios para obtener un número entre min y max
    let random = pseudoRandom(id_article) * (max - min) + min;
    if (isNaN(random)) {
        random = max;
    }
    return random
}

export function listaImagenes(imagenes: string): string[] {
    return imagenes.split(",");
}

export function obtenerImagenes(imagen: string, isbn: string): ArticleImages {
    // Las imagenes van separadas por , 
    if (imagen != null) {
        const imagenesDelLibro = listaImagenes(imagen);
        //console.log("Imagenes del libro: ", imagenesDelLibro);

        // Ordena las imágenes
        var articleImages: ArticleImages = {
            image_preview: setUrlImage(imagenesDelLibro[0]), // Utiliza la primera imagen del array original
            image_1: "",
            image_2: "",
            image_3: ""
        };

        // Ordena las imágenes en función de si su nombre incluye 01, 02 o 03
        for (let i = 1; i <= 3; i++) {
            for (let j = 0; j < imagenesDelLibro.length; j++) {
                if (imagenesDelLibro[j] != null && (imagenesDelLibro[j].includes(`0${i}.png`) || imagenesDelLibro[j].includes(`0${i}.jpg`))) {
                    (articleImages as any)[`image_${i}`] = imagenesDelLibro[j]; // Asigna la imagen original
                    break;
                }
            }
        }

        // Asigna "" si la imagen es null
        [articleImages.image_1, articleImages.image_2, articleImages.image_3] = [setUrlImage(articleImages.image_1), setUrlImage(articleImages.image_2), setUrlImage(articleImages.image_3)].map(imagen => imagen == null ? "" : imagen);

        //alert(JSON.stringify(articleImages));

        if (articleImages.image_preview === "" || articleImages.image_preview === " " || articleImages.image_preview.includes("no_image")) {
            //alert("No image");
            articleImages.image_preview = obtenerImagenCoversOpenLibrary(articleImages.image_preview, isbn);

        } else {
            articleImages.image_preview = setUrlImage(articleImages.image_preview);
        }

        //Primera imagen
        if (articleImages.image_1 === "" || articleImages.image_1 === " " || articleImages.image_1.includes("no_image")) {
            //alert("No image");
            articleImages.image_1 = obtenerImagenCoversOpenLibrary(articleImages.image_1, isbn);
        } else {
            if (articleImages.image_1 === "" || articleImages.image_1 === " ") {
                articleImages.image_1 = imagenDefault();
            } else {
                articleImages.image_1 = setUrlImage(articleImages.image_1);
            }
        }
    } else {
        articleImages = {
            image_preview: imagenDefault(),
            image_1: imagenDefault(),
            image_2: imagenDefault(),
            image_3: imagenDefault()
        };
    }

    return articleImages;
}


export function obtenerImagenCoversOpenLibrary(imagen: string, isbn: string): string {
    if (isbn !== "") {
        try {
            const url = `https://covers.openlibrary.org/b/isbn/${isbn}-L.jpg?default=false`;
            /*const url = `https://covers.openlibrary.org/b/isbn/9788401498909-L.jpg?default=false`;
            try {
                const response = await fetch(url, { method: 'HEAD' });
    
                if (response.ok) {
                    return url;
                }
            } catch (error) {
                // console.error('Error checking image:', error);
            }*/
            return url;
        } catch (error) {
            // console.error('Error checking image:', error);
            return imagen;
        }
    }

    return imagen;
}

export function setUrlImage(image: string): string {
    if (image != null && image != undefined && image != "") {
        if (image.substring(0, 4) !== 'http' && image !== " ") {
            return `${baseURLImages}/${image}`;
        }
    }
    return image;
}

export function comprobarAvatar(imagen: string): string {
    if (imagen != null && imagen != undefined && imagen != "") {
        if (!imagen.includes("http")) {
            imagen = urlWeb + "/" + imagen;
        }
    }
    return imagen;
}

//Comprueba que la imagen sea una URL y no un base64 devuelve true o false
export function comprobarImagenURLNotBase64(imagen: string): boolean {
    if (imagen != null && imagen != undefined && imagen != "") {
        if (imagen.substring(0, 4) === 'http') {
            return true;
        }
    }
    return false;
}

//transforma una imagen de una URL a base64
export async function urlToBase64(url: string): Promise<string> {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

const imagenBase = baseURLImages + "/images/no_image_prev.jpg";
const avatarBase = urlWeb + "/images/avatar-001.jpg";

export function imagenDefault() {
    return imagenBase;
}

export function avatarDefault() {
    return avatarBase;
}

//Crea un JSON-LD para un catálogo de libros

export function crearJSONLDCatalogo(
    libros: Article[]
): LDJsonScript[] {
    return libros
        .filter(libro => libro.isbn !== "") // Filtrar libros que tienen ISBN distinto de ""
        .map(libro => {
            return crearJSONLDLibro(libro, null)
        });
}

export function crearJSONLDLibro(libroOriginal: Article, puntuacion: Comentario[] | null): LDJsonScript {
    const libro = comprobarDatosLibro(libroOriginal);
    const linkCompartir = `${urlWeb}${obtenerURLDeArticulo(libro)}`;

    //console.log("puntuacion comentario libro: ", JSON.stringify(puntuacion));

    let aggregateRating = undefined;

    if (puntuacion !== null && puntuacion.length > 0) {
        aggregateRating = {
            "@type": SchemaType.AggregateRating,
            ratingValue: calcularPuntuacionMedia(puntuacion),
            reviewCount: puntuacion.length,
            bestRating: 5,
            worstRating: 0,
        };
    }

    let reviews = undefined;
    if (puntuacion !== null && puntuacion.length > 0) {
        reviews = puntuacion.map(comentario => {
            return {
                "@type": SchemaType.Review,
                datePublished: comentario.timestamp,
                reviewBody: comentario.comentario,
                author: {
                    "@type": SchemaType.Person,
                    name: comentario.name,
                },
                reviewRating: {
                    "@type": SchemaType.ReviewRating,
                    ratingValue: comentario.puntuacion,
                    bestRating: 5,
                    worstRating: 0,
                },
            };
        }
        );
    }

    return {
        "@context": ContextSchema.Schema,
        "@graph": [
            {
                "@type": SchemaType.Product,
                "@id": linkCompartir,
                productID: linkCompartir,
                image: setUrlImage(listaImagenes(libro.image)[0]),
                name: libro.title,
                description: `Libro ${libro.estado}. ${libro.description}. Editorial: ${libro.editorial}`,
                brand: libro.editorial,
                url: linkCompartir,
                offers: {
                    "@type": SchemaType.Offer,
                    gtin13: libro.isbn,
                    price: libro.prize,
                    priceCurrency: libro.code_moneda,
                    priceValidUntil: new Date(new Date().setFullYear(new Date().getFullYear() + 2)).toISOString(),
                    availability: SchemaAvailability.InStock,
                    itemCondition: SchemaCondition.UsedCondition,
                    url: linkCompartir,
                    sku: linkCompartir,
                    shippingDetails: {
                        "@type": SchemaType.ShippingDetails,
                        shippingDestination: [
                            {
                                "@type": SchemaType.DefinedRegion,
                                addressCountry: libro.pais,
                            },
                        ],
                    },
                },
                ...(aggregateRating && { "aggregateRating": aggregateRating }),
                ...(reviews && { "review": reviews }),
                gtin13: libro.isbn,
                sku: libro.articulo_categoria + "_" + libro.id_article.toString(),
            },
            {
                "@context": ContextSchema.Schema,
                "@type": SchemaType.Book,
                "@id": linkCompartir,
                image: setUrlImage(listaImagenes(libro.image)[0]),
                isbn: libro.isbn,
                name: libro.title,
                description: `Libro ${libro.estado}. ${libro.description}. Editorial: ${libro.editorial}`,
                bookFormat: SchemaBookFormat.Hardcover,
                author: {
                    "@type": SchemaType.Person,
                    name: libro.author,
                },
                about: {
                    "@type": SchemaType.Thing,
                    name: libro.description,
                },
                publisher: {
                    "@type": SchemaType.Person,
                    name: libro.editorial,
                },
                url: linkCompartir,
            },
        ],
    };
}

export function obtenerCategoriaStringServerByCategoriaString(categoria: CategoriasString): CategoriasStringServer {
    switch (categoria) {
        case CategoriasString.CIENCIA:
            return CategoriasStringServer.CIENCIA;
        case CategoriasString.COMIC:
            return CategoriasStringServer.COMIC;
        case CategoriasString.EDUCACION:
            return CategoriasStringServer.EDUCACION;
        case CategoriasString.HISTORIA:
            return CategoriasStringServer.HISTORIA;
        case CategoriasString.LITERATURA:
            return CategoriasStringServer.LITERATURA;
        case CategoriasString.INFANTIL:
            return CategoriasStringServer.INFANTIL;
        default:
            return CategoriasStringServer.LITERATURA;
    }
}

export function obtenerCategoriaStringByID(id_categoria: number): CategoriasString {
    switch (id_categoria) {
        case 1:
            return CategoriasString.CIENCIA;
        case 2:
            return CategoriasString.COMIC;
        case 3:
            return CategoriasString.EDUCACION;
        case 4:
            return CategoriasString.HISTORIA;
        case 5:
            return CategoriasString.LITERATURA;
        case 6:
            return CategoriasString.INFANTIL;
        default:
            return CategoriasString.LITERATURA;
    }
}

export function obtenerCategoriaIDByString(categoria: CategoriasString): number {
    switch (categoria) {
        case CategoriasString.CIENCIA:
            return 1;
        case CategoriasString.COMIC:
            return 2;
        case CategoriasString.EDUCACION:
            return 3;
        case CategoriasString.HISTORIA:
            return 4;
        case CategoriasString.LITERATURA:
            return 5;
        case CategoriasString.INFANTIL:
            return 6;
        default:
            return 5;
    }
}

export function obtenerCategoriaIDByStringServer(categoria: CategoriasStringServer): number {
    switch (categoria) {
        case CategoriasStringServer.CIENCIA:
            return 1;
        case CategoriasStringServer.COMIC:
            return 2;
        case CategoriasStringServer.EDUCACION:
            return 3;
        case CategoriasStringServer.HISTORIA:
            return 4;
        case CategoriasStringServer.LITERATURA:
            return 5;
        case CategoriasStringServer.INFANTIL:
            return 6;
        default:
            return 5;
    }
}

export function obtenerSubCategoriaStringByCategoriaID(id_categoria: SubCategoriasID): SubCategoriasString {
    switch (id_categoria) {
        case SubCategoriasID.AUTOAYUDA:
            return SubCategoriasString.AUTOAYUDA;
        case SubCategoriasID.AVENTURAS:
            return SubCategoriasString.AVENTURAS;
        case SubCategoriasID.BIOGRAFIA:
            return SubCategoriasString.BIOGRAFIA;
        case SubCategoriasID.COMEDIA:
            return SubCategoriasString.COMEDIA;
        case SubCategoriasID.DRAMA:
            return SubCategoriasString.DRAMA;
        case SubCategoriasID.EROTICA:
            return SubCategoriasString.EROTICA;
        case SubCategoriasID.FANTASIA:
            return SubCategoriasString.FANTASIA;
        case SubCategoriasID.FICCION:
            return SubCategoriasString.FICCION;
        case SubCategoriasID.POESIA:
            return SubCategoriasString.POESIA;
        case SubCategoriasID.ROMANTICO:
            return SubCategoriasString.ROMANTICO;
        case SubCategoriasID.TERROR:
            return SubCategoriasString.TERROR;
        case SubCategoriasID.OTROS:
            return SubCategoriasString.OTROS;
        case SubCategoriasID.PREESCOLAR:
            return SubCategoriasString.PREESCOLAR;
        case SubCategoriasID.PRIMARIA:
            return SubCategoriasString.PRIMARIA;
        case SubCategoriasID.SECUNDARIA:
            return SubCategoriasString.SECUNDARIA;
        case SubCategoriasID.BACHILLERATO:
            return SubCategoriasString.BACHILLERATO;
        case SubCategoriasID.FORMACION_PROFESIONAL:
            return SubCategoriasString.FORMACION_PROFESIONAL;
        case SubCategoriasID.UNIVERSIDAD:
            return SubCategoriasString.UNIVERSIDAD;
        case SubCategoriasID.VACACIONES:
            return SubCategoriasString.VACACIONES;
        case SubCategoriasID.PRIMERO_PRIMARIA:
            return SubCategoriasString.PRIMERO_PRIMARIA;
        case SubCategoriasID.SEGUNDO_PRIMARIA:
            return SubCategoriasString.SEGUNDO_PRIMARIA;
        case SubCategoriasID.TERCERO_PRIMARIA:
            return SubCategoriasString.TERCERO_PRIMARIA;
        case SubCategoriasID.CUARTO_PRIMARIA:
            return SubCategoriasString.CUARTO_PRIMARIA;
        case SubCategoriasID.QUINTO_PRIMARIA:
            return SubCategoriasString.QUINTO_PRIMARIA;
        case SubCategoriasID.SEXTO_PRIMARIA:
            return SubCategoriasString.SEXTO_PRIMARIA;
        case SubCategoriasID.PRIMERO_ESO:
            return SubCategoriasString.PRIMERO_ESO;
        case SubCategoriasID.SEGUNDO_ESO:
            return SubCategoriasString.SEGUNDO_ESO;
        case SubCategoriasID.TERCERO_ESO:
            return SubCategoriasString.TERCERO_ESO;
        case SubCategoriasID.CUARTO_ESO:
            return SubCategoriasString.CUARTO_ESO;
        case SubCategoriasID.PRIMERO_BACHILLERATO:
            return SubCategoriasString.PRIMERO_BACHILLERATO;
        case SubCategoriasID.SEGUNDO_BACHILLERATO:
            return SubCategoriasString.SEGUNDO_BACHILLERATO;
        default:
            return SubCategoriasString.SIN_SUBCATEGORIA;
    }
}

export function obtenerSubCategoriaIdByString(subCategoria: SubCategoriasString): SubCategoriasID {
    switch (subCategoria) {
        case SubCategoriasString.AUTOAYUDA:
            return SubCategoriasID.AUTOAYUDA;
        case SubCategoriasString.AVENTURAS:
            return SubCategoriasID.AVENTURAS;
        case SubCategoriasString.BIOGRAFIA:
            return SubCategoriasID.BIOGRAFIA;
        case SubCategoriasString.COMEDIA:
            return SubCategoriasID.COMEDIA;
        case SubCategoriasString.DRAMA:
            return SubCategoriasID.DRAMA;
        case SubCategoriasString.EROTICA:
            return SubCategoriasID.EROTICA;
        case SubCategoriasString.FANTASIA:
            return SubCategoriasID.FANTASIA;
        case SubCategoriasString.FICCION:
            return SubCategoriasID.FICCION;
        case SubCategoriasString.POESIA:
            return SubCategoriasID.POESIA;
        case SubCategoriasString.ROMANTICO:
            return SubCategoriasID.ROMANTICO;
        case SubCategoriasString.TERROR:
            return SubCategoriasID.TERROR;
        case SubCategoriasString.OTROS:
            return SubCategoriasID.OTROS;
        case SubCategoriasString.PREESCOLAR:
            return SubCategoriasID.PREESCOLAR;
        case SubCategoriasString.PRIMARIA:
            return SubCategoriasID.PRIMARIA;
        case SubCategoriasString.SECUNDARIA:
            return SubCategoriasID.SECUNDARIA;
        case SubCategoriasString.BACHILLERATO:
            return SubCategoriasID.BACHILLERATO;
        case SubCategoriasString.FORMACION_PROFESIONAL:
            return SubCategoriasID.FORMACION_PROFESIONAL;
        case SubCategoriasString.UNIVERSIDAD:
            return SubCategoriasID.UNIVERSIDAD;
        case SubCategoriasString.VACACIONES:
            return SubCategoriasID.VACACIONES;
        case SubCategoriasString.PRIMERO_PRIMARIA:
            return SubCategoriasID.PRIMERO_PRIMARIA;
        case SubCategoriasString.SEGUNDO_PRIMARIA:
            return SubCategoriasID.SEGUNDO_PRIMARIA;
        case SubCategoriasString.TERCERO_PRIMARIA:
            return SubCategoriasID.TERCERO_PRIMARIA;
        case SubCategoriasString.CUARTO_PRIMARIA:
            return SubCategoriasID.CUARTO_PRIMARIA;
        case SubCategoriasString.QUINTO_PRIMARIA:
            return SubCategoriasID.QUINTO_PRIMARIA;
        case SubCategoriasString.SEXTO_PRIMARIA:
            return SubCategoriasID.SEXTO_PRIMARIA;
        case SubCategoriasString.PRIMERO_ESO:
            return SubCategoriasID.PRIMERO_ESO;
        case SubCategoriasString.SEGUNDO_ESO:
            return SubCategoriasID.SEGUNDO_ESO;
        case SubCategoriasString.TERCERO_ESO:
            return SubCategoriasID.TERCERO_ESO;
        case SubCategoriasString.CUARTO_ESO:
            return SubCategoriasID.CUARTO_ESO;
        case SubCategoriasString.PRIMERO_BACHILLERATO:
            return SubCategoriasID.PRIMERO_BACHILLERATO;
        case SubCategoriasString.SEGUNDO_BACHILLERATO:
            return SubCategoriasID.SEGUNDO_BACHILLERATO;
        default:
            return SubCategoriasID.SIN_SUBCATEGORIA;
    }
}


export function obtenerUltimoIdByCategoria(categoria: CategoriasStringServer, articles: Article[]): string {
    //alert("categoria: " + categoria + " articles: " + JSON.stringify(articles));
    const articlesCategoria = articles.filter(article => article.articulo_categoria === categoria);
    if (articlesCategoria.length > 0) {
        return articlesCategoria[articlesCategoria.length - 1].id_article.toString();
    }
    return "";
}

export function obtenerIdUltimoTotal(articles: Article[]): string {
    if (articles.length > 0) {
        return articles[articles.length - 1].id_article.toString();
    }
    return "0";
}

export function obtenerUltimaDistanciaDelUltimoArticulo(articles: Article[]): number {
    if (articles.length > 0) {
        return Number(articles[articles.length - 1].distance) + 0.001;
    }
    return 0;
}

export function filtroBuscarCercania(latitud: number, longitud: number): string {
    //alert(latitud + " " + longitud)
    return "cercania:" + latitud + ":" + longitud + ":" + 10000;
}

export function obtenerURLDeArticulo(articulo: Article): string {
    return `/libro/${articulo.articulo_categoria}/${articulo.id_article}/${encodeURIComponent(articulo.title)}`;
}

export function obtenerURLDeArticuloAlternative(articulo: Article): string {
    return `/libro/${articulo.articulo_categoria}/${articulo.id_article}`;
}
export function numeroCajasPorDefecto() {
    return 40;
}

export function numeroCajasPorDefectoNoEncontrado() {
    return 15;
}

export function autorDesconocidoToTranslation(autor: string, t: TFunction): string {
    if (autor === "Desconocido") {
        return t("contenido:subir-libro.autor_desconocido");
    }
    return autor;
}

export function calcularPuntuacionMedia(comentarios: Comentario[]): number {
    if (comentarios.length === 0) {
        return 0;
    }
    return comentarios.reduce((total, comentario) => total + comentario.puntuacion, 0) / comentarios.length;
}


export function comprobarDatosLibro(libro: Article): Article {
    //Si alguno de los datos no existe se pone un valor por defecto a ""
    if (libro.title === undefined) {
        libro.title = "";
    }
    if (libro.author === undefined) {
        libro.author = "";
    }
    if (libro.editorial === undefined) {
        libro.editorial = "";
    }
    if (libro.description === undefined) {
        libro.description = "";
    }
    if (libro.isbn === undefined) {
        libro.isbn = "";
    }
    if (libro.image === undefined) {
        libro.image = "";
    }
    if (libro.estado === undefined) {
        libro.estado = "";
    }

    if (libro.code_moneda === undefined) {
        libro.code_moneda = "";
    }
    if (libro.pais === undefined) {
        libro.pais = "";
    }

    if (libro.distance === undefined) {
        libro.distance = 0;
    }
    if (libro.id_article === undefined) {
        libro.id_article = 0;
    }
    if (libro.avatar === undefined) {
        libro.avatar = "";
    }
    return libro;
}

const NUMERO_ARTICULOS_PARA_ANUNCIO = 13;
export async function addArticuloInternoToArticles(articles: Article[], articleInterno: Article | null): Promise<Article[]> {
    if (articleInterno == null) {
        return articles;
    }

    let count = 0;
    const articlesWithInterno: Article[] = [];

    articles.forEach((article, index) => {
        articlesWithInterno.push(article);
        if ((index + 1) % NUMERO_ARTICULOS_PARA_ANUNCIO === 0 && count === 0) {
            articlesWithInterno.push(articleInterno);
            count++;
        }
    });

    return articlesWithInterno;
}

export function checkCategoria(categoria: string): boolean {
    // Convertir el enum a un array de valores y asegurar que son strings
    const categorias = Object.values(CategoriasStringServer) as string[];
    // Verificar si la categoría está en el array de valores y devolver el resultado como booleano
    return categorias.includes(categoria);
}