import { white, black, rojoerror, transparente } from '../../constants/Colors';
import { useEffect } from 'react';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

//Definimos los tipos de datos que va a recibir el componente en forma de interfaz

export enum AutocompleteValues {
    Off = "off",
    On = "on",
    Name = "name",
    GivenName = "given-name",
    FamilyName = "family-name",
    Email = "email",
    Username = "username",
    NewPassword = "new-password",
    CurrentPassword = "current-password",
    OneTimeCode = "one-time-code",
    Address = "address",
    Country = "country",
    City = "city",
    Tel = "tel",
    Date = "date",
    Bday = "bday",
}

export enum InputTypes {
    Text = "text",
    Password = "password",
    Email = "email",
    Tel = "tel",
    Number = "number",
    Url = "url",
    Search = "search",
    Date = "date",
}

interface Props {
    id?: string;
    label: string | number;
    required?: boolean;
    fullWidth?: boolean;
    autocomplete?: AutocompleteValues;
    inputType?: InputTypes;
    value?: any;
    passwordShow?: boolean;
    introducza_texto?: string;
    setValue: (value: any) => void;
    leftIcon?: JSX.Element | null;
    rightIcon?: JSX.Element | null;
    extraStyle?: React.CSSProperties | undefined;
    focus?: boolean;
    onFocus?: () => void;
    onBlur?: () => void;
    disabled?: boolean;
    showError?: boolean;
    placeholder?: string;
    multiline?: boolean;
    minRows?: number;
    onKeyDown?: (e: any) => void;
}

function TextFieldCustom({ id, value, setValue, label = "", required = false, fullWidth = true,
    autocomplete = AutocompleteValues.Off, inputType = InputTypes.Text, leftIcon = null, rightIcon = null,
    passwordShow = false, extraStyle = undefined, focus = false, showError = false, introducza_texto,
    disabled = false, placeholder, multiline = false, minRows = 1, onKeyDown, onFocus, onBlur }: Props) {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);

    const [error, setError] = useState<boolean>(false);

    const handlePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const style = {
        backgroundColor: error && showError ? transparente : white,
        borderColor: error && showError ? rojoerror : black,
        fontWeight: error && showError ? 'bold' : 'azure'
    };

    return (
        <TextField
            disabled={disabled}
            margin="normal"
            required={required}
            fullWidth={fullWidth}
            id={id}
            label={label}
            placeholder={placeholder || label.toString()}
            name={id}
            autoFocus={focus}
            onFocus={onFocus}
            onBlur={onBlur}
            type={inputType === InputTypes.Password ? (showPassword ? 'text' : 'password') : inputType}
            autoComplete={autocomplete}
            multiline={multiline}
            minRows={minRows}
            onKeyDown={onKeyDown}
            style={{ ...(extraStyle || {}), ...(style || {}) }}
            value={value}
            onChange={(e) => {
                if (error) {
                    setError(false);
                }
                setValue(e.target.value);
            }}
            error={error && showError}
            helperText={error && showError ? introducza_texto : ''}
            InputProps={{
                startAdornment: leftIcon != null && (
                    <InputAdornment position="start">
                        {leftIcon}
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        {passwordShow ? (
                            <IconButton onClick={handlePasswordVisibility} aria-label={t("login.mostrar_contra")} edge="end">
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        ) : (
                            rightIcon
                        )}
                    </InputAdornment>
                )
            }}
            InputLabelProps={{ shrink: true }}
        />
    );
}

export default TextFieldCustom;