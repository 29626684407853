export interface GetArticlesResponse {
    articles: Article[];
}

export interface Article {
    id_article: number;
    id_user: number;
    title: string;
    content: string;
    description: string;
    image: string;
    editorial: string;
    author: string;
    contact_email: string;
    contact_phone: string;
    city: string;
    town: string;
    pais: string;
    prize: number | string;
    moneda: string;
    code_moneda?: string;
    tases_shipping: number;
    coordinates: string;
    post_type: string;
    sub_type: string;
    estado: string;
    tapa: string;
    whatsapp: number;
    isbn: string;
    emailgoogle: string;
    fotogoogle: string;
    namegoogle: string;
    articulo_categoria: CategoriasStringServer | string;
    date: string;
    lat: number;
    lng: number;
    destacado: string;
    verificado: number;
    vendido: number;
    email_comprador: string;
    id_transaccion: string;
    visto: number;
    veces_telefono: number;
    veces_email: number;
    mostrar: number;
    name: string;
    email: string;
    esTienda: number;
    avatar: string;
    distance?: number;
    masonryHeight?: number;
    linkExtra?: string;
}

//TODO: Para enviar libro
export enum TipoTapa {
    SELECCIONAR = "",
    DURA = "Tapa dura",
    BLANDA = "Tapa blanda"
}

export enum EstadoLibro {
    SELECCIONAR = "",
    POCO_USADO = "poco usado",
    USADO = "usado",
    MUY_USADO = "muy usado",
    NUEVO = "nuevo"
}

export interface GetArticlePatrocinador {
    anuncioPatrocinador: GetPatrocionador[];
}

export interface GetPatrocionador {
    id: number;
    tituloAnuncio: string;
    imagenAnuncio: string;
    precio: string;
    id_articulo: string;
    categoria: string;
    link: string;
    clicks: number;
    visualizaciones: number;
    fecha_maxima: string;
    activo: number;
    timestamp: string;
}
export interface GetArticlesDestacados {
    num_cajas: number;
    country_code: string;
}

export interface ArticleImages {
    image_preview: string;
    image_1: string;
    image_2: string;
    image_3: string;
}

export enum ModoUbicacion {
    SIN_GPS = 0,
    CON_GPS = 1,
}

export enum Format {
    JSON = "json",
}

export enum CategoriasID {
    LITERATURA = 5,
    EDUCACION = 3,
    COMIC = 2,
    INFANTIL = 6,
    CIENCIA = 1,
    HISTORIA = 4,
}

export enum SubCategoriasID {
    SIN_SUBCATEGORIA = 0,
    AUTOAYUDA = 27,
    AVENTURAS = 16,
    BIOGRAFIA = 17,
    COMEDIA = 18,
    DRAMA = 19,
    EROTICA = 24,
    FANTASIA = 26,
    FICCION = 25,
    POESIA = 20,
    ROMANTICO = 21,
    TERROR = 22,
    OTROS = 23,
    PREESCOLAR = -1,
    PRIMARIA = -2,
    SECUNDARIA = -3,
    BACHILLERATO = -4,
    FORMACION_PROFESIONAL = 13,
    UNIVERSIDAD = 14,
    VACACIONES = 15,
    PRIMERO_PRIMARIA = 1,
    SEGUNDO_PRIMARIA = 2,
    TERCERO_PRIMARIA = 3,
    CUARTO_PRIMARIA = 4,
    QUINTO_PRIMARIA = 5,
    SEXTO_PRIMARIA = 6,
    PRIMERO_ESO = 7,
    SEGUNDO_ESO = 8,
    TERCERO_ESO = 9,
    CUARTO_ESO = 10,
    PRIMERO_BACHILLERATO = 11,
    SEGUNDO_BACHILLERATO = 12,
}

export enum SubCategoriasString {
    AUTOAYUDA = "autoayuda",
    AVENTURAS = "aventura",
    BIOGRAFIA = "biografia",
    COMEDIA = "comedia",
    DRAMA = "drama",
    EROTICA = "erotica",
    FANTASIA = "fantasia",
    FICCION = "ficcion",
    POESIA = "poesia",
    ROMANTICO = "romantica",
    TERROR = "terror",
    OTROS = "otros",
    PREESCOLAR = "preescolar",
    PRIMARIA = "primaria",
    SECUNDARIA = "eso",
    BACHILLERATO = "bachiller",
    FORMACION_PROFESIONAL = "formacion",
    UNIVERSIDAD = "universidad",
    VACACIONES = "vacaciones",
    PRIMERO = "primero",
    SEGUNDO = "segundo",
    TERCERO = "tercero",
    CUARTO = "cuarto",
    QUINTO = "quinto",
    SEXTO = "sexto",
    PRIMERO_PRIMARIA = "primaria-primero",
    SEGUNDO_PRIMARIA = "primaria-segundo",
    TERCERO_PRIMARIA = "primaria-tercero",
    CUARTO_PRIMARIA = "primaria-cuarto",
    QUINTO_PRIMARIA = "primaria-quinto",
    SEXTO_PRIMARIA = "primaria-sexto",
    PRIMERO_ESO = "secundaria-primero",
    SEGUNDO_ESO = "secundaria-segundo",
    TERCERO_ESO = "secundaria-tercero",
    CUARTO_ESO = "secundaria-cuarto",
    PRIMERO_BACHILLERATO = "bachillerato-primero",
    SEGUNDO_BACHILLERATO = "bachillerato-segundo",
    SIN_SUBCATEGORIA = "",
}

export enum CategoriasString {
    DEFECTO = "defecto",
    TODAS = "todas",
    SELECCIONA_CATEGORIA = "",
    LITERATURA = "literatura",
    EDUCACION = "educacion",
    COMIC = "comics",
    INFANTIL = "infantiles",
    CIENCIA = "ciencia",
    HISTORIA = "historia",
    PRIMARIA = "primaria",
    SECUNDARIA = "secundaria",
    BACHILLERATO = "bachillerato",
}

export enum CategoriasStringServer {
    SELECCIONA_CATEGORIA = "",
    LITERATURA = "literature",
    EDUCACION = "education",
    COMIC = "comic",
    INFANTIL = "children",
    CIENCIA = "science",
    HISTORIA = "history",
    TODAS = "todas"
}

export enum CategoriasInternasAnuncios {
    AMAZON = "Amazon",
    PREMIUM = "Premium",
    INTERNO = "Interno",
}

export interface ObjCategoria {
    id_categoria: number;
    sub_filtro: SubCategoriasID;
    imagen: string;
}

export interface GetArticlesCategoria {
    valorCategoria: CategoriasID;
    stringCategoria?: CategoriasString;
    favoritos: string;
    type_app: number;
    filtro: number;
    latitudActual: string;
    longitudActual: string;
    modoUbicacion: ModoUbicacion;
    ultimaDistance: number;
    format: Format;
    num_cajas: number;
    id_ultimo_articulo: number | string;
    conBoton: string;
    tipo_android_app: number;
    country_code: string;
}

export interface GetArticulosInicial {
    idUltimoCiencia: string;
    idUltimoComic: string;
    idUltimoEducacion: string;
    idUltimoHistoricos: string;
    idUltimoLiteratura: string;
    idUltimoInfantil: string;
    idUser: string;
    favoritos: string;
    type_app: number;
    format: Format;
    num_cajas: number;
    conBoton: string;
    tipo_android_app: number;
    type_reduced?: string;
    country_code?: string;
}

export interface GetArticulosCercania {
    ultimaDistance: number;
    latitudActual: number;
    longitudActual: number;
    ideUltimo: string;
    favoritos?: string;
    type_app: number;
    format: Format;
    num_cajas: number;
    conBoton: string;
    tipo_android_app: number;
    country_code: string;
}

export interface GetArticulosPropiosBusqueda {
    idUltimoCiencia: string;
    idUltimoComic: string;
    idUltimoEducacion: string;
    idUltimoHistoricos: string;
    idUltimoLiteratura: string;
    idUltimoInfantil: string;
    texto_buscar: string;
    format: Format;
    num_cajas: number;
    conBoton: string;
    type_app: number;
    id_user: number;
}

export interface GetArticulosPropios {
    id_user: number;
    emailGoogle: string;
    idUltimoCiencia: string;
    idUltimoComic: string;
    idUltimoEducacion: string;
    idUltimoHistoricos: string;
    idUltimoLiteratura: string;
    idUltimoInfantil: string;
    favoritos: string;
    type_app: number;
    format: Format;
    num_cajas: number;
    conBoton: string;
    tipo_android_app: number;
}

export interface GetArticulosBusqueda {
    textoBuscar: string;
    filtro: string;
    idUltimoCiencia: string;
    idUltimoComic: string;
    idUltimoEducacion: string;
    idUltimoHistoricos: string;
    idUltimoLiteratura: string;
    idUltimoInfantil: string;
    categoriaFiltrada: string;
    nuevos: boolean;
    pocoUsados: boolean;
    usados: boolean;
    muyUsado: boolean;
    tapaDura: boolean;
    tapaBlanda: boolean;
    favoritos: string;
    type_app: number;
    format: Format;
    num_cajas: number;
    conBoton: string;
    tipo_android_app: number;
    country_code: string;
    ip_busqueda?: string;
}

export interface AvisoLibroIncorrectoObj {
    id_libro: number,
    categoria_libro: string,
    id_user_avisa: number,
    texto: string
}

export interface PostArticuloObj {
    email: string;
    categoriaPrincipal: string;
    latitude: number;
    longitude: number;
    titulo: string;
    isbn: string;
    editorial: string;
    autor: string;
    descripcion: string;
    estado: string;
    precio: number;
    provincia: string;
    localidad: string;
    categoriaSecundaria: string;
    categoriaSub: string;
    idUsuario: number;
    formato: string;
    countryCode: string;
    moneyCode: string;
}

export interface PostImagenArticuloObj {
    categoria: CategoriasStringServer;
    nombre_imagen: string;
    ultimo_id: string;
    idUser: number;
}

export interface PostsImagenRemotoObj {
    categoria: CategoriasStringServer;
    nombre_imagen: string;
    ultimo_id: string;
    imagenSubida?: string;
    idUser: number;
}

export interface PostISBNArticuloRemoto {
    isbn: string;
    titulo: string;
    editorial: string;
    autor: string;
    imagenLibro: string;
    categoria: string;
    subcategoria: string;
    idUser: number;
}

export interface GetDatosLibroISBN {
    book: DatosLibroISBN[];
}

export interface DatosLibroISBN {
    id_isbn_libro?: number;
    isbn?: string;
    nombre_libro: string;
    editorial_libro: string;
    autor: string;
    imagen?: string;
    categoria?: string;
    subcategoria?: string;
}
export interface DatosLibroISBNFinder {
    titulo: string;
    editorial: string;
    autor: string;
}

export interface GetDatosLibroISBNFinderGoogle {
    items: DatosLibroISBNGoogle[];
}

export interface DatosLibroISBNGoogle {
    volumeInfo: VolumeInfoGoogle;
}

export interface VolumeInfoGoogle {
    title: string;
    publisher: string;
    authors: string[];
    imageLinks: ImageLinksGoogle;
}

export interface ImageLinksGoogle {
    thumbnail: string;
}

export enum ModoDestacarLibro {
    SIETE_DIAS = 0,
    UN_DIA_ANUNCIO = 1
}

export interface ResponseDestacarLibro {
    resultado: number;
}

export interface ObjMarcarVendido {
    isbn: string,
    title: string,
    idArticulo: number,
    categoriaArticulo: CategoriasStringServer,
    idUser: number,
    prize: number
}

export interface EditarArticuloObj {
    idArticle: number,
    categoria: string,
    titulo: string,
    descripcion: string,
    precio: number,
    isbn: string,
    telefono: number,
    autor: string,
    editorial: string,
    estado: EstadoLibro,
    formato: TipoTapa,
    ciudad: string,
    localidad: string,
    latitud: number,
    longitud: number,
    idUser: number
}

export interface PuntuarLibroObj {
    isbn: string,
    id_usuario: number,
    puntuacion: number,
    comentario: string
}

export interface GetPuntuacionLibro {
    comentarios: Comentario[] | null;
}

export interface Comentario {
    id: number,
    isbn: string,
    name?: string,
    id_usuario: number,
    puntuacion: number,
    comentario: string,
    timestamp: string,
    nombre_libro?: string
}

export interface GetPuntuarUsuario {
    puntuar: PuntuarUsuarioLibroObj[]
}

export interface PuntuarUsuarioLibroObj {
    id_libros_vendidos: number,
    ISBN: string,
    Nombre: string,
    id_articulo: number,
    categoria_articulo: CategoriasStringServer,
    id_user_vende: number,
    id_user_compra: number,
    precio: string,
    aviso_notificacion: number,
    aviso_puntuar: number,
    beseif: number,
    fecha: string
}

export interface ComentarUsuarioObj {
    id_user_puntua: number,
    id_user_puntuado: number,
    puntuacion: number,
    comentario: string,
    id_articulo: number,
    categoria_articulo: string,
    titulo_articulo: string
}

export interface TextoBusqueda {
    id: number,
    texto_busqueda: string,
    filtro: string,
    country_code: string
    timestamp: string
}

export interface MediaLibroObj {
    precioMinimo: number;
    precioMaximo: number;
    media: number;
}