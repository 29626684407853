import { useRouter } from 'next/router'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import Image from 'next/image'
import { setCookieNextLocale } from '@/util/CookieStorage'
import ImageCustom from '../elements/ImageCustom'

const LanguageSwitcher = () => {
    const router = useRouter()
    const [currentLanguage, setCurrentLanguage] = useState(router.locale)

    useEffect(() => {
        //console.log('LanguageSwitcher useEffect', router.locale)
        setCurrentLanguage(router.locale)
        //setCookieNextLocale(null, String(router.locale))
    }, [router.locale])

    const getLanguageLink = (lang: string) => {
        const currentPath = router.asPath
        let langUsar = lang
        if (lang === 'es') {
            langUsar = ''
        }
        return langUsar ? `/${langUsar}${currentPath}` : currentPath
    }

    const containerStyle = {
        display: 'flex',
    }

    const flagStyle = {
        marginRight: '5px',
        cursor: 'pointer',
        border: '2px solid transparent',
        transition: 'border 0.3s ease',
        verticalAlign: 'middle',
        alignItems: 'center',
    }

    const selectedFlagStyle = {
        ...flagStyle,
        border: '2px solid white',
        borderRadius: '4px',
    }

    return (
        <div style={containerStyle}>
            {/*TODO: poner idiomasDisponibles importado de Comun.tsx*/}
            {[
                { language: 'es', flag: '/images/flags/es.svg', title: 'QuieroLibros en Español', alt: 'Icono de idioma Español' },
                { language: 'en', flag: '/images/flags/uk.svg', title: 'QuieroLibros in English', alt: 'Language icon English' },
                // Agrega más idiomas según sea necesario
            ].map(({ language, flag, alt, title }) => (
                <Link title={title} href={getLanguageLink(language)} locale={language} key={language} onClick={() => { /*setCurrentLanguage(language)*/ }}>
                    <ImageCustom
                        src={flag}
                        alt={alt}
                        style={currentLanguage === language ? selectedFlagStyle : flagStyle}
                        width={38} // Ajusta el ancho según sea necesario
                        height={30} // Ajusta la altura según sea necesario
                    />
                </Link>
            ))}
        </div>
    )
}

export default LanguageSwitcher