import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import { black, white } from '@/constants/Colors';

let setOpenExt: (arg: boolean) => void;
let setMessageExt: (arg: string) => void;
let setContentExt: (arg: React.ReactNode) => void;

const snackbarStyle: React.CSSProperties = {
    minWidth: '250px',
    backgroundColor: white,
    color: black,
    position: 'fixed',
    zIndex: 999,
    right: '30px',
    padding: "20px",
    top: '100px',
};

const CustomSnackbar: React.FC = () => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [content, setContent] = useState<React.ReactNode | null>(null);

    setOpenExt = setOpen;
    setMessageExt = setMessage;
    setContentExt = setContent;

    useEffect(() => {
        if (open) {
            const timer = setTimeout(() => {
                setOpen(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [open]);

    return (
        <Card
            style={{ ...snackbarStyle, visibility: open ? 'visible' : 'hidden' }}
        >
            {content ? content : message}
        </Card>
    );
};

export interface SnackbarParams {
    message?: string;
    content?: React.ReactNode;
}

export default CustomSnackbar;

export const mostrarSnackbar = (params: SnackbarParams) => {
    setOpenExt(true);
    setMessageExt(params.message || '');
    setContentExt(params.content || null);
};