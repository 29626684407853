import React from 'react';
import Image from 'next/image';

export enum TypeLoading {
    EAGER = "eager",
    LAZY = "lazy"
}

export enum TypeObjectFit {
    CONTAIN = "contain",
    COVER = "cover",
    FILL = "fill",
    NONE = "none",
    SCALE_DOWN = "scale-down"
}

export enum LayoutImageType {
    FIXED = "fixed",
    INTRINSIC = "intrinsic",
    RESPONSIVE = "responsive",
    FILL = "fill"
}

//Definimos los tipos de datos que va a recibir el componente en forma de interfaz
interface Props {
    src: string;
    alt: string;
    title?: string;
    width?: number;
    height?: number;
    layout?: LayoutImageType;
    objectFit?: TypeObjectFit;
    loading?: TypeLoading;
    style?: React.CSSProperties;
    className?: string;
    onError?: () => void;
    onClick?: () => void;
}

function ImageCustom({ src, onError, onClick, alt, title, width, height, style, layout, loading = TypeLoading.LAZY, objectFit = TypeObjectFit.NONE, className = "" }: Props) {

    return (
        <Image
            className={className}
            src={src}
            onError={onError}
            onClick={onClick}
            alt={alt}
            title={title}
            width={width}
            height={height}
            loading={loading}
            objectFit={objectFit}
            style={style}
            layout={layout}
        />
    );
}

export default ImageCustom;