import Button from '@mui/material/Button';
import { gray, lightGray, primary, white } from '../../constants/Colors';
import { textoBotones } from '../../constants/Dimens';
import { Box } from '@mui/material';
import LinkCustom from './LinkCustom';


//Definimos los tipos de datos que va a recibir el componente en forma de interfaz
interface Props {
    texto: string;
    link?: string;
    title?: string;
    color?: string; // El color es opcional
    colorTexto?: string;
    onClick?: any; // La función es opcional
    fullWidth?: boolean;
    height?: string;
    width?: string;
    endIcon?: JSX.Element | null;
    startIcon?: React.ReactNode | null;
    disabled?: boolean;
    margin?: boolean
    id?: string
}

function Boton({ id, texto, link = "", title, color = primary, colorTexto = white, onClick = () => { }, fullWidth = false, height = "50px", width = "auto", startIcon = null, endIcon = null, margin = true, disabled = false }: Props) {

    //Style:
    const buttonStyle: React.CSSProperties = {
        backgroundColor: disabled ? lightGray : color,
        color: disabled ? white : colorTexto,
        fontWeight: 'bold',
        width: fullWidth ? '100%' : width,
        height: height,
        //Texto en tamaño de 16px
        fontSize: textoBotones,
        //Texto en mayuscula
        textTransform: 'none',
        textAlign: 'center',
        marginTop: margin ? '5px' : '0px',
        marginBottom: margin ? '5px' : '0px',
        marginRight: margin ? '5px' : '0px',
    };

    const botonLink: React.CSSProperties = {
        width: fullWidth ? '100%' : width,
    };


    return (
        link === "" ?
            <Button id={id} variant="contained" style={buttonStyle} onClick={onClick} endIcon={endIcon} startIcon={startIcon} disabled={disabled}>
                <Box width="100%">{texto}</Box>
            </Button> :
            <LinkCustom style={botonLink} styleLink={botonLink} href={link} color={colorTexto} title={String(title)}>
                <Button id={id} variant="contained" style={buttonStyle} onClick={onClick} endIcon={endIcon} startIcon={startIcon} disabled={disabled}>
                    <Box width="100%">{texto}</Box>
                </Button>
            </LinkCustom>
    );
}

export default Boton;
