import * as React from 'react';
import { Menu, MenuItem } from '@mui/material';
import BotonBarraMovil from './BotonBarraMovil';

interface Props {
    textoMenu?: string;
    colorTextoBoton?: string;
    icon?: JSX.Element | null;
    listaElementosMenu: Array<JSX.Element>;
}

export default function MenuDropDownMovil({ textoMenu = "test", listaElementosMenu = [], icon }: Props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <BotonBarraMovil onClick={handleClick} icon={icon!}> {/* Reemplaza el Button por BotonBarraMovil */}
                {textoMenu}
            </BotonBarraMovil>
            <Menu
                autoFocus={false}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {listaElementosMenu.map((elemento, index) => (
                    <MenuItem key={index} onClick={handleClose}>{elemento}</MenuItem>
                ))}
            </Menu>
        </div>
    );
}