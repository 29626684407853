import { black, primaryDark } from '@/constants/Colors';
import { textos, tituloH1 } from '@/constants/Dimens';
import { Typography } from '@mui/material';
import Link from 'next/link';

//Definimos los tipos de datos que va a recibir el componente en forma de interfaz
interface Props {
    title: string;
    href: string;
    children?: React.ReactNode;
    color?: string;
    icon?: JSX.Element;
    target?: string;
    style?: React.CSSProperties;
    styleLink?: React.CSSProperties;
}

function LinkCustom({ children, title, color = primaryDark, href, icon, style, styleLink, target = "" }: Props) {

    const styleCustom: React.CSSProperties = {
        fontSize: textos,
        color: color,
        fontWeight: '600',
        userSelect: 'none',
        textDecoration: 'none',
        textAlign: 'left',
        cursor: 'pointer'
    };


    return (
        <div style={{ display: 'flex', ...style }}>
            {icon && <div style={{ width: "30px", height: "30px" }}>{icon}</div>}
            <Link style={{ ...styleCustom, ...styleLink }}
                title={title} href={href} target={target}>{children}</Link>
        </div>
    );
}

export default LinkCustom;