
import { GetLocalizacionIP, GetLocalizacionTexto, ReturnNominatim } from '@/objects/Localizacion';
import { AxiosInstance, AxiosResponse } from 'axios';
import { axiosBaseURLNominatim } from '@/util/NetworkInstance';
//Buscar pacientes
export default class LocalizacionQueries {

    //Este se utiliza para las llamadas que requieren token
    private axiosInstanceToken: AxiosInstance
    private axiosNominatim: AxiosInstance


    constructor(axiosInstanceToken: AxiosInstance) {
        this.axiosInstanceToken = axiosInstanceToken
        this.axiosNominatim = axiosBaseURLNominatim()
        //   this.userData = userData
    }
    //TODO: NO SE USA, utilizo uno dentro de util/ubicacion
    public async obtenerLatLonPorIP(): Promise<AxiosResponse<GetLocalizacionIP>> {
        try {
            const url = `/php/actions/obtener_lat_long_por_ip.php`;
            const response: AxiosResponse<GetLocalizacionIP> = await this.axiosInstanceToken.get(url);
            return response;
        } catch (error: any) {
            console.log('Error en obtener Lat Lon back', error);
            return error;
            //throw new Error(error.message);
        }
    }

    public async obtenerTextoLocalizacion(latitud: number, longitud: number): Promise<AxiosResponse<GetLocalizacionTexto>> {
        try {
            const url = `/python/nominatim_reverse.php?lat=${latitud}&lon=${longitud}`;
            const response: AxiosResponse<GetLocalizacionTexto> = await this.axiosInstanceToken.get(url);
            return response;
        } catch (error: any) {
            console.log('Error en obtener Nominaim Reverse', error);
            return error;
            //throw new Error(error.message);
        }
    }

    public async obtenerLatLonPorTextoLocalizacion(texto: string): Promise<AxiosResponse<ReturnNominatim[]>> {
        try {
            let query =  texto;
            query = query.replace(" ", "+");
            const format = "json";

            const url = `/search?q=${query}&format=${format}`;
            const response: AxiosResponse<ReturnNominatim[]> = await this.axiosNominatim.get(url);
            return response;
        } catch (error: any) {
            console.log('Error en obtener Text localizacion', error);
            return error;
            //throw new Error(error.message);
        }
    }
        
}
