import React from 'react';
import LinkCustom from '../elements/LinkCustom';
import { useTranslation } from 'next-i18next';
import { white } from '@/constants/Colors';
import TituloSeccionH2 from '../elements/TituloSeccionH2';
import { textos } from '@/constants/Dimens';

function LinksUsadosFooter() {
  const { t } = useTranslation();

  return (
    <section style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
      <TituloSeccionH2 fontSize={textos} color={white} texto={t(`common:articulos.libros_usados`)} />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: "100%", paddingLeft: "10px"}}>
        <LinkCustom
          color={white}
          href={`/libros-usados-oferta/infantil`}
          title={t(`common:articulos.libros_usados_de`, { categoria: t(`common:categorias.infantiles.titulo`) })}
        >
          {t(`common:categorias.infantiles.titulo`)}
        </LinkCustom>
        <LinkCustom
          color={white}
          href={`/libros-usados-oferta/literatura`}
          title={t(`common:articulos.libros_usados_de`, { categoria: t(`common:categorias.literatura.titulo`) })}
        >
          {t(`common:categorias.literatura.titulo`)}
        </LinkCustom>
        <LinkCustom
          color={white}
          href={`/libros-usados-oferta/comic`}
          title={t(`common:articulos.libros_usados_de`, { categoria: t(`common:categorias.comic.titulo`) })}
        >
          {t(`common:categorias.comic.titulo`)}
        </LinkCustom>
        <LinkCustom
          color={white}
          href={`/libros-usados-oferta/educacion`}
          title={t(`common:articulos.libros_usados_de`, { categoria: t(`common:categorias.educacion.titulo`) })}
        >
          {t(`common:categorias.educacion.titulo`)}
        </LinkCustom>
        <LinkCustom
          color={white}
          href={`/libros-usados-oferta/ciencia`}
          title={t(`common:articulos.libros_usados_de`, { categoria: t(`common:categorias.ciencia.titulo`) })}
        >
          {t(`common:categorias.ciencia.titulo`)}
        </LinkCustom>
        <LinkCustom
          color={white}
          href={`/libros-usados-oferta/historia`}
          title={t(`common:articulos.libros_usados_de`, { categoria: t(`common:categorias.historia.titulo`) })}
        >
          {t(`common:categorias.historia.titulo`)}
        </LinkCustom>
      </div>
    </section >
  );
}

export default LinksUsadosFooter;
