import { GoogleAPIBooksResponse } from '@/objects/GoogleAPI';
import { axiosBaseURLGoogle } from '@/util/NetworkInstance';
import { AxiosInstance, AxiosResponse } from 'axios';

//Buscar pacientes
export default class GoogleAPIQueries {

    private axiosInstance: AxiosInstance

    constructor() {
        this.axiosInstance = axiosBaseURLGoogle();
    }

    public async buscarSinopsisLibro(isbn: string): Promise<AxiosResponse<GoogleAPIBooksResponse>> {
        try {
            const url = `/books/v1/volumes?q=${isbn}`;
            const response: AxiosResponse<GoogleAPIBooksResponse> = await this.axiosInstance.get(url);
            return response;
        } catch (error: any) {
            console.log('Error en buscarSinopsisLibro', error);
            return error;
            //throw new Error(error.message);
        }
    }


}
