export const baseURL = "https://api.quierolibros.com"
export const urlWeb = "https://www.quierolibros.com"
export const urlWebLocal = "http://localhost:3000"
export const baseURLImages = "https://images.quierolibros.com"
export const baseURLGoogleAPI = "https://www.googleapis.com"
export const baseURLNominatim = "https://nominatim.openstreetmap.org"

export const urlAPPAndroid = "https://play.google.com/store/apps/details?id=com.quierolibros"
export const urlAPPIOS = "https://apps.apple.com/es/app/quiero-libros-compra-y-vende/id1661699849"

export const urlBeseifComprar = "https://beseif.com/marketplace/pre_checkout?webview=true&codigo="
export const urlBeseifMisTransacciones = "https://beseif.com/marketplace/pre_mis-transacciones?webview=true&codigo="

export const emailPlataforma = "contact@quierolibros.com"

export const GoogleSigninClientId = "539371599921-k9hs0o5ejdc05b4t7he93ites5l5lajv.apps.googleusercontent.com"
export const GoogleReCaptchaSiteKey = "6LeWUvQUAAAAABCkS2IYzqYgASC9UIvpqSemtXnV"

export const FacebookSiteKey = "1765033603764829"

export const IDGoogleAnalytics = "G-VH353PXP1S"

export const IDGoogleAds = "AW-16657178728"

//Ads:
export const IDAdsense = "ca-pub-9376549970510647"
export const SlotAnuncioDisplay = "7526924542"
export const SlotAnuncioFeed = "2274597866"
export const SlotAnuncioInArticle = "9641951992"
export const SlotAnuncioMultiplex = "1582331287"