
import { EnviarChatObj, GetChatObj, GetMensajesChat, GetMensajesNoLeidosObj, GetUsuarioBloqueado, MensajesChatObj, ObtenerChatBloqueado, ReportarUsuarioChat } from '@/objects/Chat';
import { AxiosInstance, AxiosResponse } from 'axios';

//Buscar pacientes
export default class MensajesQueries {

    //Este se utiliza para las llamadas que requieren token
    private axiosInstanceToken: AxiosInstance
    // private userData: LoginDataResponse

    constructor(axiosInstanceToken: AxiosInstance) {
        this.axiosInstanceToken = axiosInstanceToken
        //   this.userData = userData
    }

    public async obtenerMensajesNoLeidos(idUsuario: number): Promise<AxiosResponse<GetMensajesNoLeidosObj>> {
        try {
            const url = `/php/chat/chat_obtener_mensajes_sin_leer_token.php?id_receptor=${idUsuario}`;
            const response: AxiosResponse<GetMensajesNoLeidosObj> = await this.axiosInstanceToken.get(url);
            return response;
        } catch (error: any) {
            console.log('Error en obtener Mensajes No Leidos', error);
            return error;
            //throw new Error(error.message);
        }

    }

    public async obtenerMensajesChat(idUsuario: number, idChat: number): Promise<AxiosResponse<GetMensajesChat>> {
        try {
            const url = `/php/chat/chat_obtener_mensajes_chat_token.php?id_receptor=${Number(idUsuario)}&id_chat=${Number(idChat)}`;
            const response: AxiosResponse<GetMensajesChat> = await this.axiosInstanceToken.post(url);
            return response;
        } catch (error: any) {
            console.log('Error en obtener Mensajes Chat', error);
            return error;
            //throw new Error(error.message);
        }
    }

    public async responderChat(objEnviarChat: EnviarChatObj): Promise<AxiosResponse<number>> {
        try {
            const url = `/php/chat/chat_respondiendo_mensaje_token_post_v2.php`;
            const response: AxiosResponse<number> = await this.axiosInstanceToken.post(url, JSON.stringify(objEnviarChat));
            return response;
        } catch (error: any) {
            console.log('Error en ENVIAR Responder Chat', error);
            return error;
            //throw new Error(error.message);
        }
    }

    public async nuevoMensajeChat(objEnviarChat: EnviarChatObj): Promise<AxiosResponse<number>> {
        try {
            const url = `/php/chat/chat_nuevo_mensaje_token_post_v2.php`;
            const response: AxiosResponse<number> = await this.axiosInstanceToken.post(url, JSON.stringify(objEnviarChat));
            return response;
        } catch (error: any) {
            console.log('Error en ENVIAR Nuevo Mensaje Chat', error);
            return error;
            //throw new Error(error.message);
        }
    }

    public async cargarBloqueadoChat(objEnviarChat: ObtenerChatBloqueado): Promise<AxiosResponse<number>> {
        try {
            const url = `/php/chat/chat_es_usuario_bloqueado.php`;
            const response: AxiosResponse<number> = await this.axiosInstanceToken.post(url, JSON.stringify(objEnviarChat));
            return response;
        } catch (error: any) {
            console.log('Error en cargar Bloqueado Chat', error);
            return error;
            //throw new Error(error.message);
        }
    }

    public async bloquearUsuarioChat(objEnviarChat: ObtenerChatBloqueado): Promise<AxiosResponse<number>> {
        try {
            const url = `/php/chat/chat_bloquear_usuario.php`;
            const response: AxiosResponse<number> = await this.axiosInstanceToken.post(url, JSON.stringify(objEnviarChat));
            return response;
        } catch (error: any) {
            console.log('Error Bloquear Usuario Chat', error);
            return error;
            //throw new Error(error.message);
        }
    }

    public async reportarUsuarioChat(objEnviarChat: ReportarUsuarioChat): Promise<AxiosResponse<number>> {
        try {
            const url = `/php/chat/avisar_usuario_chat.php`;
            const response: AxiosResponse<number> = await this.axiosInstanceToken.post(url, JSON.stringify(objEnviarChat));
            return response;
        } catch (error: any) {
            console.log('Error Reportar Usuario Chat', error);
            return error;
            //throw new Error(error.message);
        }
    }

    public async borrarConversacionChat(id_user: number, id_chat: number): Promise<AxiosResponse<number>> {
        try {
            const url = `/php/chat/chat_borrar_conversacion.php?id_user=${id_user}&id_chat=${id_chat}`;
            const response: AxiosResponse<number> = await this.axiosInstanceToken.get(url);
            return response;
        } catch (error: any) {
            console.log('Error en Borrar Conversacion Chat', error);
            return error;
            //throw new Error(error.message);
        }
    }

    public async obtenerListaChats(idUsuario: number): Promise<AxiosResponse<GetChatObj>> {
        try {
            const url = `/php/chat/chat_obtener_chats_token.php?id_receptor=${idUsuario}`;
            const response: AxiosResponse<GetChatObj> = await this.axiosInstanceToken.post(url);
            return response;
        } catch (error: any) {
            console.log('Error en Obtener Lista Chats', error);
            return error;
            //throw new Error(error.message);
        }
    }

    public async obtenerUsuarioBloqueado(objUsuarioBloqueado: GetUsuarioBloqueado): Promise<AxiosResponse<number>> {
        try {
            const url = `/php/chat/chat_es_usuario_bloqueado.php`;
            const response: AxiosResponse<number> = await this.axiosInstanceToken.post(url, objUsuarioBloqueado);
            return response;
        } catch (error: any) {
            console.log('Error en Obtener Usuario Bloqueado', error);
            return error;
            //throw new Error(error.message);
        }
    }

    public async bloquearUsuario(objUsuarioBloqueado: GetUsuarioBloqueado): Promise<AxiosResponse<number>> {
        try {
            const url = `/php/chat/chat_bloquear_usuario.php`;
            const response: AxiosResponse<number> = await this.axiosInstanceToken.post(url, objUsuarioBloqueado);
            return response;
        } catch (error: any) {
            console.log('Error en Bloquear Usuario', error);
            return error;
            //throw new Error(error.message);
        }
    }

    public async reportarUsuario(objUsuarioBloqueado: GetUsuarioBloqueado): Promise<AxiosResponse<number>> {
        try {
            const url = `/php/chat/avisar_usuario_chat.php`;
            const response: AxiosResponse<number> = await this.axiosInstanceToken.post(url, objUsuarioBloqueado);
            return response;
        } catch (error: any) {
            console.log('Error REPORTAR usuario', error);
            return error;
            //throw new Error(error.message);
        }
    }

    //TODO: Deprecated
    public async enviarTrato(objEnviarChat: EnviarChatObj): Promise<AxiosResponse<number>> {
        try {
            const url = `/php/chat/chat_enviar_trato_token_post_v2.php`;
            const response: AxiosResponse<number> = await this.axiosInstanceToken.post(url, JSON.stringify(objEnviarChat));
            return response;
        } catch (error: any) {
            console.log('Error en enviar trato', error);
            return error;
            //throw new Error(error.message);
        }
    }
}
