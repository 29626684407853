// components/ArticleList.tsx
import React from 'react';
import LinkCustom from '@/components/elements/LinkCustom';
import { useTranslation } from 'next-i18next';
import { white } from '@/constants/Colors';

const CiudadesLink: React.FC = () => {
  const { t } = useTranslation();
  return (
    <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: "100%", paddingLeft: "10px" }}>
      <LinkCustom
        color={white}
        href={`/libros-de-segunda-mano-en-tu-ciudad`}
        title={t(`contenido:libros-de-segunda-mano-en-tu-ciudad.title`)}
      >
        {t(`contenido:libros-de-segunda-mano-en-tu-ciudad.texto_link`)}
      </LinkCustom>
    </section>
  );
};

export default CiudadesLink;
