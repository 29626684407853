import { LoginDatos } from "@/objects/User";
import { TFunction } from "next-i18next";
import { cargarDatosUsuario } from "./LocalStorageLoaded";

export function isPhoneValid(phone: number | string) {
    const re = /^[0-9]{9}$/;
    return re.test(String(phone));
}

export function isEmailValid(email: string) {
    const re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function isPasswordValid(password: string) {
    const re = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{6,30}$/;
    return re.test(String(password));
}

export function isValidISBN(isbn: string) {
    const re = /^(?:ISBN(?:-13)?:?●)?(?=[0-9X]{10}$|(?=(?:[0-9]+[-●]){3})[-●0-9X]{13}$|97[89][0-9]{10}$|(?=(?:[0-9]+[-●]){4})[-●0-9]{17}$)(?:97[89][-●]?)?[0-9]{1,5}[-●]?[0-9]+[-●]?[0-9]+[-●]?[0-9X]$/;
    return re.test(String(isbn));
}

export function limpiarISBN(isbn: string) {
    isbn = isbn.replace(/-/g, "");
    isbn = isbn.replace(/●/g, "");
    isbn = isbn.replace("X", "9");
    isbn = isbn.replace("x", "9");
    isbn = isbn.replace(" ", "");
    return isbn;
}

export function primeraLetraMayuscula(minusculado: string) {
    var mayusculado = "";
    if (minusculado != null && minusculado.length > 0) {
        var primeraLetra = minusculado.charAt(0) + "";
        primeraLetra = primeraLetra.toUpperCase();
        mayusculado = minusculado.substring(1);
        mayusculado = primeraLetra + mayusculado;
    } else {
        mayusculado = minusculado;
    }
    return mayusculado;
}

export function apellidoConPunto(nombre: string): string {
    //El nombre tiene el apellido como segundo nombre separado por un espacio
    //Retorna nombre y apellido con punt el apellido
    var retorno = nombre;
    if (nombre != null && nombre != "") {
        var palabras = nombre.split(" ");
        if (palabras.length > 1) {
            retorno = palabras[0] + " " + palabras[1].charAt(0) + ".";
        }
    }
    return retorno;
}

export function filtroUsado(uso_libro: string, t: TFunction) {
    let uso = String(uso_libro).toLowerCase();
    if (uso != null) {
        if (uso == "usado") {
            uso = t("common:uso_libro.usado");
        } else if (uso == "poco usado") {
            uso = t("common:uso_libro.poco_usado");
        } else if (uso == "muy usado") {
            uso = t("common:uso_libro.muy_usado");
        } else if (uso == "nuevo") {
            uso = t("common:uso_libro.nuevo");
        }
    }
    if (uso == "0") {
        uso = t("common:uso_libro.usado");
    }
    return uso;
}

export function filtroTapa(tapa_libro: string, t: TFunction) {

    let tapa = String(tapa_libro).toLowerCase();

    //alert(tapa_libro + " " + tapa)

    if (tapa != null) {
        if (tapa == "tapa blanda") {
            tapa = t("common:tapa_libro.tapa_blanda");
        } else if (tapa == "tapa dura") {
            tapa = t("common:tapa_libro.tapa_dura");
        } else {
            tapa = t("common:tapa_libro.tapa_blanda");
        }
    }
    return tapa;
}


export function filtroCategoria(subcategoria: string, t: TFunction) {
    if (subcategoria == "poesia") {
        subcategoria = t("common:categorias.subcategorias.literatura.poesia");
    } else if (subcategoria == "biografia") {
        subcategoria = t("common:categorias.subcategorias.literatura.biografia");
    } else if (subcategoria == "erotica") {
        subcategoria = t("common:categorias.subcategorias.literatura.erotica");
    } else if (subcategoria == "fantasia") {
        subcategoria = t("common:categorias.subcategorias.literatura.fantasia");
    } else if (subcategoria == "ficcion") {
        subcategoria = t("common:categorias.subcategorias.literatura.ficcion");
    } else if (subcategoria == "romantico") {
        subcategoria = t("common:categorias.subcategorias.literatura.romantico");
    }
    return subcategoria;
}

export function filtroProvincia(provincia: string) {
    provincia = comunidades(provincia);
    return provincia;
}

function comunidades(texto: string) {
    const palabras = texto.split(" ");
    if (palabras[0] === "Comunidad") {
        if (palabras[1] !== "de") {
            texto = "C. " + palabras[1];
        } else {
            texto = "C. " + palabras[2];
        }
    }
    return texto;
}

export function limpiarBeseif(nombre: string) {
    nombre = nombre.replace("\\s", "_");
    nombre = nombre.replace("\\(", "_");
    nombre = nombre.replace("\\)", "_");
    nombre = nombre.replace(".", "_");
    nombre = nombre.replace(",", "_");
    nombre = nombre.replace(";", "_");
    nombre = nombre.replace("\\+", "_");
    nombre = nombre.replace("\\-", "_");
    nombre = nombre.replace("\\*", "_");
    nombre = nombre.replace("$", "_");
    nombre = stripAccents(nombre);
    if (nombre.length > 30) {
        nombre = nombre.substring(0, 30);
        nombre += "...";
    }
    return nombre;
}


export function stripAccents(s: string) {
    return s.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function filtraCodigoPais(codigoPais: string, t: TFunction) {
    var retorno = codigoPais;
    if (codigoPais == "AR") {
        retorno = t("common:paises.argentina");
    }
    if (codigoPais == "BO") {
        retorno = t("common:paises.bolivia");
    }
    if (codigoPais == "BR") {
        retorno = t("common:paises.brasil");
    }
    if (codigoPais == "CL") {
        retorno = t("common:paises.chile");
    }
    if (codigoPais == "CO") {
        retorno = t("common:paises.colombia");
    }
    if (codigoPais == "CR") {
        retorno = t("common:paises.costa_rica");
    }
    if (codigoPais == "CU") {
        retorno = t("common:paises.cuba");
    }
    if (codigoPais == "EC") {
        retorno = t("common:paises.ecuador");
    }
    if (codigoPais == "SV") {
        retorno = t("common:paises.el_salvador");
    }
    if (codigoPais == "ES") {
        retorno = t("common:paises.espana");
    }
    if (codigoPais == "GT") {
        retorno = t("common:paises.guatemala");
    }
    if (codigoPais == "HT") {
        retorno = t("common:paises.haiti");
    }
    if (codigoPais == "GQ") {
        retorno = t("common:paises.guinea_ecuatorial");
    }
    if (codigoPais == "HN") {
        retorno = t("common:paises.honduras");
    }
    if (codigoPais == "MX") {
        retorno = t("common:paises.mexico");
    }
    if (codigoPais == "NI") {
        retorno = t("common:paises.nicaragua");
    }
    if (codigoPais == "PA") {
        retorno = t("common:paises.panama");
    }
    if (codigoPais == "PY") {
        retorno = t("common:paises.paraguay");
    }
    if (codigoPais == "PE") {
        retorno = t("common:paises.peru");
    }
    if (codigoPais == "PR") {
        retorno = t("common:paises.puerto_rico");
    }
    if (codigoPais == "DO") {
        retorno = t("common:paises.republica_dominicana");
    }
    if (codigoPais == "UY" || codigoPais == "VEB") {
        retorno = t("common:paises.uruguay");
    }
    if (codigoPais == "VE") {
        retorno = t("common:paises.venezuela");
    }
    if (codigoPais == "FR") {
        retorno = t("common:paises.francia");
    }
    if (codigoPais == "PO") {
        retorno = t("common:paises.portugal");
    }

    if (codigoPais == "") {
        retorno = t("common:paises.todos");
    }
    return retorno;
}

export function ciudadAbreviada(cityTratada: string) {
    if (cityTratada == "Comunidad de Madrid") {
        cityTratada = "Madrid";
    } else if (cityTratada == "CAT") {
        cityTratada = "Cataluña";
    } else if (cityTratada == "null") {
        cityTratada = "";
    }
    return cityTratada;
}

export function ciudadRecortada(cityTratada: string) {
    if (cityTratada.length > 30) {
        cityTratada = cityTratada.substring(0, 30) + "...";
    }
    return cityTratada;
}


export function tituloTratado(titulo: string) {
    //Maximo tamaño de titulo 80
    var retorno = titulo;
    if (titulo.length > 40) {
        retorno = titulo.substring(0, 40) + "...";
    }
    return retorno;
}

export function idUsuarioMod(userId: number): string {
    return "" + Number(userId) + "-" + (Number(userId) + 213) + "-QLB";
}

export function esPremium(premium: string): boolean {
    if (premium == null || premium == undefined || premium == "" || premium == "null") {
        return false;
    }
    // Creamos un objeto Date con la fecha actual
    const fechaActual = new Date();
    // Creamos un objeto Date con la fechaPasada
    const fechaPremium = new Date(premium);
    // Comparamos las fechas
    const seHaPasadoDeFecha = fechaActual > fechaPremium;
    return premium != null && premium != undefined && premium != "" && !seHaPasadoDeFecha;
}

//si es usuario premium no va a mostrar los anuncios etc
export function noMostrarPorPremium(): boolean {
    const datosUsuario: LoginDatos | null = cargarDatosUsuario();
    if (datosUsuario?.premium) {
        return esPremium(datosUsuario?.premium);
    } else {
        return false;
    }
}