import {
    Article, AvisoLibroIncorrectoObj, CategoriasInternasAnuncios, ComentarUsuarioObj, Comentario, DatosLibroISBNFinder,
    EditarArticuloObj, GetArticlePatrocinador, GetArticlesCategoria, GetArticlesDestacados, GetArticlesResponse,
    GetArticulosBusqueda, GetArticulosCercania, GetArticulosInicial, GetArticulosPropios,
    GetArticulosPropiosBusqueda, GetDatosLibroISBN, GetDatosLibroISBNFinderGoogle, GetPatrocionador, GetPuntuacionLibro,
    GetPuntuarUsuario,
    MediaLibroObj,
    ModoDestacarLibro, ObjMarcarVendido, PostArticuloObj, PostISBNArticuloRemoto, PostImagenArticuloObj,
    PostsImagenRemotoObj, PuntuarLibroObj, ResponseDestacarLibro,
    TextoBusqueda
} from '@/objects/Articles';
import { getRandomHeight } from '@/util/Articulos';
import { getCookiePais } from '@/util/CookieStorage';
import { cargarToken } from '@/util/LocalStorageLoaded';
import { axiosImagesURL } from '@/util/NetworkInstance';
import { noMostrarPorPremium } from '@/util/StringsUtil';
import { AxiosInstance, AxiosResponse } from 'axios';

//Buscar pacientes
export default class ArticulosQueries {

    private axiosInstance: AxiosInstance
    private axiosInstanceToken: AxiosInstance
    // private userData: LoginDataResponse

    constructor(axiosInstance: AxiosInstance, axiosInstanceToken?: AxiosInstance) {
        this.axiosInstance = axiosInstance
        this.axiosInstanceToken = axiosInstanceToken!
        //   this.userData = userData
    }

    public async getArticulosInicial(getArticulosInicial: GetArticulosInicial): Promise<AxiosResponse<GetArticlesResponse>> {
        try {
            const { idUltimoCiencia, idUltimoComic, idUltimoEducacion, idUltimoHistoricos, idUltimoLiteratura, idUltimoInfantil, idUser, favoritos, type_app, format, num_cajas, conBoton, tipo_android_app, type_reduced, country_code } = getArticulosInicial;

            const stringBusqueda = "?idUltimoCiencia=" + idUltimoCiencia + "&idUltimoComic=" + idUltimoComic
                + "&idUltimoEducacion=" + idUltimoEducacion + "&idUltimoHistoricos=" + idUltimoHistoricos
                + "&idUltimoLiteratura=" + idUltimoLiteratura + "&idUltimoInfantil=" + idUltimoInfantil
                + "&idUser=" + idUser + "&favoritos=" + favoritos + "&type_app=" + type_app + "&format=" + format
                + "&num_cajas=" + num_cajas + "&conBoton=" + conBoton + "&tipo_android_app=" + tipo_android_app
                + "&type_reduced=" + type_reduced + "&country_code=" + country_code;

            const url = `/php/formularios/envioCajasInicio.php${stringBusqueda}`;
            const response: AxiosResponse<GetArticlesResponse> = await this.axiosInstance.get(url);
            response.data.articles.forEach((article: Article) => {
                article.masonryHeight = getRandomHeight(article.id_article);
            });
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async getArticulosCategoria(getArticlesCategoria: GetArticlesCategoria): Promise<AxiosResponse<GetArticlesResponse>> {
        try {
            const { id_ultimo_articulo, valorCategoria, filtro, favoritos, type_app, format, num_cajas, conBoton, modoUbicacion, latitudActual, longitudActual, ultimaDistance, tipo_android_app, country_code } = getArticlesCategoria;

            const stringBusqueda = "?idUltimoArticle=" + id_ultimo_articulo + "&valorcategoria=" + valorCategoria
                + "&filtro=" + filtro + "&favoritos=" + favoritos
                + "&type_app=" + type_app + "&format=" + format
                + "&num_cajas=" + num_cajas + "&conBoton=" + conBoton
                + "&ubicacion=" + modoUbicacion + "&latitudActual=" + latitudActual
                + "&longitudActual=" + longitudActual + "&ultimaDistance=" + ultimaDistance +
                "&tipo_android_app=" + tipo_android_app + "&country_code=" + country_code;

            //console.log('stringBusqueda:', stringBusqueda);

            const url = `/php/formularios/envioCajas.php${stringBusqueda}`;
            const response: AxiosResponse<GetArticlesResponse> = await this.axiosInstance.get(url);
            //console.log('response:', response.data);
            response.data.articles.forEach((article: Article) => {
                article.masonryHeight = getRandomHeight(article.id_article);
            });
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async getArticulosCategoriaTodos(getArticlesCategoria: GetArticlesCategoria): Promise<AxiosResponse<GetArticlesResponse>> {
        try {
            const { id_ultimo_articulo, valorCategoria, filtro, favoritos, type_app, format, num_cajas, conBoton, modoUbicacion, latitudActual, longitudActual, ultimaDistance, tipo_android_app, country_code } = getArticlesCategoria;

            const stringBusqueda = "?idUltimoArticle=" + id_ultimo_articulo + "&valorcategoria=" + valorCategoria
                + "&filtro=" + filtro + "&favoritos=" + favoritos
                + "&type_app=" + type_app + "&format=" + format
                + "&num_cajas=" + num_cajas + "&conBoton=" + conBoton
                + "&ubicacion=" + modoUbicacion + "&latitudActual=" + latitudActual
                + "&longitudActual=" + longitudActual + "&ultimaDistance=" + ultimaDistance +
                "&tipo_android_app=" + tipo_android_app + "&country_code=" + country_code;

            //console.log('stringBusqueda:', stringBusqueda);

            const url = `/php/formularios/envioCajas_todas.php${stringBusqueda}`;
            const response: AxiosResponse<GetArticlesResponse> = await this.axiosInstance.get(url);
            //console.log('response:', response.data);
            response.data.articles.forEach((article: Article) => {
                article.masonryHeight = getRandomHeight(article.id_article);
            });
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async getArticulosIndividual(getArticulosInicial: GetArticulosInicial): Promise<AxiosResponse<GetArticlesResponse>> {
        try {
            const { idUltimoCiencia, idUltimoComic, idUltimoEducacion, idUltimoHistoricos, idUltimoLiteratura, idUltimoInfantil, idUser, favoritos, type_app, format, num_cajas, conBoton, tipo_android_app } = getArticulosInicial;

            var stringBusqueda = "?idUser=" + idUser + "&emailGoogle=" + "" + "&idUltimoCiencia=" + idUltimoCiencia
                + "&idUltimoComic=" + idUltimoComic + "&idUltimoEducacion=" + idUltimoEducacion + "&idUltimoHistoricos=" + idUltimoHistoricos
                + "&idUltimoLiteratura=" + idUltimoLiteratura + "&idUltimoInfantil=" + idUltimoInfantil + "&favoritos=" + favoritos
                + "&type_app=" + type_app + "&format=" + format + "&num_cajas=" + num_cajas + "&conBoton=" + conBoton
                + "&tipo_android_app=" + tipo_android_app;

            const url = `/php/formularios/envioCajasIndividual.php${stringBusqueda}`;
            const response: AxiosResponse<GetArticlesResponse> = await this.axiosInstance.get(url);
            response.data.articles.forEach((article: Article) => {
                article.masonryHeight = getRandomHeight(article.id_article);
            });
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async getArticulosCercania(getArticulosInicial: GetArticulosCercania): Promise<AxiosResponse<GetArticlesResponse>> {
        try {

            const { ultimaDistance, latitudActual, longitudActual, ideUltimo, type_app, format, num_cajas, conBoton, tipo_android_app, country_code } = getArticulosInicial;

            const stringBusqueda = "?ultimaDistance=" + ultimaDistance + "&latitudActual=" + latitudActual
                + "&longitudActual=" + longitudActual + "&ideUltimo=" + ideUltimo
                + "&type_app=" + type_app
                + "&format=" + format + "&num_cajas=" + num_cajas + "&conBoton=" + conBoton
                + "&tipo_android_app=" + tipo_android_app
                + "&country_code=" + country_code;

            //alert('stringBusqueda:' + stringBusqueda)

            const url = `/php/formularios/envioCajasCercania.php${stringBusqueda}`;
            const response: AxiosResponse<GetArticlesResponse> = await this.axiosInstance.get(url);
            //alert(JSON.stringify(response.data.articles));
            response.data.articles.forEach((article: Article) => {
                article.masonryHeight = getRandomHeight(article.id_article);
            });
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async getArticulosBuscar(getArticulosInicial: GetArticulosPropiosBusqueda): Promise<AxiosResponse<GetArticlesResponse>> {
        try {

            const { texto_buscar, type_app, id_user, idUltimoCiencia, idUltimoComic, idUltimoEducacion, idUltimoHistoricos, idUltimoLiteratura, idUltimoInfantil, format, num_cajas, conBoton } = getArticulosInicial;

            const stringBusqueda = "?idUltimoCiencia=" + idUltimoCiencia + "&idUltimoComic=" + idUltimoComic +
                "&idUltimoEducacion=" + idUltimoEducacion + "&idUltimoHistoricos=" + idUltimoHistoricos +
                "&idUltimoLiteratura=" + idUltimoLiteratura + "&idUltimoInfantil=" + idUltimoInfantil +
                "&texto_buscar=" + texto_buscar +
                "&type_app=" + type_app + "&id_user=" + id_user +
                "&format=" + format + "&num_cajas=" + num_cajas + "&conBoton=" + conBoton;

            const url = `/php/formularios/envioCajasPropias_busqueda.php${stringBusqueda}`;
            const response: AxiosResponse<GetArticlesResponse> = await this.axiosInstance.get(url);
            //alert(JSON.stringify(response.data.articles));
            response.data.articles.forEach((article: Article) => {
                article.masonryHeight = getRandomHeight(article.id_article);
            });
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    //Obtener los términos de busqueda mas populares
    public async getTerminosBusquedaPopulares(): Promise<AxiosResponse<TextoBusqueda[]>> {
        try {
            const url = `/php/formularios/obtenerTextosBusqueda.php`;
            const response: AxiosResponse<TextoBusqueda[]> = await this.axiosInstance.get(url);
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async getLibrosPropios(getArticulosInicial: GetArticulosPropios): Promise<AxiosResponse<GetArticlesResponse>> {
        try {

            const { id_user, emailGoogle, idUltimoCiencia, idUltimoComic, idUltimoEducacion, idUltimoHistoricos, idUltimoLiteratura,
                idUltimoInfantil, favoritos, type_app, format, num_cajas, conBoton, tipo_android_app } = getArticulosInicial;

            const stringBusqueda = "?id_user=" + id_user + "&emailGoogle=" + emailGoogle
                + "&idUltimoCiencia=" + idUltimoCiencia + "&idUltimoComic=" + idUltimoComic
                + "&idUltimoEducacion=" + idUltimoEducacion + "&idUltimoHistoricos=" + idUltimoHistoricos
                + "&idUltimoLiteratura=" + idUltimoLiteratura + "&idUltimoInfantil=" + idUltimoInfantil
                + "&favoritos=" + favoritos + "&type_app=" + type_app
                + "&format=" + format + "&num_cajas=" + num_cajas +
                "&conBoton=" + conBoton + "&tipo_android_app=" + tipo_android_app;

            //alert('stringBusqueda:' + stringBusqueda)

            const url = `/php/formularios/envioCajasPropias.php${stringBusqueda}`;
            const response: AxiosResponse<GetArticlesResponse> = await this.axiosInstance.get(url);
            //alert(JSON.stringify(response.data.articles));
            response.data.articles.forEach((article: Article) => {
                article.masonryHeight = getRandomHeight(article.id_article);
            });
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async getArticulosBusqueda(getArticulosBusqueda: GetArticulosBusqueda): Promise<AxiosResponse<GetArticlesResponse>> {
        try {

            const { textoBuscar, filtro, idUltimoCiencia, idUltimoComic, idUltimoEducacion, idUltimoHistoricos, idUltimoLiteratura, idUltimoInfantil, categoriaFiltrada, nuevos, pocoUsados, usados, muyUsado, tapaDura, tapaBlanda, type_app, format, num_cajas, conBoton, tipo_android_app, country_code } = getArticulosBusqueda;

            const stringBusqueda = "?textoBusqueda=" + textoBuscar + "&filtro=" + filtro +
                "&idUltimoCiencia=" + idUltimoCiencia + "&idUltimoComic=" + idUltimoComic
                + "&idUltimoEducacion=" + idUltimoEducacion + "&idUltimoHistoricos=" + idUltimoHistoricos
                + "&idUltimoLiteratura=" + idUltimoLiteratura + "&idUltimoInfantil=" + idUltimoInfantil
                + "&categoriaFiltrada=" + categoriaFiltrada + "&nuevo=" + nuevos
                + "&poco_usado=" + pocoUsados + "&usado=" + usados + "&muy_usado=" + muyUsado + "&tapa_dura=" + tapaDura +
                "&tapa_blanda=" + tapaBlanda + "&type_app=" + type_app + "&format=" + format + "&num_cajas=" + num_cajas +
                "&conBoton=" + conBoton + "&tipo_android_app=" + tipo_android_app + "&country_code=" + country_code;

            //console.log('stringBusqueda:', stringBusqueda);

            const url = `/php/formularios/envioCajasBusqueda.php${stringBusqueda}`;
            const response: AxiosResponse<GetArticlesResponse> = await this.axiosInstance.get(url);
            //console.log('response:', response.data);
            response.data.articles.forEach((article: Article) => {
                article.masonryHeight = getRandomHeight(article.id_article);
            });
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async getArticuloCiudad(ciudad: string, ultimo_id: number, num_cajas: number): Promise<AxiosResponse<GetArticlesResponse>> {
        try {
            const stringBusqueda = "?ciudad=" + ciudad + "&pagina=" + ultimo_id + "&num_cajas=" + num_cajas;
            const url = `/php/formularios/obtenerArticulosPorCiudad.php${stringBusqueda}`;
            const response: AxiosResponse<GetArticlesResponse> = await this.axiosInstance.get(url);
            //console.log('response:', response.data);
            response.data.articles.forEach((article: Article) => {
                article.masonryHeight = getRandomHeight(article.id_article);
            });
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async getDestacadosPrincipal(): Promise<AxiosResponse<GetArticlesResponse>> {
        try {

            const getDestacados: GetArticlesDestacados = {
                num_cajas: 10,
                country_code: getCookiePais(null).pais
            };

            const url = `/php/destacados/obtenerDestacadosPrincipal.php`;
            const response: AxiosResponse<GetArticlesResponse> = await this.axiosInstance.post(url, getDestacados);
            //console.log('response:', response);
            /*response.data.articles.forEach((article: Article) => {
                article.masonryHeight = getRandomHeight(article.id_article);
            });*/
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async getArticulosPatrocinadores(): Promise<Article | null> {

        if (noMostrarPorPremium()) {
            return null;
        }

        try {
            const url = `/php/anuncios_patrocinadores/obtenerAnunciosPatrocinadores.php`;
            const response: AxiosResponse<GetArticlePatrocinador> = await this.axiosInstance.get(url);

            const articles: GetPatrocionador[] = response.data.anuncioPatrocinador;
            const articleReturn: Article = {
                id_article: articles[0].id * -1,
                image: articles[0].imagenAnuncio,
                linkExtra: articles[0].link,
                prize: articles[0].precio,
                title: articles[0].tituloAnuncio,
                moneda: "",
                isbn: "",
                articulo_categoria: CategoriasInternasAnuncios.AMAZON,
                post_type: "Amazon",
                sub_type: "Amazon",
                date: new Date().toISOString(),
                destacado: "0",
                verificado: 1,
                vendido: 0,
                mostrar: 1,
                name: "Amazon",
                email: "",
                esTienda: 1,
                avatar: "",
                distance: 0,
                id_user: 0,
                content: "",
                description: "",
                editorial: "",
                author: "",
                contact_email: "",
                contact_phone: "",
                city: "",
                town: "",
                pais: "",
                tases_shipping: 0,
                coordinates: "",
                estado: "",
                tapa: "",
                whatsapp: 0,
                emailgoogle: "",
                fotogoogle: "",
                namegoogle: "",
                lat: 0,
                lng: 0,
                email_comprador: "",
                id_transaccion: "",
                visto: 0,
                veces_telefono: 0,
                veces_email: 0,
                masonryHeight: getRandomHeight(articles[0].id),
            };

            return articleReturn;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async getLibro(categoria: string, id_articulo: number): Promise<AxiosResponse<Article>> {
        try {

            const stringBusqueda = "?cat=" + categoria + "&id=" + id_articulo;
            //console.log('stringBusqueda:', stringBusqueda);
            const url = `/php/formularios/cardGrande.php${stringBusqueda}`;
            const response: AxiosResponse<Article> = await this.axiosInstance.get(url);
            //console.log('response CARDGRANDE:', response);
            /*response.data.articles.forEach((article: Article) => {
                article.masonryHeight = getRandomHeight(article.id_article);
            });*/
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async getLibroVendido(categoria: string, id_articulo: number): Promise<AxiosResponse<Article>> {
        try {

            const stringBusqueda = "?cat=" + categoria + "&id=" + id_articulo;
            //console.log('stringBusqueda:', stringBusqueda);
            const url = `/php/formularios/articuloVendido.php${stringBusqueda}`;
            const response: AxiosResponse<Article> = await this.axiosInstance.get(url);
            //console.log('response CARDGRANDE:', response);
            /*response.data.articles.forEach((article: Article) => {
                article.masonryHeight = getRandomHeight(article.id_article);
            });*/
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async enviarAvisoLibroIncorrecto(avisoLibro: AvisoLibroIncorrectoObj): Promise<AxiosResponse<string>> {
        try {
            const url = `/php/actions/avisar_libro_incorrecto.php`;
            const response: AxiosResponse<string> = await this.axiosInstanceToken.post(url, avisoLibro);
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async sumarVisitaLibro(categoriaArticulo: string, id_articulo: number): Promise<AxiosResponse<string>> {
        try {
            const stringBusqueda = "?categoria_articulo=" + categoriaArticulo + "&id_articulo=" + id_articulo;
            const url = `/php/actions/verArticulo.php${stringBusqueda}`;
            const response: AxiosResponse<string> = await this.axiosInstance.get(url);
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async obtenerNumeroTelefonoArticulo(categoriaArticulo: string, id_articulo: number): Promise<AxiosResponse<string>> {
        try {
            const stringBusqueda = "?cate=" + categoriaArticulo + "&id_articulo=" + id_articulo;
            const url = `/php/actions/obtener_telefono.php${stringBusqueda}`;
            //JSON.stringify(phoneNumber).replace(/ /g, "");
            const response: AxiosResponse<string> = await this.axiosInstance.get(url);
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async sumarNumeroTelefonoVeces(categoriaArticulo: string, id_articulo: number): Promise<AxiosResponse<string>> {
        try {
            const stringBusqueda = "?categoria_articulo=" + categoriaArticulo + "&id_articulo=" + id_articulo;
            const url = `/php/actions/sumarTelefono.php${stringBusqueda}`;
            //JSON.stringify(phoneNumber).replace(/ /g, "");
            const response: AxiosResponse<string> = await this.axiosInstance.get(url);
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async obtenerEmail(categoriaArticulo: string, id_articulo: number): Promise<AxiosResponse<string>> {
        try {
            const stringBusqueda = "?cate=" + categoriaArticulo + "&id_articulo=" + id_articulo;
            const url = `/php/actions/obtener_email.php${stringBusqueda}`;
            //JSON.stringify(phoneNumber).replace(/ /g, "");
            const response: AxiosResponse<string> = await this.axiosInstance.get(url);
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async sumarEmailVeces(categoriaArticulo: string, id_articulo: number): Promise<AxiosResponse<string>> {
        try {
            const stringBusqueda = "?categoria_articulo=" + categoriaArticulo + "&id_articulo=" + id_articulo;
            const url = `/php/actions/sumarEmail.php${stringBusqueda}`;
            //JSON.stringify(phoneNumber).replace(/ /g, "");
            const response: AxiosResponse<string> = await this.axiosInstance.get(url);
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }
    
    public async obtenerMediaLibro(isbn: string, moneda: string, idUser: number): Promise<AxiosResponse<MediaLibroObj>> {

        try {
            const stringBusqueda = "?isbn=" + isbn + "&moneda=" + moneda + "&idUser=" + idUser;
            const url = `/php/actions/obtenerMedia_v2.php?isbn=${stringBusqueda}`;
            //JSON.stringify(phoneNumber).replace(/ /g, "");
            const response: AxiosResponse<MediaLibroObj> = await this.axiosInstance.get(url);
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async obtenerDatosLibroByISBN(isbn: string): Promise<AxiosResponse<GetDatosLibroISBN>> {

        try {
            const stringBusqueda = "?isbn=" + isbn + "&tipo_android_app=1";
            const url = `/php/formularios/get_isbn.php${stringBusqueda}`;
            //JSON.stringify(phoneNumber).replace(/ /g, "");
            const response: AxiosResponse<GetDatosLibroISBN> = await this.axiosInstance.get(url);
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async obtenerDatosLibrosBarcodeFinderOpenLibrary(isbn: string): Promise<AxiosResponse<DatosLibroISBNFinder>> {

        try {
            const stringBusqueda = "?isbn=" + isbn;
            const url = `/python/barcode_finder_open_library.php${stringBusqueda}`;
            //JSON.stringify(phoneNumber).replace(/ /g, "");
            const response: AxiosResponse<DatosLibroISBNFinder> = await this.axiosInstance.get(url);
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async obtenerDatosLibroBarcodeFinderGoogle(isbn: string): Promise<AxiosResponse<GetDatosLibroISBNFinderGoogle>> {

        try {
            const stringBusqueda = "?isbn=" + isbn;
            const url = `/python/barcodefinder_lanzador_google.php${stringBusqueda}`;
            //JSON.stringify(phoneNumber).replace(/ /g, "");
            const response: AxiosResponse<GetDatosLibroISBNFinderGoogle> = await this.axiosInstance.get(url);
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async guardarImagenArticulo(objImagenLibro: PostImagenArticuloObj): Promise<AxiosResponse<string>> {
        try {
            const url = `/php/formularios/guardar_imagen_articulo.php`;
            const response: AxiosResponse<string> = await this.axiosInstanceToken.post(url, objImagenLibro);
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async enviarImagenArticulo(objImagenLibro: PostsImagenRemotoObj): Promise<AxiosResponse<string>> {
        try {
            //Esta se envia a otro servidor distinto
            const config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    "X-AUTH-TOKEN": cargarToken()
                }
            }
            const url = `/subir_imagenes.php`;
            const response: AxiosResponse<string> = await axiosImagesURL().post(url, JSON.stringify(objImagenLibro), config);
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async modificarImagen(postModificarImagen: PostsImagenRemotoObj): Promise<AxiosResponse<ResponseDestacarLibro>> {

        try {
            const url = `/php/formularios/modificar_imagen_v3.php`;
            //JSON.stringify(phoneNumber).replace(/ /g, "");
            const response: AxiosResponse<ResponseDestacarLibro> = await this.axiosInstanceToken.post(url, postModificarImagen);
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async borrarImagen(postModificarImagen: PostsImagenRemotoObj): Promise<AxiosResponse<ResponseDestacarLibro>> {

        try {
            const url = `/php/formularios/borrar_imagen.php`;
            //JSON.stringify(phoneNumber).replace(/ /g, "");
            const response: AxiosResponse<ResponseDestacarLibro> = await this.axiosInstanceToken.post(url, postModificarImagen);
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async setISBNRemote(objPostISBN: PostISBNArticuloRemoto): Promise<AxiosResponse<string>> {
        try {

            const config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }

            var urlencoded = new URLSearchParams();

            urlencoded.append("isbn", objPostISBN.isbn);
            urlencoded.append("nombre", objPostISBN.titulo);
            urlencoded.append("editorial", objPostISBN.editorial);
            urlencoded.append("autor", objPostISBN.autor);
            urlencoded.append("imagenLibro", ""); //Imagen libro vacia
            urlencoded.append("categoria", objPostISBN.categoria == null ? "" : objPostISBN.categoria);
            urlencoded.append("subcategoria", objPostISBN.subcategoria == null ? "" : objPostISBN.subcategoria);
            urlencoded.append("idUser", String(objPostISBN.idUser));

            const url = `/php/formularios/set_isbn_token.php`;
            const response: AxiosResponse<string> = await this.axiosInstanceToken.post(url, urlencoded, config);
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async addLibro(postArticuloObj: PostArticuloObj): Promise<AxiosResponse<any>> {
        // alert(JSON.stringify(postArticuloObj));
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }

            const urlencoded = new URLSearchParams();
            urlencoded.append("email_contacto", postArticuloObj.email);
            urlencoded.append("nombrecategoria", postArticuloObj.categoriaPrincipal);
            urlencoded.append("latForm", String(postArticuloObj.latitude));
            urlencoded.append("longsForm", String(postArticuloObj.longitude));
            urlencoded.append("nombrearticulo", postArticuloObj.titulo); //Imagen libro vacia
            urlencoded.append("nombreisbn", postArticuloObj.isbn);
            urlencoded.append("editorial", postArticuloObj.editorial);
            urlencoded.append("autor", postArticuloObj.autor);
            urlencoded.append("descripcion", postArticuloObj.descripcion);
            urlencoded.append("estado", postArticuloObj.estado);
            urlencoded.append("precio", String(postArticuloObj.precio));
            urlencoded.append("gastosenvio", "");
            urlencoded.append("telefono_contacto", "");
            urlencoded.append("whatsapp", "0");
            urlencoded.append("provinciaarticulo", postArticuloObj.provincia);
            urlencoded.append("localidadarticulo", postArticuloObj.localidad);
            urlencoded.append("provinciaPredefinida", postArticuloObj.provincia);
            urlencoded.append("localidadPredefinida", postArticuloObj.localidad);
            urlencoded.append("tipo_education", postArticuloObj.categoriaSecundaria); //Tipo para libros de educacion
            urlencoded.append("tipo_education2", postArticuloObj.categoriaSub);
            urlencoded.append("tipo", postArticuloObj.categoriaSecundaria); //Tipo para libros de literatura
            urlencoded.append("id_user", String(postArticuloObj.idUsuario));
            urlencoded.append("type_app", "1");
            urlencoded.append("verificado", "0");
            urlencoded.append("tapa", postArticuloObj.formato);
            urlencoded.append("pais", postArticuloObj.countryCode);
            urlencoded.append("moneda", postArticuloObj.moneyCode);

            // alert(JSON.stringify(urlencoded));
            const url = `/php/formularios/abasededatos_app_android_token_new.php`;
            const response: AxiosResponse<string> = await this.axiosInstanceToken.post(url, urlencoded.toString(), config);
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }


    public async destacarTodosLosLibros(idUsuario: string): Promise<AxiosResponse<any>> {

        try {
            const stringBusqueda = "?id_user=" + idUsuario;
            const url = `/php/destacados/destacar_todos.php${stringBusqueda}`;
            //JSON.stringify(phoneNumber).replace(/ /g, "");
            const response: AxiosResponse<string> = await this.axiosInstanceToken.get(url);
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async borrarLibro(categoria: string, id_articulo: number, id_usuario: number): Promise<AxiosResponse<string>> {

        try {
            const stringBusqueda = "?valorcategoria=" + categoria + "&id=" + id_articulo + "&idUser=" + id_usuario;
            const url = `/php/formularios/borrarArticulo_v3.php${stringBusqueda}`;
            //JSON.stringify(phoneNumber).replace(/ /g, "");
            const response: AxiosResponse<string> = await this.axiosInstanceToken.get(url);
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async marcarLibroComoVendido(objMarcarVendido: ObjMarcarVendido): Promise<AxiosResponse<any>> {

        try {
            const url = `/php/actions/marcar_vendido.php`;
            //JSON.stringify(phoneNumber).replace(/ /g, "");
            const response: AxiosResponse<string> = await this.axiosInstanceToken.post(url, objMarcarVendido);
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    //Favoritos:

    public async getLibrosFavoritos(id_usuario: number): Promise<AxiosResponse<GetArticlesResponse>> {
        try {
            const stringBusqueda = "?id_user=" + id_usuario + "&tipo=1";

            const url = `/php/favoritos/envioCajasFavoritos.php${stringBusqueda}`;
            const response: AxiosResponse<GetArticlesResponse> = await this.axiosInstanceToken.get(url);
            //alert(JSON.stringify(response.data.articles));
            response.data.articles.forEach((article: Article) => {
                article.masonryHeight = getRandomHeight(article.id_article);
            });
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async comprobarFavorito(id_usuario: number, id_article: number, categoria: string): Promise<AxiosResponse<number>> {
        try {
            const stringBusqueda = "?id_user=" + id_usuario + "&id_articulo=" + id_article + "&categoria=" + categoria;

            const url = `/php/favoritos/esFavorito.php${stringBusqueda}`;
            const response: AxiosResponse<number> = await this.axiosInstanceToken.get(url);
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async addFavoritos(id_usuario: number, id_article: number, categoria: string): Promise<AxiosResponse<number>> {
        try {
            const stringBusqueda = "?id_user=" + id_usuario + "&id_articulo=" + id_article + "&categoria=" + categoria;

            const url = `/php/favoritos/addFavorito.php${stringBusqueda}`;
            const response: AxiosResponse<number> = await this.axiosInstanceToken.get(url);
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async destacarLibro(modo: ModoDestacarLibro, categoria_articulo: string, id_articulo: number, id_usuario: number): Promise<AxiosResponse<ResponseDestacarLibro>> {

        try {
            const stringBusqueda = "?id_user=" + id_usuario + "&id_articulo=" + id_articulo +
                "&categoria_articulo=" + categoria_articulo +
                "&e=" + modo;
            const url = `/php/destacados/destacar.php${stringBusqueda}`;
            //JSON.stringify(phoneNumber).replace(/ /g, "");
            const response: AxiosResponse<ResponseDestacarLibro> = await this.axiosInstanceToken.get(url);
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async obtenerLinkBlogArticulo(id_article: number, categoria: string): Promise<AxiosResponse<string>> {

        try {
            const url = `/php/formularios/get_book_link_blog.php?id_article=${id_article}&categoria=${categoria}`;
            const response: AxiosResponse<string> = await this.axiosInstance.get(url);
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }


    ///php/actions/editArticleToken_v4

    public async editarArticulo(editarArticuloObj: EditarArticuloObj): Promise<AxiosResponse<string>> {

        try {
            const url = `/php/actions/editArticleToken_v4.php`;
            //JSON.stringify(phoneNumber).replace(/ /g, "");
            const response: AxiosResponse<string> = await this.axiosInstanceToken.post(url, editarArticuloObj);
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async puntuarLibro(puntuarObj: PuntuarLibroObj): Promise<AxiosResponse<string>> {

        try {
            const url = `/php/comentarios_libros/enviar_comentario_libro_json.php`;
            //JSON.stringify(phoneNumber).replace(/ /g, "");
            const response: AxiosResponse<string> = await this.axiosInstanceToken.post(url, puntuarObj);
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async obtenerPuntuacionLibro(isbn: string, id_usuario: number): Promise<AxiosResponse<GetPuntuacionLibro>> {

        try {
            const url = `/php/comentarios_libros/obtener_puntuacion_libro.php?isbn=${isbn}&id_usuario=${id_usuario}`;
            //JSON.stringify(phoneNumber).replace(/ /g, "");
            const response: AxiosResponse<GetPuntuacionLibro> = await this.axiosInstanceToken.get(url);
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async obtenerComentariosLibroByISBN(isbn: string): Promise<AxiosResponse<GetPuntuacionLibro>> {

        try {
            const url = `/php/comentarios_libros/obtener_comentarios_libro_by_isbn.php?isbn=${isbn}`;
            //JSON.stringify(phoneNumber).replace(/ /g, "");
            const response: AxiosResponse<GetPuntuacionLibro> = await this.axiosInstance.get(url);
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async obtenerTodosLosComentariosByIdUser(id_usuario: number): Promise<AxiosResponse<Comentario[]>> {

        try {
            const url = `/php/comentarios_libros/obtener_todos_los_comentarios.php?id_usuario=${id_usuario}`;
            //JSON.stringify(phoneNumber).replace(/ /g, "");
            const response: AxiosResponse<Comentario[]> = await this.axiosInstanceToken.get(url);
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async borrarComentarioLibro(id_comentario: number, id_usuario: number): Promise<AxiosResponse<String>> {

        try {
            const url = `/php/comentarios_libros/borrar_comentario.php?id=${id_comentario}&id_usuario=${id_usuario}`;
            //JSON.stringify(phoneNumber).replace(/ /g, "");
            const response: AxiosResponse<String> = await this.axiosInstanceToken.get(url);
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async obtenerListaCiudadesLibros(): Promise<AxiosResponse<string[]>> {

        try {
            const url = `/php/formularios/obtenerCiudadesArticulos.php`;
            //JSON.stringify(phoneNumber).replace(/ /g, "");
            const response: AxiosResponse<string[]> = await this.axiosInstance.get(url);
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    //Puntuaciones de usuarios
    public async obtenerArticulosSinPuntuar(id_usuario: number): Promise<AxiosResponse<GetPuntuarUsuario>> {
        try {
            const url = `/php/puntuaciones/preguntar_puntuar.php?id_user=${id_usuario}`;
            //JSON.stringify(phoneNumber).replace(/ /g, "");
            const response: AxiosResponse<GetPuntuarUsuario> = await this.axiosInstance.get(url);
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    public async puntuarUsuario(comentarioUsuarioObj: ComentarUsuarioObj): Promise<AxiosResponse<string>> {

        const { id_user_puntuado, puntuacion, comentario, id_articulo, categoria_articulo, titulo_articulo, id_user_puntua } = comentarioUsuarioObj;

        const query = "?id_user_puntua=" + id_user_puntua +
            "&id_user_puntuado=" + id_user_puntuado +
            "&puntuacion=" + puntuacion +
            "&comentario=" + comentario +
            "&id_articulo=" + id_articulo +
            "&categoria_articulo=" + categoria_articulo +
            "&titulo_articulo=" + titulo_articulo;
        try {
            const url = `/php/puntuaciones/puntuar.php${query}`;
            //JSON.stringify(phoneNumber).replace(/ /g, "");
            const response: AxiosResponse<string> = await this.axiosInstance.get(url);
            return response;
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

}
