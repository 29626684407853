import { GetLogroDesbloqueable, GetLogrosObj, IndiceLogro } from '@/objects/Gamificacion';
import { AxiosInstance, AxiosResponse } from 'axios';

//Buscar pacientes
export default class GamificacionQueries {

    //Este se utiliza para las llamadas que requieren token
    private axiosInstanceToken: AxiosInstance
    // private userData: LoginDataResponse

    constructor(axiosInstanceToken: AxiosInstance) {
        this.axiosInstanceToken = axiosInstanceToken
        //   this.userData = userData
    }

    public async obtenerLogrosPorIdUser(id_user: number): Promise<AxiosResponse<GetLogrosObj>> {
        try {
            const url = `/php/gamificacion/obtener_logros.php?id_user=${id_user}`;
            const response: AxiosResponse<GetLogrosObj> = await this.axiosInstanceToken.get(url);
            return response;
        } catch (error: any) {
            console.log('Error en obtener datos usuario', error);
            return error;
            //throw new Error(error.message);
        }
    }

    public async cargarLogroPorId(id_user: number, id_logro: IndiceLogro): Promise<AxiosResponse<GetLogroDesbloqueable>> {
        try {
            const url = `/php/gamificacion/comprobar_logro.php?id_user=${id_user}&tipo_logro=${id_logro}`;
            const response: AxiosResponse<GetLogroDesbloqueable> = await this.axiosInstanceToken.get(url);
            return response;
        } catch (error: any) {
            console.log('Error en obtener datos usuario', error);
            return error;
            //throw new Error(error.message);
        }
    }


}
