//Define los tamaños
export const tituloH1 = '22px';
export const subtitulo = '18px';
export const textos = '15px';
export const textoBotonMovilHeader = '12px';
export const textosPeque = '13px';
export const textosMini = '11px';
export const textoGrande = '45px';
export const textoMediano = '30px';
export const textoGigante = '80px';
export const textoBotones = '15px';
export const precioLibro = '35px';

export const mostrando = "30px";
export const mostrandoPeque = "18px";


