import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { marronTransparente, primary, red, white } from '@/constants/Colors';
import LanguageSwitcher from '../internacionalizacion/LanguageSwitcher';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SearchIcon from '@mui/icons-material/Search';
import Boton from '../elements/Boton';

import { LoginDatos } from '@/objects/User';
import { cargarDatosUsuario } from '@/util/LocalStorageLoaded';
import ImageRound from '../elements/ImageRound';
import { comprobarAvatar } from '@/util/Articulos';
import ImageCustom, { TypeObjectFit } from '../elements/ImageCustom';
import SettingsIcon from '@mui/icons-material/Settings';
import BotonBarraMovil from '../elements/BotonBarraMovil';
import MenuDropDownMovil from '../elements/MenuDropDownMovil';
import LinkCustom from '../elements/LinkCustom';

function HeaderGeneral() {

  const { t } = useTranslation();

  const [datosUsuario, setDatosUsuario] = React.useState<LoginDatos | null>(null);

  React.useEffect(() => {
    setDatosUsuario(cargarDatosUsuario()!);
  }, []);


  return (
    <AppBar id="header" position="fixed"
      sx={{
        backgroundColor: marronTransparente, zIndex: 999, height: "64px", backdropFilter: 'blur(3px)',
        WebkitBackdropFilter: 'blur(3px)'
      }} >
      <Container maxWidth="xl">
        <Toolbar disableGutters>

          <div id="barra_web" style={{ width: '100%', alignItems: 'center' }}>
            <Link href="/" title={t('header:title_home')}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ImageCustom src="/images/icons/logo_barra.png" alt={t('header:alt_logo_quierolibros')} width={55} height={55} objectFit={TypeObjectFit.CONTAIN} style={{ marginRight: '5px', marginBottom: "-3px" }} />
                <Box sx={{ display: { xs: 'flex', md: 'flex' }, marginBottom: "-7px" }}>
                  <ImageCustom src="/images/titulo_peq.png" alt={t('header:alt_titulo_quierolibros')} width={190} height={35} objectFit={TypeObjectFit.CONTAIN} style={{ marginBottom: "5px" }} />
                </Box>
              </div>
            </Link>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
              <div style={{ display: 'flex', alignItems: 'center', margin: "10px" }}>
                <LanguageSwitcher />
              </div>
              <Boton startIcon={<SearchIcon />} texto={t("header:buscar_libros")} title={t("header:title_buscar_libros")} link="/buscar-libros-de-segunda-mano" color={white} colorTexto={primary} height='35px' />
              <Boton startIcon={<AddCircleIcon />} texto={t("header:add_libro")} title={t("header:title_add_libro")} link="/subir-libro" color={white} colorTexto={primary} height='35px' />
            </Box>

            {datosUsuario !== null ?
              <LinkCustom href="/dashboard/perfil" title={t("header:title_perfil")} color={primary}>
                <IconButton
                  size="large"
                  edge="end"
                  color="inherit"
                  aria-label="menu"
                  style={{ marginRight: "-5px" }}>
                  <div id="notificaciones_header_web"></div>
                  <ImageRound width={45} height={45} src={comprobarAvatar(datosUsuario?.image!)} title={t("header:title_logo_user")} alt={"header:alt_logo_user"} />
                </IconButton>
              </LinkCustom>
              :
              <Boton texto={t("header:entrar")} link='/login' title={t("header:title_entrar")} startIcon={<AccountCircleIcon />} color={white} colorTexto={primary} height='35px' />
            }
          </div>

          <Box
            id="barra_movil"
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "space-between", // Para que los elementos se distribuyan uniformemente
              alignItems: "center", // Para centrar verticalmente los elementos
              flexWrap: "wrap", // Para que los elementos se envuelvan cuando no haya suficiente espacio
              padding: "0 10px", // Ajusta el espaciado interior de la barra
            }}
          >
            <Box id="logo_web_movil" sx={{ display: { xs: 'flex', md: 'flex' }, marginBottom: "-7px" }}>
              <ImageCustom src="/images/titulo_peq_2.png" alt={t('header:alt_titulo_quierolibros')} width={125} height={30} objectFit={TypeObjectFit.CONTAIN} style={{ marginBottom: "5px" }} />
            </Box>
            <BotonBarraMovil title={t("header:title_home")} href="/" icon={<ImageCustom src="/images/icons/logo_barra.png" alt={t('header:alt_logo_quierolibros')} width={20} height={20} objectFit={TypeObjectFit.CONTAIN} style={{ marginRight: '5px', marginBottom: "-3px" }} />} >
              {t("header:home")}
            </BotonBarraMovil>
            <BotonBarraMovil title={t("header:title_buscar_libros")} href="/buscar-libros-de-segunda-mano" icon={<SearchIcon style={{ width: "20px", height: "20px" }} />} >
              {t("header:buscar_libros_movil")}
            </BotonBarraMovil>
            <BotonBarraMovil title={t("header:title_add_libro")} href="/subir-libro" icon={<AddCircleIcon style={{ width: "20px", height: "20px" }} />} >
              <div style={{ marginTop: "2px" }}>
                {t("header:add_libro_movil")}
              </div>
          </BotonBarraMovil>
          <MenuDropDownMovil
            icon={<SettingsIcon style={{ width: "20px", height: "20px" }} />}
            textoMenu={t("header:idioma")}
            listaElementosMenu={[<LanguageSwitcher key={1} />]} />

          <BotonBarraMovil title={datosUsuario === null ? t("header:title_entrar") : t("header:title_perfil")} href={datosUsuario === null ? "/login" : "/dashboard/perfil"} color={primary} icon={datosUsuario !== null ? <div>
            <IconButton
              size="medium"
              edge="end"
              color="inherit"
              aria-label="menu"
              style={{ marginRight: "0px", marginTop: "-15px", width: "35px", height: "35px" }}
            >
              <div id="notificaciones_header_movil" style={{}}></div>
              <ImageRound style={{ marginBottom: "-12px" }} width={20} height={20} src={comprobarAvatar(datosUsuario?.image!)}
                title={t("header:title_logo_user")}
                alt={"header:alt_logo_user"} />
            </IconButton>
          </div> : <AccountCircleIcon style={{ width: "20px", height: "20px" }} />
          } >

            {datosUsuario !== null ? t("header:perfil") : t("header:entrar")}
          </BotonBarraMovil>
        </Box>

      </Toolbar>
    </Container>
    </AppBar >
  );
}

export default HeaderGeneral;

