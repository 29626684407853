// theme.ts
import { createTheme, Theme } from '@mui/material/styles';
import { primary, white, black, whiteAll, blackAll } from '../../constants/Colors';
import '@fontsource/roboto';
import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';

const lightTheme: Theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: primary,
    },
    background: {
      default: whiteAll,
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          backdropFilter: 'blur(3px)',
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
        },
      },
    },
  },
});

const darkTheme: Theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: primary,
    },
    background: {
      default: blackAll,
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          backdropFilter: 'blur(3px)',
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
        },
      },
    },
  },
});

export { lightTheme, darkTheme };
