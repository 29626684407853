import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { white } from '../../constants/Colors';
import { useTranslation } from 'react-i18next';

export interface OptionSelect {
  text: string;
  value: string | number;
}

interface Props {
  options: OptionSelect[];
  placeholder?: string | number;
  label?: string;
  showPlaceholder?: boolean;
  showError?: boolean;
  fullWidth?: boolean;
  style_basico?: boolean;
  disabled?: boolean;
  reset?: boolean;
  onSelectOption: (option: string) => void;
  value: string;
}

const SelectBasico: React.FC<Props> = ({
  options,
  placeholder,
  showPlaceholder = false,
  onSelectOption,
  style_basico = true,
  showError = false,
  disabled = false,
  reset = null,
  fullWidth = false,
  label = '',
  value,
}) => {
  const { t } = useTranslation();

  const handleChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value as string;
    onSelectOption(selectedValue);
  };

  React.useEffect(() => {
    if (reset !== null) {
      onSelectOption('');
    }
  }, [reset, onSelectOption]);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 224,
        width: 250,
      },
    },
  };

  return (
    <FormControl style={{ width: '100%', alignItems: "center", zIndex:999 }} error={showError}>
      <Select
        label={label}
        disabled={disabled}
        style={fullWidth == true ? { height: 55, width: '100%' } : { height: 45, width: 250 }}
        value={value}
        onChange={handleChange}
        displayEmpty
        input={<OutlinedInput sx={{ padding: '0' }} />}
        MenuProps={MenuProps}
        sx={{
          backgroundColor: white,
        }}
      >
        {showPlaceholder && (
          <MenuItem disabled value="">
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem
            
            key={option.value.toString()}
            value={option.value}
          >
            {t(option.text)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectBasico;
