import { Snowfall } from '@/components/widgets/efectos/SnowFall';
import React, { createContext, useContext, useEffect, useState } from 'react';

export const EventsContext = createContext<{

} | undefined>(undefined);


export const EventsContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {

    const [isSnowing, setIsSnowing] = useState(false);

    useEffect(() => {
        const currentMonth = new Date().getMonth();
        setIsSnowing(currentMonth === 11); // Activa en diciembre (mes 11)
    }, []);

    return (
        <EventsContext.Provider value={{}}>
            {children}
            {isSnowing && <Snowfall />}
        </EventsContext.Provider>
    );
};

export const useEventContext = () => useContext(EventsContext);
