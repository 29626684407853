import { primary } from '../../constants/Colors';
import { Stack } from '@mui/material';
import ImageCustom from './ImageCustom';
import Texto from './Texto';
import { useTranslation } from 'next-i18next';

function Loader() {
    const { t } = useTranslation(['common']);
    const loaderStyle: React.CSSProperties = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backdropFilter: 'blur(2px)',
        zIndex: 9999,
        //backgroundColor: 'rgba(0, 0, 0, 0.6)'
    };

    return (
        <div style={loaderStyle}>
            <Stack sx={{ color: 'grey.500' }} spacing={2} direction="column">
                <ImageCustom src="/images/icons/loader.gif" alt={t("common:opciones.cargando_puntos")} width={100} height={100} />
            </Stack>
        </div>
    );
}
/*<Texto> {t("common:opciones.cargando_puntos")} </Texto>*/

export default Loader;
