import { GetPaisMonedaResponse } from '@/objects/User';
import nookies from 'nookies'

enum CookieKeys {
    PAIS = 'pais',
    NEXT_LOCALE = 'NEXT_LOCALE',
    VECES_COOKIE_PAIS = 'VECES_COOKIE_PAIS',
}

//Configuración
export function getMaxAgeCookie() {
    return Number.MAX_SAFE_INTEGER;
}

//Para pais y moneda
export function getMaxVecesCookiePais() {
    return 20;
}

export function setCookiePais(context: any, valor: GetPaisMonedaResponse) {
    if (valor !== undefined || valor !== null)
        setCookies(context, CookieKeys.PAIS, JSON.stringify(valor));
}

export function getCookiePais(context: any): GetPaisMonedaResponse {
    const cookieValue = getCookies(context, CookieKeys.PAIS);
    if (cookieValue === undefined || cookieValue === "{}") {
        return {
            pais: "",
            moneda: "",
        }
    }
    return JSON.parse(cookieValue);
}

export function setCookieVecesCookiePais(context: any, valor: string) {
    setCookies(context, CookieKeys.VECES_COOKIE_PAIS, valor);
}

export function getCookieVecesCookiePais(context: any): string {
    return getCookies(context, CookieKeys.VECES_COOKIE_PAIS);
}

//Si supera las veces de cookies permitidas se devuelve true y ademas se pone en 0
export function incrementarVecesCookiePais(context: any) {
    let veces = getCookieVecesCookiePais(context);
    let vecesNumber = Number(veces);
    if (veces === undefined) {
        vecesNumber = 0;
    } else {
        vecesNumber = Number(veces) + 1;
    }
    setCookieVecesCookiePais(context, vecesNumber.toString());
    if (vecesNumber > getMaxVecesCookiePais()) {
        setCookieVecesCookiePais(context, "0");
        return true;
    }
    return false;
}

//Para idioma i18n
export function setCookieNextLocale(context: any, valor: string) {
    setCookies(context, CookieKeys.NEXT_LOCALE, valor);
}

export function getCookieNextLocale(context: any): string {
    return getCookies(context, CookieKeys.NEXT_LOCALE);
}

//Borrar cookies
export function removeCookiePais(context: any) {
    nookies.destroy(context, CookieKeys.PAIS);
}

export function removeCookieNextLocale(context: any) {
    nookies.destroy(context, CookieKeys.NEXT_LOCALE);
}

export function removeCookieVecesCookiePais(context: any) {
    nookies.destroy(context, CookieKeys.VECES_COOKIE_PAIS);
}

export function removeAllCookies(context: any) {
    nookies.destroy(context, CookieKeys.PAIS);
    nookies.destroy(context, CookieKeys.NEXT_LOCALE);
    nookies.destroy(context, CookieKeys.VECES_COOKIE_PAIS);
}

//General
export function getCookies(context: any, clave: CookieKeys): string {
    const cookies = nookies.get(context);
    return cookies[clave];
}

export function setCookies(context: any, clave: CookieKeys, valor: string) {
    nookies.set(context, clave, valor, {
        maxAge: getMaxAgeCookie(), // Tiempo de vida de la cookie al máximo
        path: '/', // La ruta en la que la cookie es válida (en este caso, la raíz del dominio)
        sameSite: 'strict', // La cookie solo se enviará en solicitudes de origen cruzado
        secure: true, // Asegura que la cookie solo se envíe a través de conexiones HTTPS
    });
}
