import { CategoriasString, CategoriasStringServer, SubCategoriasString } from "./Articles";

export interface CategoriaObj {
    titulo: string;
    icono: string;
    alt: string;
    title: string;
    link: string;
    categoriaString: CategoriasString
    categoriaStringServer: CategoriasStringServer | SubCategoriasString
}

export const categoria_todas: CategoriaObj = {
    titulo: "common:categorias.todas.titulo",
    alt: "common:categorias.todas.alt",
    title: "common:categorias.todas.title",
    icono: "/images/icons/ic_otros.png",
    link: "/categoria/todas",
    categoriaString: CategoriasString.TODAS,
    categoriaStringServer: CategoriasStringServer.TODAS
}

export const selecciona_categoria: CategoriaObj = {
    titulo: "common:categorias.selecciona_categoria",
    alt: "common:categorias.todas.alt",
    title: "common:categorias.todas.title",
    icono: "/images/icons/ic_otros.png",
    link: "/categoria/todas",
    categoriaString: CategoriasString.SELECCIONA_CATEGORIA,
    categoriaStringServer: CategoriasStringServer.SELECCIONA_CATEGORIA
}

export const selecciona_nivel: CategoriaObj = {
    titulo: "common:categorias.selecciona_nivel",
    alt: "common:categorias.todas.alt",
    title: "common:categorias.todas.title",
    icono: "/images/icons/ic_otros.png",
    link: "/categoria/todas",
    categoriaString: CategoriasString.SELECCIONA_CATEGORIA,
    categoriaStringServer: CategoriasStringServer.SELECCIONA_CATEGORIA
}

export const selecciona_sub_categoria: CategoriaObj = {
    titulo: "common:categorias.selecciona_subcategoria",
    alt: "common:categorias.todas.alt",
    title: "common:categorias.todas.title",
    icono: "/images/icons/ic_otros.png",
    link: "/categoria/todas",
    categoriaString: CategoriasString.SELECCIONA_CATEGORIA,
    categoriaStringServer: CategoriasStringServer.SELECCIONA_CATEGORIA
}


export const categorias_defecto: CategoriaObj[] = [
    {
        titulo: "common:categorias.literatura.titulo",
        alt: "common:categorias.literatura.alt",
        title: "common:categorias.literatura.title",
        icono: "/images/icons/ic_literatura_n.png",
        link: "/categoria/literatura",
        categoriaString: CategoriasString.LITERATURA,
        categoriaStringServer: CategoriasStringServer.LITERATURA
    },
    {
        titulo: "common:categorias.educacion.titulo",
        alt: "common:categorias.educacion.alt",
        title: "common:categorias.educacion.title",
        icono: "/images/icons/ic_school_n.png",
        link: "/categoria/educacion",
        categoriaString: CategoriasString.EDUCACION,
        categoriaStringServer: CategoriasStringServer.EDUCACION
    },

    {
        titulo: "common:categorias.comic.titulo",
        alt: "common:categorias.comic.alt",
        title: "common:categorias.comic.title",
        icono: "/images/icons/ic_comic_n.png",
        link: "/categoria/comics",
        categoriaString: CategoriasString.COMIC,
        categoriaStringServer: CategoriasStringServer.COMIC
    },
    {
        titulo: "common:categorias.infantiles.titulo",
        alt: "common:categorias.infantiles.alt",
        title: "common:categorias.infantiles.title",
        icono: "/images/icons/ic_infantil_n.png",
        link: "/categoria/infantiles",
        categoriaString: CategoriasString.INFANTIL,
        categoriaStringServer: CategoriasStringServer.INFANTIL
    },
    {
        titulo: "common:categorias.ciencia.titulo",
        alt: "common:categorias.ciencia.alt",
        title: "common:categorias.ciencia.title",
        icono: "/images/icons/ic_ciencia_n.png",
        link: "/categoria/ciencia",
        categoriaString: CategoriasString.CIENCIA,
        categoriaStringServer: CategoriasStringServer.CIENCIA
    },
    {
        titulo: "common:categorias.historia.titulo",
        alt: "common:categorias.historia.alt",
        title: "common:categorias.historia.title",
        icono: "/images/icons/ic_historicos_n.png",
        link: "/categoria/historia",
        categoriaString: CategoriasString.HISTORIA,
        categoriaStringServer: CategoriasStringServer.HISTORIA
    },
];

export const categorias_literatura: CategoriaObj[] = [
    selecciona_sub_categoria,
    {
        titulo: "common:categorias.subcategorias.literatura.autoayuda.titulo",
        alt: "common:categorias.subcategorias.literatura.autoayuda.alt",
        title: "common:categorias.subcategorias.literatura.autoayuda.title",
        icono: "/images/icons/ic_autoayuda.png",
        link: "/categoria/literatura-autoayuda",
        categoriaString: CategoriasString.LITERATURA,
        categoriaStringServer: SubCategoriasString.AUTOAYUDA
    },
    //Aventuras
    {
        titulo: "common:categorias.subcategorias.literatura.aventuras.titulo",
        alt: "common:categorias.subcategorias.literatura.aventuras.alt",
        title: "common:categorias.subcategorias.literatura.aventuras.title",
        icono: "/images/icons/ic_aventuras.png",
        link: "/categoria/literatura-aventura",
        categoriaString: CategoriasString.LITERATURA,
        categoriaStringServer: SubCategoriasString.AVENTURAS
    },
    //biografia
    {
        titulo: "common:categorias.subcategorias.literatura.biografia.titulo",
        alt: "common:categorias.subcategorias.literatura.biografia.alt",
        title: "common:categorias.subcategorias.literatura.biografia.title",
        icono: "/images/icons/ic_biografia.png",
        link: "/categoria/literatura-biografia",
        categoriaString: CategoriasString.LITERATURA,
        categoriaStringServer: SubCategoriasString.BIOGRAFIA
    },
    //Comedia
    {
        titulo: "common:categorias.subcategorias.literatura.comedia.titulo",
        alt: "common:categorias.subcategorias.literatura.comedia.alt",
        title: "common:categorias.subcategorias.literatura.comedia.title",
        icono: "/images/icons/ic_comedia.png",
        link: "/categoria/literatura-comedia",
        categoriaString: CategoriasString.LITERATURA,
        categoriaStringServer: SubCategoriasString.COMEDIA
    },
    //Drama
    {
        titulo: "common:categorias.subcategorias.literatura.drama.titulo",
        alt: "common:categorias.subcategorias.literatura.drama.alt",
        title: "common:categorias.subcategorias.literatura.drama.title",
        icono: "/images/icons/ic_drama.png",
        link: "/categoria/literatura-drama",
        categoriaString: CategoriasString.LITERATURA,
        categoriaStringServer: SubCategoriasString.DRAMA
    },
    //erotica
    {
        titulo: "common:categorias.subcategorias.literatura.erotica.titulo",
        alt: "common:categorias.subcategorias.literatura.erotica.alt",
        title: "common:categorias.subcategorias.literatura.erotica.title",
        icono: "/images/icons/ic_erotica.png",
        link: "/categoria/literatura-erotica",
        categoriaString: CategoriasString.LITERATURA,
        categoriaStringServer: SubCategoriasString.EROTICA
    },
    //fantasia
    {
        titulo: "common:categorias.subcategorias.literatura.fantasia.titulo",
        alt: "common:categorias.subcategorias.literatura.fantasia.alt",
        title: "common:categorias.subcategorias.literatura.fantasia.title",
        icono: "/images/icons/ic_fantasia.png",
        link: "/categoria/literatura-fantasia",
        categoriaString: CategoriasString.LITERATURA,
        categoriaStringServer: SubCategoriasString.FANTASIA
    },
    //ficcion
    {
        titulo: "common:categorias.subcategorias.literatura.ficcion.titulo",
        alt: "common:categorias.subcategorias.literatura.ficcion.alt",
        title: "common:categorias.subcategorias.literatura.ficcion.title",
        icono: "/images/icons/ic_ficcion.png",
        link: "/categoria/literatura-ficcion",
        categoriaString: CategoriasString.LITERATURA,
        categoriaStringServer: SubCategoriasString.FICCION
    },
    //poesia
    {
        titulo: "common:categorias.subcategorias.literatura.poesia.titulo",
        alt: "common:categorias.subcategorias.literatura.poesia.alt",
        title: "common:categorias.subcategorias.literatura.poesia.title",
        icono: "/images/icons/ic_poesia.png",
        link: "/categoria/literatura-poesia",
        categoriaString: CategoriasString.LITERATURA,
        categoriaStringServer: SubCategoriasString.POESIA
    },
    //romantico
    {
        titulo: "common:categorias.subcategorias.literatura.romantico.titulo",
        alt: "common:categorias.subcategorias.literatura.romantico.alt",
        title: "common:categorias.subcategorias.literatura.romantico.title",
        icono: "/images/icons/ic_amor.png",
        link: "/categoria/literatura-romantica",
        categoriaString: CategoriasString.LITERATURA,
        categoriaStringServer: SubCategoriasString.ROMANTICO
    },
    //Terror
    {
        titulo: "common:categorias.subcategorias.literatura.terror.titulo",
        alt: "common:categorias.subcategorias.literatura.terror.alt",
        title: "common:categorias.subcategorias.literatura.terror.title",
        icono: "/images/icons/ic_terror.png",
        link: "/categoria/literatura-terror",
        categoriaString: CategoriasString.LITERATURA,
        categoriaStringServer: SubCategoriasString.TERROR
    },
    //otros
    {
        titulo: "common:categorias.subcategorias.literatura.otros.titulo",
        alt: "common:categorias.subcategorias.literatura.otros.alt",
        title: "common:categorias.subcategorias.literatura.otros.title",
        icono: "/images/icons/ic_otros.png",
        link: "/categoria/literatura-otros",
        categoriaString: CategoriasString.LITERATURA,
        categoriaStringServer: SubCategoriasString.OTROS
    },
];

export const categorias_educacion: CategoriaObj[] = [
    selecciona_sub_categoria,
    {
        titulo: "common:categorias.subcategorias.educacion.preescolar.titulo",
        alt: "common:categorias.subcategorias.educacion.preescolar.alt",
        title: "common:categorias.subcategorias.educacion.preescolar.title",
        icono: "/images/icons/ic_preescolar.png",
        link: "/categoria/educacion-preescolar",
        categoriaString: CategoriasString.EDUCACION,
        categoriaStringServer: SubCategoriasString.PREESCOLAR
    },
    {
        titulo: "common:categorias.subcategorias.educacion.primaria.titulo",
        alt: "common:categorias.subcategorias.educacion.primaria.alt",
        title: "common:categorias.subcategorias.educacion.primaria.title",
        icono: "/images/icons/ic_primaria.png",
        link: "/categoria/educacion-primaria",
        categoriaString: CategoriasString.EDUCACION,
        categoriaStringServer: SubCategoriasString.PRIMARIA
    },
    {
        titulo: "common:categorias.subcategorias.educacion.secundaria.titulo",
        alt: "common:categorias.subcategorias.educacion.secundaria.alt",
        title: "common:categorias.subcategorias.educacion.secundaria.title",
        icono: "/images/icons/ic_eso.png",
        link: "/categoria/educacion-secundaria",
        categoriaString: CategoriasString.EDUCACION,
        categoriaStringServer: SubCategoriasString.SECUNDARIA
    },
    {
        titulo: "common:categorias.subcategorias.educacion.bachillerato.titulo",
        alt: "common:categorias.subcategorias.educacion.bachillerato.alt",
        title: "common:categorias.subcategorias.educacion.bachillerato.title",
        icono: "/images/icons/ic_bachiller.png",
        link: "/categoria/educacion-bachillerato",
        categoriaString: CategoriasString.EDUCACION,
        categoriaStringServer: SubCategoriasString.BACHILLERATO
    },
    {
        titulo: "common:categorias.subcategorias.educacion.fp.titulo",
        alt: "common:categorias.subcategorias.educacion.fp.alt",
        title: "common:categorias.subcategorias.educacion.fp.title",
        icono: "/images/icons/ic_fp.png",
        link: "/categoria/educacion-fp",
        categoriaString: CategoriasString.EDUCACION,
        categoriaStringServer: SubCategoriasString.FORMACION_PROFESIONAL
    },
    {
        titulo: "common:categorias.subcategorias.educacion.universidad.titulo",
        alt: "common:categorias.subcategorias.educacion.universidad.alt",
        title: "common:categorias.subcategorias.educacion.universidad.title",
        icono: "/images/icons/ic_universidad.png",
        link: "/categoria/educacion-universidad",
        categoriaString: CategoriasString.EDUCACION,
        categoriaStringServer: SubCategoriasString.UNIVERSIDAD
    },
    {
        titulo: "common:categorias.subcategorias.educacion.vacaciones.titulo",
        alt: "common:categorias.subcategorias.educacion.vacaciones.alt",
        title: "common:categorias.subcategorias.educacion.vacaciones.title",
        icono: "/images/icons/ic_vacaciones.png",
        link: "/categoria/educacion-vacaciones",
        categoriaString: CategoriasString.EDUCACION,
        categoriaStringServer: SubCategoriasString.VACACIONES
    },
];

export const categorias_primaria: CategoriaObj[] = [
    selecciona_nivel,
    {
        titulo: "common:categorias.subcategorias.educacion.primero.titulo",
        alt: "common:categorias.subcategorias.educacion.primero.alt",
        title: "common:categorias.subcategorias.educacion.primero.title",
        icono: "/images/icons/ic_1.png",
        link: "/categoria/educacion-primaria-primero",
        categoriaString: CategoriasString.PRIMARIA,
        categoriaStringServer: SubCategoriasString.PRIMERO
    },
    {
        titulo: "common:categorias.subcategorias.educacion.segundo.titulo",
        alt: "common:categorias.subcategorias.educacion.segundo.alt",
        title: "common:categorias.subcategorias.educacion.segundo.title",
        icono: "/images/icons/ic_2.png",
        link: "/categoria/educacion-primaria-segundo",
        categoriaString: CategoriasString.PRIMARIA,
        categoriaStringServer: SubCategoriasString.SEGUNDO
    },
    {
        titulo: "common:categorias.subcategorias.educacion.tercero.titulo",
        alt: "common:categorias.subcategorias.educacion.tercero.alt",
        title: "common:categorias.subcategorias.educacion.tercero.title",
        icono: "/images/icons/ic_3.png",
        link: "/categoria/educacion-primaria-tercero",
        categoriaString: CategoriasString.PRIMARIA,
        categoriaStringServer: SubCategoriasString.TERCERO
    },
    {
        titulo: "common:categorias.subcategorias.educacion.cuarto.titulo",
        alt: "common:categorias.subcategorias.educacion.cuarto.alt",
        title: "common:categorias.subcategorias.educacion.cuarto.title",
        icono: "/images/icons/ic_4.png",
        link: "/categoria/educacion-primaria-cuarto",
        categoriaString: CategoriasString.PRIMARIA,
        categoriaStringServer: SubCategoriasString.CUARTO
    },
    {
        titulo: "common:categorias.subcategorias.educacion.quinto.titulo",
        alt: "common:categorias.subcategorias.educacion.quinto.alt",
        title: "common:categorias.subcategorias.educacion.quinto.title",
        icono: "/images/icons/ic_5.png",
        link: "/categoria/educacion-primaria-quinto",
        categoriaString: CategoriasString.PRIMARIA,
        categoriaStringServer: SubCategoriasString.QUINTO
    },
    {
        titulo: "common:categorias.subcategorias.educacion.sexto.titulo",
        alt: "common:categorias.subcategorias.educacion.sexto.alt",
        title: "common:categorias.subcategorias.educacion.sexto.title",
        icono: "/images/icons/ic_6.png",
        link: "/categoria/educacion-primaria-sexto",
        categoriaString: CategoriasString.PRIMARIA,
        categoriaStringServer: SubCategoriasString.SEXTO
    },
];

export const categorias_secundaria: CategoriaObj[] = [
    selecciona_nivel,
    {
        titulo: "common:categorias.subcategorias.educacion.primero.titulo",
        alt: "common:categorias.subcategorias.educacion.primero.alt",
        title: "common:categorias.subcategorias.educacion.primero.title",
        icono: "/images/icons/ic_1.png",
        link: "/categoria/educacion-secundaria-primero",
        categoriaString: CategoriasString.SECUNDARIA,
        categoriaStringServer: SubCategoriasString.PRIMERO
    },
    {
        titulo: "common:categorias.subcategorias.educacion.segundo.titulo",
        alt: "common:categorias.subcategorias.educacion.segundo.alt",
        title: "common:categorias.subcategorias.educacion.segundo.title",
        icono: "/images/icons/ic_2.png",
        link: "/categoria/educacion-secundaria-segundo",
        categoriaString: CategoriasString.SECUNDARIA,
        categoriaStringServer: SubCategoriasString.SEGUNDO
    },
    {
        titulo: "common:categorias.subcategorias.educacion.tercero.titulo",
        alt: "common:categorias.subcategorias.educacion.tercero.alt",
        title: "common:categorias.subcategorias.educacion.tercero.title",
        icono: "/images/icons/ic_3.png",
        link: "/categoria/educacion-secundaria-tercero",
        categoriaString: CategoriasString.SECUNDARIA,
        categoriaStringServer: SubCategoriasString.TERCERO
    },
    {
        titulo: "common:categorias.subcategorias.educacion.cuarto.titulo",
        alt: "common:categorias.subcategorias.educacion.cuarto.alt",
        title: "common:categorias.subcategorias.educacion.cuarto.title",
        icono: "/images/icons/ic_4.png",
        link: "/categoria/educacion-secundaria-cuarto",
        categoriaString: CategoriasString.SECUNDARIA,
        categoriaStringServer: SubCategoriasString.CUARTO
    }
];

export const categorias_bachillerato: CategoriaObj[] = [
    selecciona_nivel,
    {
        titulo: "common:categorias.subcategorias.educacion.primero.titulo",
        alt: "common:categorias.subcategorias.educacion.primero.alt",
        title: "common:categorias.subcategorias.educacion.primero.title",
        icono: "/images/icons/ic_1.png",
        link: "/categoria/educacion-bachillerato-primero",
        categoriaString: CategoriasString.BACHILLERATO,
        categoriaStringServer: SubCategoriasString.PRIMERO
    },
    {
        titulo: "common:categorias.subcategorias.educacion.segundo.titulo",
        alt: "common:categorias.subcategorias.educacion.segundo.alt",
        title: "common:categorias.subcategorias.educacion.segundo.title",
        icono: "/images/icons/ic_2.png",
        link: "/categoria/educacion-bachillerato-segundo",
        categoriaString: CategoriasString.BACHILLERATO,
        categoriaStringServer: SubCategoriasString.SEGUNDO
    }
];
