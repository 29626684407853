import Grid from '@mui/material/Grid2';
import { primary, white } from '@/constants/Colors';
import React, { CSSProperties } from 'react';
import LinksCategoriasFooter from './LinksCategoriasFooter';
import BannerPaisMoneda from '../widgets/BannerPaisMoneda';
import { useTranslation } from 'next-i18next';
import CiudadesLink from './CiudadesLink';
import LinksDeInteres from './LinksDeInteres';
import FraseYCopyRight from './FraseYCopyRight';
import RedesSociales from './RedesSociales';
import Premios from './Premios';
import LinksUsadosFooter from './LinksUsadosFooter';

function FooterComponent() {

  const { t } = useTranslation();

  const style: CSSProperties = {
    backgroundColor: primary,
    color: white,
    textAlign: 'center',
    width: '100%',
  };

  return (
    <footer id="footer_page" style={style}>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 3 }} >
          <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: "column", alignItems: 'center', }}>
            <div style={{ marginTop: "40px" }}>
              <BannerPaisMoneda t={t} />
            </div>
          </div>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: "column", alignItems: 'center', }}>
            <RedesSociales />
            <Premios />
          </div>
        </Grid>

        <Grid size={{ xs: 12, md: 3 }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
            <LinksDeInteres />
          </div>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }} >
          <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: "column", alignItems: 'center', }}>
            <LinksCategoriasFooter />
            <LinksUsadosFooter />
            <CiudadesLink />
          </div>
        </Grid>
        <Grid size={{ xs: 12}}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
            <FraseYCopyRight />
          </div>
        </Grid>
      </Grid>
    </footer>
  );
}

export default FooterComponent;
