
import { GetCodigoBeseif, GetMisTransaccionesBeseif, GetProcesoCompraBeseif, GetTokenBeseif, PostBeseifObj } from '@/objects/Beseif';
import { EnviarChatObj, GetChatObj, MensajesChatObj, ObtenerChatBloqueado } from '@/objects/Chat';
import { AxiosInstance, AxiosResponse } from 'axios';

//Buscar pacientes
export default class BeseifQueries {

    //Este se utiliza para las llamadas que requieren token
    private axiosInstanceToken: AxiosInstance
    // private userData: LoginDataResponse

    constructor(axiosInstanceToken: AxiosInstance) {
        this.axiosInstanceToken = axiosInstanceToken
        //   this.userData = userData
    }

    public async obtenerTokenBeseif(postBeseifObj: PostBeseifObj): Promise<AxiosResponse<GetTokenBeseif>> {
        try {
            const url = `/php/beseif/beseif_token_lanzador_v2.php`;
            const response: AxiosResponse<GetTokenBeseif> = await this.axiosInstanceToken.post(url, postBeseifObj);
            return response;
        } catch (error: any) {
            console.log('Error en obtener token beseif', error);
            return error;
            //throw new Error(error.message);
        }
    }

    public async obtenerMisComprasBeseif(postBeseifObj: GetMisTransaccionesBeseif): Promise<AxiosResponse<GetCodigoBeseif>> {
        try {
            const url = `/php/beseif/beseif_mis_transaciones_v2.php`;
            const response: AxiosResponse<GetCodigoBeseif> = await this.axiosInstanceToken.post(url, JSON.stringify(postBeseifObj));
            return response;
        } catch (error: any) {
            console.log('Error en obtener compras Beseif', error);
            return error;
            //throw new Error(error.message);
        }
    }

    //Proceso de compra
    public async obtenerComprarBeseif(postBeseifObj: GetProcesoCompraBeseif): Promise<AxiosResponse<GetCodigoBeseif>> {
        try {
            const url = `/php/beseif/beseif_proceso_compra_lanzador_v2.php`;
            const response: AxiosResponse<GetCodigoBeseif> = await this.axiosInstanceToken.post(url, JSON.stringify(postBeseifObj));
            return response;
        } catch (error: any) {
            console.log('Error en obtener COMPRAR Beseif', error);
            return error;
            //throw new Error(error.message);
        }
    }

    public async obtenerAvisosCompraBeseif(id_user: number): Promise<AxiosResponse<number>> {
        try {
            const url = `/php/beseif/obtener_avisos_compras_beseif.php?id_user=${id_user}`;
            const response: AxiosResponse<number> = await this.axiosInstanceToken.get(url);
            return response;
        } catch (error: any) {
            console.log('Error en obtener COMPRAR Beseif', error);
            return error;
            //throw new Error(error.message);
        }
    }

}
