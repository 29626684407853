import * as Sentry from '@sentry/nextjs';

export function iniciarlizarBugsink() {

    Sentry.init({
        dsn: "https://80e2a7c1d11349f89c2dc1a31299a445@bugsinkcontrol.mgbiomed.es/1",
        integrations: [],
        tracesSampleRate: 1.0,
    });

}