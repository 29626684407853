import { LocalizacionObj } from "@/objects/Localizacion";
import { LoginDatos, LoginDataResponse, TokenData, UserDataResponse } from "@/objects/User";

enum LocalStorageKeys {
    USER_DATA = 'user_data',
    TOKEN_DATA = 'token_data',
    REFRESH_TOKEN_DATA = 'refresh_token_data',
    LOCATION_DATA = 'location_data',
    LOCATION_REFRESH = 'location_refresh',
    NOTIFICACION_MENSAJES = 'notificacion_mensajes',
    MENSAJE_APP_MOVIL = 'mensaje_app_movil'
}

//Login data:
export function guardarDatosUsuarioLogin(data: LoginDataResponse) {
    //alert(JSON.stringify(data));
    localStorage.setItem(LocalStorageKeys.USER_DATA, JSON.stringify(data.login[0] as LoginDatos));
    localStorage.setItem(LocalStorageKeys.TOKEN_DATA, (data.login[1] as TokenData).tokenAuth);
    localStorage.setItem(LocalStorageKeys.REFRESH_TOKEN_DATA, (data.login[1] as TokenData).tokenRefresh);
}

export function refrescarDatosUsuarioLogin(data: UserDataResponse) {
    localStorage.setItem(LocalStorageKeys.USER_DATA, JSON.stringify(data.user_data[0] as LoginDatos));
}

export function guardarToken(token: string) {
    localStorage.setItem(LocalStorageKeys.TOKEN_DATA, token);
}

export function guardarRefreshTokenData(token: string) {
    localStorage.setItem(LocalStorageKeys.REFRESH_TOKEN_DATA, token);
}

export function cargarDatosUsuario(): LoginDatos | null {
    const data = localStorage.getItem(LocalStorageKeys.USER_DATA);
    if (data) {
        return JSON.parse(data);
    }
    return null;
}

export function cargarToken(): string | null {
    return localStorage.getItem(LocalStorageKeys.TOKEN_DATA);
}

export function cargarRefreshTokenData(): string | null {
    return localStorage.getItem(LocalStorageKeys.REFRESH_TOKEN_DATA);
}

//Localizacion
export function guardarLocationData(data: LocalizacionObj) {
    localStorage.setItem(LocalStorageKeys.LOCATION_DATA, JSON.stringify(data));
}

export function cargarLocationData(): LocalizacionObj | null {
    const data = localStorage.getItem(LocalStorageKeys.LOCATION_DATA);
    if (data) {
        return JSON.parse(data);
    }
    return null;
}

export function removeLocationData() {
    localStorage.removeItem(LocalStorageKeys.LOCATION_DATA);
}

//Refresh
const VECES_REFRESCO = 20;

export function guardarVecesRefresco(valor: number) {
    localStorage.setItem(LocalStorageKeys.LOCATION_REFRESH, valor.toString());
}

export function cargarVecesRefresco(): number {
    const data = localStorage.getItem(LocalStorageKeys.LOCATION_REFRESH);
    if (data) {
        return Number(data);
    }
    return 0;
}

export function incrementarVecesRefrescoYComprobar() {
    let veces = cargarVecesRefresco();
    let vecesNumber = Number(veces);
    if (veces === undefined) {
        vecesNumber = 0;
    } else {
        vecesNumber = Number(veces) + 1;
    }
    guardarVecesRefresco(vecesNumber);
    if (vecesNumber > VECES_REFRESCO) {
        guardarVecesRefresco(0);
        return true;
    }
    return false;
}

//Notificacion mensajes
export function guardarNotificacionMensajes(valor: number[]) {
    localStorage.setItem(LocalStorageKeys.NOTIFICACION_MENSAJES, JSON.stringify(valor));
}

export function cargarNotificacionMensajes(): number[] {
    const data = localStorage.getItem(LocalStorageKeys.NOTIFICACION_MENSAJES);
    if (data) {
        return JSON.parse(data);
    }
    return [];
}

//Mensaje app movil
export function guardarMensajeAppMovil() {
    localStorage.setItem(LocalStorageKeys.MENSAJE_APP_MOVIL, true.toString());
}

export function cargarMensajeAppMovil(): boolean {
    const data = localStorage.getItem(LocalStorageKeys.MENSAJE_APP_MOVIL);
    if (data) {
        return data === 'true';
    }
    return false;
}

//Borrar los datos
export function removeUserData() {
    localStorage.removeItem(LocalStorageKeys.USER_DATA);
    localStorage.removeItem(LocalStorageKeys.TOKEN_DATA);
    localStorage.removeItem(LocalStorageKeys.REFRESH_TOKEN_DATA);
    localStorage.removeItem(LocalStorageKeys.LOCATION_DATA);
    localStorage.removeItem(LocalStorageKeys.LOCATION_REFRESH);
}

//Borrar todo local storage
export function removeAllLocalStorage() {
    localStorage.clear();
}
