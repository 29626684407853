import { primary } from '@/constants/Colors';
import { HeadObj } from '@/objects/Page';
import { FacebookSiteKey, GoogleSigninClientId, IDAdsense, IDGoogleAds, IDGoogleAnalytics, urlAPPAndroid, urlAPPIOS, urlWeb } from '@/util/BaseURL';
import { cargarenAPPMovil, cargarStripeHeader, noCargarAnunciosPorPath } from '@/util/Comun';
import { cargarDatosUsuario } from '@/util/LocalStorageLoaded';
import { esPremium } from '@/util/StringsUtil';
import Head from 'next/head';
import React, { useEffect } from 'react';

interface Props {
  headObj?: HeadObj;
  pathActual?: string;
}

function HeadComponent({ headObj, pathActual }: Props) {

  const logo = headObj?.imagen !== undefined ? headObj?.imagen : `${urlWeb}/images/icons/logo.png`;

  const [usuarioPremium, setUsuarioPremium] = React.useState<number>();

  useEffect(() => {
    const datosUsuario = cargarDatosUsuario();
    if (datosUsuario != undefined) {
      if (esPremium(datosUsuario.premium)) {
        setUsuarioPremium(1);
      } else {
        setUsuarioPremium(0);
      }
    } else {
      setUsuarioPremium(0);
    }
  }, []);

  return (
    <Head>
      {/* Meta común */}
      <title>{headObj?.title || 'QuieroLibros'}</title>
      <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
      <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
      {/*<meta content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" name="viewport" /> */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="description" content={headObj?.descripcionKey} />
      <meta name="keywords" content={headObj?.indexKeywords} />

      <meta httpEquiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
      <meta httpEquiv="Expires" content="0" />
      <meta httpEquiv="Pragma" content="no-cache" />

      {/* Google ID 
      <meta name="google-signin-client_id" content={GoogleSigninClientId} />*/}

      {/* Tema para chrome */}
      <meta id="tema" name="theme-color" content={primary} />
      <link rel="icon" sizes="192x192" href={logo} />
      <link rel="icon" sizes="512x512" href={logo} />
      <link rel="icon" sizes="72x72" href={logo} />
      <link rel="icon" sizes="96x96" href={logo} />
      <link rel="icon" sizes="128x128" href={logo} />
      <link rel="icon" sizes="144x144" href={logo} />
      <link rel="icon" sizes="152x152" href={logo} />
      <link rel="icon" sizes="192x192" href={logo} />

      {/* Icono para Apple */}
      <link rel="apple-touch-icon" href={logo} />
      <link rel="apple-touch-icon" sizes="57x57" href={logo} />
      <link rel="apple-touch-icon" sizes="72x72" href={logo} />
      <link rel="apple-touch-icon" sizes="76x76" href={logo} />
      <link rel="apple-touch-icon" sizes="114x114" href={logo} />
      <link rel="apple-touch-icon" sizes="120x120" href={logo} />
      <link rel="apple-touch-icon" sizes="144x144" href={logo} />
      <link rel="apple-touch-icon" sizes="152x152" href={logo} />
      <link rel="apple-touch-icon" sizes="180x180" href={logo} />
      <link rel="apple-touch-icon" sizes="192x192" href={logo} />
      <link rel="apple-touch-icon" sizes="512x512" href={logo} />


      {/* Para Twitter Card */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={headObj?.title} />
      <meta name="twitter:description" content={headObj?.descripcionKey} />
      <meta name="twitter:image" content={logo} />
      <meta name="twitter:site" content="@QuieroLibros_" />

      {/* Para Facebook y otros */}
      <meta property="fb:app_id" content={FacebookSiteKey} />
      <meta property="og:title" content={headObj?.title} />
      <meta property="og:type" content="website" />
      <meta property="og:type" content="Marketplace" />
      <meta property="og:site_name" content="QuieroLibros" />
      <meta property="og:description" content={headObj?.descripcionKey} />
      <meta property="og:image" content={logo} />
      <meta property="og:url" content={`${urlWeb}/${headObj?.linkIdioma}`} />
      <meta property="op:markup_url" content={`${urlWeb}/${headObj?.linkIdioma}`} />

      <meta name="msapplication-TileColor" content={primary} />
      <meta name="msapplication-TileImage" content={logo} />

      <link rel="manifest" href="./manifest.json" />

      {/* Canonical */}
      {headObj?.linkCanonical && <link rel="canonical" href={headObj?.linkCanonical} />}
      {/* Alternates */}
      {headObj?.alternates && headObj.alternates.map((alt, index) => (
        <link key={index} rel="alternate" href={alt.href} hrefLang={alt.hreflang} />
      ))}

      {/*PCI DSS*/}
      {cargarStripeHeader(pathActual) && (
        <meta
          http-equiv="Content-Security-Policy"
          content="
         connect-src https://checkout.stripe.com https://js.stripe.com https://beseif.com https://*.quierolibros.com https://ep2.adtrafficquality.google http://localhost:3000 ws://localhost:3000 https://bugsinkcontrol.mgbiomed.es;
         frame-src https://checkout.stripe.com https://js.stripe.com https://beseif.com https://*.quierolibros.com;
         script-src 'self' https://checkout.stripe.com https://js.stripe.com https://*.quierolibros.com https://accounts.google.com 'unsafe-eval';
         img-src 'self' https://*.stripe.com https://*.quierolibros.com data:;"
        />
      )}

      {/* Google Adsense*/}
      {(usuarioPremium !== undefined && usuarioPremium === 0) &&
        cargarenAPPMovil(pathActual) && (
          <script
            data-ad-client={IDAdsense}
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
          />
        )}

      {/* Google Analytics */}
      {cargarenAPPMovil(pathActual) && (
        <>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${IDGoogleAnalytics}`}
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${IDGoogleAnalytics}');
            `,
            }}
          />
        </>
      )}

      {/* Google Ads */}
      {cargarenAPPMovil(pathActual) && (
        <>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${IDGoogleAds}`}
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${IDGoogleAds}');
            `,
            }}
          />
        </>
      )}

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "QuieroLibros",
            "url": "https://www.quierolibros.com",
            "sameAs": [
              urlAPPAndroid,
              urlAPPIOS,
              "https://twitter.com/QuieroLibros_",
              "https://www.facebook.com/quieroLibrosAPP",
              "https://www.amazon.es/Quiero-Libros-segunda-mano/dp/B081B6Z99Q",
              "https://www.linkedin.com/company/quierolibros"
            ]
          })
        }}
      />
      {/* JSON LD descripcion de pagina */}

      {headObj?.JSONLDPage && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(headObj?.JSONLDPage)
          }}
        />
      )}

    </Head>
  );
}

export default HeadComponent;

