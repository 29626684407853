import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import { primary, red } from '../../constants/Colors';
import { textoBotones } from '../../constants/Dimens';
import { useTranslation } from 'next-i18next';
import { AppBar, IconButton, Slide, Toolbar } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

let setOpenExt: (arg: boolean) => void;
let setParamsExt: (arg: LoadWebfParams) => void;

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const LoadWebWidget: React.FC<LoadWebfParams> = () => {

    const { t } = useTranslation();

    const [open, setOpen] = React.useState(false);
    const [props, setParams] = React.useState<LoadWebfParams>({
        title: '',
        urlCargar: ''
    });

    setOpenExt = setOpen;
    setParamsExt = (params: LoadWebfParams) => {
        setParams(params);
    };


    const handleClose = () => {
        setOpen(false);
    };


    return (
        <React.Fragment>
            <Dialog style={{ zIndex: 9999 }}
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        {props.title}
                    </Toolbar>
                </AppBar>
                <iframe src={props.urlCargar} style={{ width: '100%', height: '100%' }} />
            </Dialog>
        </React.Fragment>
    );
};

export interface LoadWebfParams {
    title?: string;
    urlCargar?: string;
}

export default LoadWebWidget;

export const mostrarWebAlert = (params: LoadWebfParams) => {
    setOpenExt(true);
    setParamsExt(params);
    // Puedes acceder a los atributos pasados a través de params en lugar de props
};