import React, { useEffect, createContext, useState, ReactNode, useRef } from 'react';
import { AxiosResponse } from 'axios';
import { GetPaisMonedaResponse, LoginDatos, UserDataResponse } from '@/objects/User';
import { useServices } from './ServicesProvider';
import { cargarDatosUsuario, cargarNotificacionMensajes, guardarNotificacionMensajes, refrescarDatosUsuarioLogin, removeUserData } from '@/util/LocalStorageLoaded';
import { getCookiePais, incrementarVecesCookiePais, setCookiePais } from '@/util/CookieStorage';
import { GetMensajesNoLeidosObj, MensajesChatObj } from '@/objects/Chat';
import { mostrarAlerta } from './UtilsContext';
import { useTranslation } from 'react-i18next';
import { abrirAlertaMovil, cargarenAPPMovil, crearNotificacionHeader } from '@/util/Comun';
import { GetPuntuarUsuario, PuntuarUsuarioLibroObj } from '@/objects/Articles';
import { iniciarlizarBugsink } from '@/util/ControlErrores/Bugsink';

// Define la interfaz para las props del componente
interface NotificationContextProviderProps {
    children: ReactNode;
    pathActual?: string;
}

// En este archivo se van a cargar, los datos de usuario, los datos de mensajes, las alertas de ventas y la localizacion de la plataforma.
const NotificationContext = createContext<{ userData: UserDataResponse | null } | null>(null);

function NotificationContextProvider({ children, pathActual }: NotificationContextProviderProps) {
    const { t } = useTranslation();
    const { usersQueries, mensajesQueries, articulosQueries, beseifQueries } = useServices();

    const [userData, setUserData] = useState<UserDataResponse | null>(null);
    const notificacionesRef = useRef<number>(0);

    // const [notificacionesTotales, setNotificacionesTotales] = useState<number>(0);

    // useEffect(() => {
    //     if (notificacionesTotales > 0) {
    //         crearNotificacionHeader(notificacionesTotales);
    //     }
    // }, [notificacionesTotales]);

    useEffect(() => {
        iniciarlizarBugsink();
        // Realizar las llamadas aquí
        const datosUsuario: LoginDatos | null = cargarDatosUsuario();
        if (datosUsuario != null) {
            usersQueries?.obtenerDatosUsuario().then((response: AxiosResponse<UserDataResponse>) => {
                //alert("response: " + JSON.stringify(response.data));
                //almacena los nueevos datos de usuario

                //Comprueba si esta baneado (se devuelve con id_user -2)
                if (response.data.user_data[0].id_user == -2) {
                    removeUserData();
                    //Volver a inicio
                    window.location.href = "/";
                    return;
                }

                refrescarDatosUsuarioLogin(response.data);
                setUserData(response.data);
                if (response.data.user_data[0].pais_code != "" && response.data.user_data[0].moneda_code != "") {
                    const paisMoneda: GetPaisMonedaResponse = {
                        pais: response.data.user_data[0].pais_code,
                        moneda: response.data.user_data[0].moneda_code
                    };
                    setCookiePais(null, paisMoneda);
                } else {
                    //Si no tiene pais y moneda se le asigna el de la cookie
                    if (getCookiePais(null).pais !== "") {
                        usersQueries?.setPaisMonedaPerfil(datosUsuario.id_user, getCookiePais(null).pais, getCookiePais(null).moneda).then((response: AxiosResponse<String>) => {
                            //alert("response: " + JSON.stringify(response.data));
                        }
                        ).catch((error) => {
                            console.error('Error al guardar pais moneda perfil:', error);
                        });
                    }

                }
            }).catch((error) => {
                console.error('Error al obtener artículos:', error);
            });

            //Articulos sin puntuar
            articulosQueries?.obtenerArticulosSinPuntuar(Number(datosUsuario?.id_user)).then((response: AxiosResponse<GetPuntuarUsuario>) => {
                //alert("response: " + JSON.stringify(response.data));
                if (response.status == 200) {
                    const puntuarUsuarioLibro: PuntuarUsuarioLibroObj[] = response.data.puntuar;
                    //setNotificacionesTotales(notificacionesTotales + puntuarUsuarioLibro.length);
                    notificacionesRef.current += puntuarUsuarioLibro.length;
                    crearNotificacionHeader(notificacionesRef.current);
                }
            }
            ).catch((error: any) => {

            });

            beseifQueries?.obtenerAvisosCompraBeseif(Number(datosUsuario?.id_user)).then((response: AxiosResponse<number>) => {
                if (response.status == 200) {
                    const avisosCompra: number = response.data;
                    console.log("avisosCompra: " + avisosCompra);

                    notificacionesRef.current += avisosCompra
                    crearNotificacionHeader(notificacionesRef.current);
                }
            }
            ).catch((error: any) => {

            });

            //Para comprobar mensajes en segundo plano:
            mensajesQueries?.obtenerMensajesNoLeidos(datosUsuario.id_user).then((response: AxiosResponse<GetMensajesNoLeidosObj>) => {
                const chatSinLeer: MensajesChatObj[] = response.data.mensajes;
                //Total de mensajes no leidos
                const totalMensajesNoLeidos = chatSinLeer.length;
                //añadir los mensajes no leidos al badge

                if (totalMensajesNoLeidos > 0) {
                    // setNotificacionesTotales(notificacionesTotales + totalMensajesNoLeidos);

                    notificacionesRef.current += totalMensajesNoLeidos;
                    crearNotificacionHeader(notificacionesRef.current);

                    const cargarNotificacionMensajesLlegan: number[] = cargarNotificacionMensajes();
                    //Si no contiene el id de algun mensaje nuevo muestra la alerta
                    //funciona bien por que llegan ordenados
                    if (!cargarNotificacionMensajesLlegan.includes(response.data.mensajes[0].id_mensaje)) {
                        //muestra alerta:
                        mostrarAlerta({
                            title: t('common:opciones.informacion'),
                            content: t('contenido:chat.tienes_mensajes_nuevos', { numero_mensajes: totalMensajesNoLeidos }),
                            onAccept: () => {
                                //abre mensajes
                                window.location.href = "/dashboard/mensajes";
                            },
                            acceptText: t('contenido:chat.abrir_chat'),
                            showCancelButton: true,
                        });
                        //guarda los ids de los mensajes nuevos y los anteriores
                        chatSinLeer.forEach((chat: MensajesChatObj) => {
                            cargarNotificacionMensajesLlegan.push(chat.id_mensaje);
                        });
                        guardarNotificacionMensajes(cargarNotificacionMensajesLlegan);
                    }

                }

                //alert("response: " + JSON.stringify(response.data));
            }).catch((error) => {
                console.error('Error al obtener artículos:', error);
            });
        }
        // alert(getCookiePais(null))
        if (getCookiePais(null).pais === "" || incrementarVecesCookiePais(null) == true) {
            usersQueries?.comprobarPaisMoneda().then((response: AxiosResponse<GetPaisMonedaResponse>) => {

                if (response.data != undefined && response.data.pais != "" && response.data.moneda != "") {
                    setCookiePais(null, response.data);
                    if (datosUsuario != null) {
                        usersQueries?.setPaisMonedaPerfil(datosUsuario.id_user, response.data.pais, response.data.moneda).then((response: AxiosResponse<any>) => {
                            //alert("response: " + JSON.stringify(response.data));
                        }
                        ).catch((error) => {
                            console.error('Error al obtener artículos:', error);
                        });
                    }
                }
            }).catch((error) => {
                console.error('Error al obtener artículos:', error);
            });
        }
        //Mensaje de dispositivo movil:
        //alert(pathActual)
        //console.log("Path que llega " + pathActual)
        if (cargarenAPPMovil(pathActual)) {
            //alert("hola mundo");
            //console.log("hola mundo");
            abrirAlertaMovil(t);
        }
    }, []); // El array vacío significa que useEffect se ejecutará solo una vez, cuando el componente se monte

    // Renderizar el componente
    return (
        <NotificationContext.Provider value={{ userData }}>
            {children}
        </NotificationContext.Provider>
    );
}

export { NotificationContext, NotificationContextProvider };
