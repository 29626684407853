// context/ServiceContext.tsx
import React, { createContext, useState } from 'react';
import { InternalAxiosRequestConfig } from 'axios';

//Queries:
import ArticulosQueries from '../network/Articulos';
import AmazonQueries from '../network/Amazon';
import UsersQueries from '@/network/User';
import { cargarDatosUsuario, cargarToken } from '@/util/LocalStorageLoaded';
import MensajesQueries from '@/network/Mensajes';
import { axiosBaseURL } from '@/util/NetworkInstance';
import BeseifQueries from '@/network/Beseif';
import LocalizacionQueries from '@/network/Localizacion';
import GamificacionQueries from '@/network/Gamificacion';
import MisLeidosQueries from '@/network/MisLeidos';
import Loader from '@/components/elements/Loader';
import GoogleAPIQueries from '@/network/GoogleAPI';

export const ServicesContext = createContext<{
    articulosQueries?: ArticulosQueries
    usersQueries?: UsersQueries
    amazonQueries?: AmazonQueries
    mensajesQueries?: MensajesQueries
    beseifQueries?: BeseifQueries
    localizacionQueries?: LocalizacionQueries
    gamificationQueries?: GamificacionQueries
    misLeidosQueries?: MisLeidosQueries
    googleAPIQueries?: GoogleAPIQueries
} | undefined>(undefined);

export const ServicesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    //Para loader
    const [loading, setLoading] = useState<{ [key: string]: boolean }>({});
    const toggleLoading = (instance: string, status: boolean) => {
        setLoading(prevLoading => ({ ...prevLoading, [instance]: status }));
    };

    /*const { userData, verifyToken, refreshToken, refreshUserData } = useContext(AuthContext) as {
        userData: LoginDataResponse,
        verifyToken: (accessToken: string, refreshToken: string) => boolean,
        refreshToken: () => Promise<AxiosResponse<TokenRefreshResponse>>,
        refreshUserData: (auth_token: string, refresh_token: string) => void,
    };*/
    //Instancia para llamadas sin token
    const axiosInstance = axiosBaseURL()
    const axiosInstanceToken = axiosBaseURL()
    // Llamadas sin token
    axiosInstance.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
        toggleLoading('axiosInstance', true);
        //console.log(`Request to :${config.url}: `, config)
        //configuracion de la url
        const fullUrl = config.baseURL! + config.url;

        //console.log('Full Request URL:', fullUrl);
        config.headers["Content-Type"] = "application/x-www-form-urlencoded";

        return config;
    }, function (error) {
        toggleLoading('axiosInstance', false);
        return Promise.reject(error);
    });

    // Llamadas con token
    axiosInstanceToken.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
        toggleLoading('axiosInstanceToken', true);

        //Si no existe el token quiere decir que no hay iniciado sesion entonces tienes que redirigir al login
        if (cargarDatosUsuario() == null || cargarToken() == null) {
            // window.location.href = "/login";
            //deten la llamada
            return Promise.reject("No hay token")
        }
        //config.headers["X-AUTH-TOKEN"] = JSON.stringify(cargarToken())
        config.headers!.Authorization = `${cargarToken()}`
        //alert(config.headers!.Authorization);
        //configuracion de la url
        const fullUrl = config.baseURL! + config.url;

        //alert("fullUrl: " + fullUrl);
        //console.log('Full Request URL:', fullUrl);
        //Comprueba prrimero si existe la cabecera content-type y si no la crea
        if (!config.headers["Content-Type"]) {
            config.headers["Content-Type"] = "application/json";
        }

        return config;
    }, function (error) {
        toggleLoading('axiosInstanceToken', false);
        return Promise.reject(error);
    });

    // Decrementa el contador de solicitudes pendientes al recibir una respuesta
    axiosInstance.interceptors.response.use((response) => {
        toggleLoading('axiosInstance', false);
        return response;
    }, (error) => {
        toggleLoading('axiosInstance', false);
        return Promise.reject(error);
    });

    axiosInstanceToken.interceptors.response.use((response) => {
        toggleLoading('axiosInstanceToken', false);
        return response;
    }, (error) => {
        toggleLoading('axiosInstanceToken', false);
        return Promise.reject(error);
    });


    /*async function checkTokenAndRefreshIdNeeded(config: InternalAxiosRequestConfig) {
        if (!verifyToken(userData.auth_token, userData.refresh_token)) {
            console.log("token bad")
            const newTokenResponse = await refreshToken();
            const newToken = newTokenResponse.data;
            refreshUserData(newToken.auth_token, newToken.refresh_token)

            config.headers!.Authorization = `Bearer ${newToken.auth_token}`
        }
        return config
    }*/

    return (
        <ServicesContext.Provider
            value={
                {
                    articulosQueries: new ArticulosQueries(axiosInstance, axiosInstanceToken),
                    usersQueries: new UsersQueries(axiosInstance, axiosInstanceToken),
                    amazonQueries: new AmazonQueries(axiosInstance),
                    mensajesQueries: new MensajesQueries(axiosInstanceToken),
                    beseifQueries: new BeseifQueries(axiosInstanceToken),
                    localizacionQueries: new LocalizacionQueries(axiosInstanceToken),
                    gamificationQueries: new GamificacionQueries(axiosInstanceToken),
                    misLeidosQueries: new MisLeidosQueries(axiosInstanceToken),
                    googleAPIQueries: new GoogleAPIQueries(),
                }
            }>
            {children}
            {(loading['axiosInstance'] || loading['axiosInstanceToken']) && <Loader />}
        </ServicesContext.Provider>
    );
};

//Hook personalizado
export const useServices = () => {
    const context = React.useContext(ServicesContext);
    if (context === undefined) {
        throw new Error('useServices must be used within a ServicesProvider');
    }
    return context;
};

