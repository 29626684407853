import axios from 'axios';
import { baseURL, baseURLGoogleAPI, baseURLImages, baseURLNominatim } from './BaseURL';

export function axiosBaseURL() {
    return axios.create({
        baseURL: baseURL,
    });
}

export function axiosImagesURL() {
    return axios.create({
        baseURL: baseURLImages,
    });
}

export function axiosBaseURLGoogle() {
    return axios.create({
        baseURL: baseURLGoogleAPI,
    });
}

export function axiosBaseURLNominatim() {
    return axios.create({
        baseURL: baseURLNominatim,
    });
}

