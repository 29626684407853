import React from 'react';
import LinkCustom from '../elements/LinkCustom';
import { useTranslation } from 'next-i18next';
import { white } from '@/constants/Colors';
import TituloSeccionH2 from '../elements/TituloSeccionH2';
import { textos } from '@/constants/Dimens';
import { categorias_bachillerato, categorias_defecto, categorias_educacion, categorias_literatura, categorias_primaria, categorias_secundaria } from '@/objects/Categorias';
import { CategoriasStringServer, SubCategoriasString } from '@/objects/Articles';

function LinksCategoriasFooter() {
  const { t } = useTranslation();

  return (
    <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: "100%" }}>
      <TituloSeccionH2 fontSize={textos} color={white} texto={t(`common:articulos.categorias_libros`)} />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: "100%", paddingLeft: "10px" }}>
        {categorias_defecto.map((categoria, index) => (
          <div key={categoria.categoriaStringServer + "_footer_" + index}>
            <LinkCustom
              color={white}
              href={`${categoria.link}`}
              title={t(`common:articulos.libros_de_segunda_mano_de`, { categoria: t(categoria.titulo) })}
            >
              {(categoria.categoriaStringServer == CategoriasStringServer.INFANTIL ? t(`common:categorias.libros`) : t(`common:categorias.libros_de`)) + t(categoria.titulo)}
            </LinkCustom>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: "100%", paddingLeft: "10px" }}>
              {categoria.categoriaStringServer == CategoriasStringServer.LITERATURA && (
                categorias_literatura.map((subcategoria, index) => (
                  //salta el primero
                  index > 0 && (
                    <LinkCustom
                      key={subcategoria.categoriaStringServer + "_sub_footer_" + index}
                      color={white}
                      href={`${subcategoria.link}`}
                      title={t(`common:articulos.libros_de_segunda_mano_de`, { categoria: t(subcategoria.titulo) })}
                    >
                      {t(`common:categorias.libros_de`) + t(subcategoria.titulo)}
                    </LinkCustom>
                  )
                ))
              )}
              {categoria.categoriaStringServer == CategoriasStringServer.EDUCACION && (
                categorias_educacion.map((subcategoria, index) => (
                  //salta el primero
                  index > 0 && (
                    <div key={subcategoria.categoriaStringServer + "_sub__edu_footer_" + index}>
                      <LinkCustom
                        color={white}
                        href={`${subcategoria.link}`}
                        title={t(`common:articulos.libros_de_segunda_mano_de`, { categoria: t(subcategoria.titulo) })}
                      >
                        {t(`common:categorias.libros_de`) + t(subcategoria.titulo)}
                      </LinkCustom>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: "100%", paddingLeft: "10px" }}>
                        {subcategoria.categoriaStringServer == SubCategoriasString.PRIMARIA && (
                          categorias_primaria.map((subcategoria, index) => (
                            //salta el primero
                            index > 0 && (
                              <LinkCustom
                                key={subcategoria.categoriaStringServer + "_sub__primaria_footer_" + index}
                                color={white}
                                href={`${subcategoria.link}`}
                                title={t(`common:articulos.libros_de_segunda_mano_de`, { categoria: t(subcategoria.titulo) })}
                              >
                                {t(`common:categorias.libros_de`) + t(subcategoria.titulo) + t(`common:categorias.de`) + t(`common:categorias.subcategorias.educacion.primaria.titulo`)}
                              </LinkCustom>
                            )
                          ))
                        )}
                        {subcategoria.categoriaStringServer == SubCategoriasString.SECUNDARIA && (
                          categorias_secundaria.map((subcategoria, index) => (
                            //salta el primero
                            index > 0 && (
                              <LinkCustom
                                key={subcategoria.categoriaStringServer + "_sub__primaria_footer_" + index}
                                color={white}
                                href={`${subcategoria.link}`}
                                title={t(`common:articulos.libros_de_segunda_mano_de`, { categoria: t(subcategoria.titulo) })}
                              >
                                {t(`common:categorias.libros_de`) + t(subcategoria.titulo) + t(`common:categorias.de`) + t(`common:categorias.subcategorias.educacion.secundaria.titulo`)}
                              </LinkCustom>
                            )
                          ))
                        )}
                        {subcategoria.categoriaStringServer == SubCategoriasString.BACHILLERATO && (
                          categorias_bachillerato.map((subcategoria, index) => (
                            //salta el primero
                            index > 0 && (
                              <LinkCustom
                                key={subcategoria.categoriaStringServer + "_sub__primaria_footer_" + index}
                                color={white}
                                href={`${subcategoria.link}`}
                                title={t(`common:articulos.libros_de_segunda_mano_de`, { categoria: t(subcategoria.titulo) })}
                              >
                                {t(`common:categorias.libros_de`) + t(subcategoria.titulo) + t(`common:categorias.de`) + t(`common:categorias.subcategorias.educacion.bachillerato.titulo`)}
                              </LinkCustom>
                            )
                          ))
                        )}
                      </div>
                    </div>
                  )
                ))
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default LinksCategoriasFooter;
