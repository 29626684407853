// pages/_app.tsx
import React from 'react';
import { AppProps } from 'next/app';
import { ServicesProvider } from '@/context/ServicesProvider';
import { UtilsProvider } from '@/context/UtilsContext';
import HeaderProvider from '@/components/headers/HeadersProvider';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { appWithTranslation } from 'next-i18next';
// import { useRouter } from 'next/router';

import { NotificationContextProvider } from '@/context/NotificationContext';

//CSS:
import '../css/comun.css';
import '../css/animations.css';
import '../css/header.css';
import '../css/libro.css';
import '../css/efectos/Snowfall.css'; // CSS para animaciones

import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleSigninClientId } from '@/util/BaseURL';
import { darkTheme, lightTheme } from '@/components/elements/Theme';
import {  EventsContextProvider } from '@/context/EventsContext';

const MyApp: React.FC<AppProps> = ({ Component, pageProps }) => {
  //console.log(JSON.stringify(pageProps))
  //const router = useRouter();
  //const pathActual = router.pathname;
  //const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const { headerObj, pathActual, ...rest } = pageProps;

  return (
    <ThemeProvider theme={lightTheme/*prefersDarkMode ? darkTheme : lightTheme*/}>
      <EventsContextProvider>
        <GoogleOAuthProvider clientId={GoogleSigninClientId}>
          <ServicesProvider>
            <UtilsProvider headObj={headerObj} pathActual={pathActual}>
              <NotificationContextProvider pathActual={pathActual}>
                <HeaderProvider />
                <CssBaseline />
                <main style={{ marginTop: "60px", paddingTop: "20px", paddingBottom: "16px" }}>
                  <Component pathActual={pathActual} {...rest} />
                </main>
              </NotificationContextProvider>
            </UtilsProvider>
          </ServicesProvider>
        </GoogleOAuthProvider>
      </EventsContextProvider>
    </ThemeProvider>
  );
};

export default appWithTranslation(MyApp);