// components/ArticleList.tsx
import React, { useContext } from 'react';
import { TFunction } from 'next-i18next';
import { GetPaisMonedaResponse, LoginDatos } from '@/objects/User';
import { getCookiePais, setCookiePais } from '@/util/CookieStorage';
import Texto from '../elements/Texto';
import { Card } from '@mui/material';
import { filtraCodigoPais } from '@/util/StringsUtil';
import { filtarMoneda } from '@/util/Monedas';
import Boton from '../elements/Boton';
import { AxiosResponse } from 'axios';
import { cargarDatosUsuario } from '@/util/LocalStorageLoaded';
import { ServicesContext, useServices } from '@/context/ServicesProvider';
import LoopIcon from '@mui/icons-material/Loop';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import { primary, purple, rojoerror, white } from '@/constants/Colors';
import SelectBasico, { OptionSelect } from '../elements/SelectBasico';
import { textosPeque } from '@/constants/Dimens';
import CancelIcon from '@mui/icons-material/Cancel';
import RestorePageIcon from '@mui/icons-material/RestorePage';

interface Props {
  t: TFunction;
}


const BannerPaisMoneda: React.FC<Props> = ({ t }) => {

  const { usersQueries } = useServices();

  const [getPaisMoneda, setPaisMoneda] = React.useState<GetPaisMonedaResponse>();
  const [cargando, setCargando] = React.useState<boolean>(false);

  const [modoManual, setModoManual] = React.useState<boolean>(false);

  const manualRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const getPaisMonedaResponse: GetPaisMonedaResponse = getCookiePais(null);
    setPaisMoneda(getPaisMonedaResponse);
  }, []);

  var paisesMonedas: OptionSelect[] = [
    { text: t("common:paises.argentina"), value: "AR,ARS" },
    { text: t("common:paises.espana"), value: "ES,EUR" },
    { text: t("common:paises.mexico"), value: "MX,MXN" },
    { text: t("common:paises.colombia"), value: "CO,COP" },
    { text: t("common:paises.peru"), value: "PE,PEN" },
    { text: t("common:paises.chile"), value: "CL,CLP" },
    { text: t("common:paises.venezuela"), value: "VE,VEF" },
    { text: t("common:paises.ecuador"), value: "EC,USD" },
    { text: t("common:paises.costa_rica"), value: "CR,CRC" },
    { text: t("common:paises.guatemala"), value: "GT,GTQ" },
    { text: t("common:paises.panama"), value: "PA,PAB" },
    { text: t("common:paises.uruguay"), value: "UY,UYU" },
    { text: t("common:paises.paraguay"), value: "PY,PYG" },
    { text: t("common:paises.brasil"), value: "BR,BRL" },
    { text: t("common:paises.bolivia"), value: "BO,BOP" },
    { text: t("common:paises.cuba"), value: "CU,CUC" },
    { text: t("common:paises.haiti"), value: "HT,HTG" },
    { text: t("common:paises.guinea_ecuatorial"), value: "GQ,GTQ" },
    { text: t("common:paises.nicaragua"), value: "NI,NIO" },
    { text: t("common:paises.el_salvador"), value: "SV,SVC" },
    { text: t("common:paises.honduras"), value: "HN,HNL" },
    { text: t("common:paises.puerto_rico"), value: "PR,USD" },
    { text: t("common:paises.republica_dominicana"), value: "DO,DOP" },
    { text: t("common:paises.francia"), value: "FR,EUR" },
    { text: t("common:paises.portugal"), value: "PT,EUR" },
  ];

  function seleccionarPaisPorMoneda(value: string) {
    //alert("value: " + value)
    var paisMoneda = value.split(",");
    const paisMonedaResponse: GetPaisMonedaResponse = {
      pais: paisMoneda[0],
      moneda: paisMoneda[1]
    };
    setPaisMoneda(paisMonedaResponse);
    establecerPaisMoneda(paisMonedaResponse);
  }

  function cambiarModo() {
    setModoManual(!modoManual);
  }

  React.useEffect(() => {
    // manualRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [modoManual]);

  function refrescarMonedaPais() {
    setCargando(true);
    usersQueries?.comprobarPaisMoneda().then((response: AxiosResponse<GetPaisMonedaResponse>) => {
      establecerPaisMoneda(response.data);
      seleccionarPaisPorMoneda(response.data.pais + "," + response.data.moneda);
      setCargando(false);
    }).catch((error) => {
      setCargando(false);
      console.error('Error al obtener artículos:', error);
    });
  }

  function establecerPaisMoneda(paisMoneda: GetPaisMonedaResponse) {
    const datosUsuario: LoginDatos | null = cargarDatosUsuario();
    setCookiePais(null, paisMoneda);
    if (datosUsuario != null) {
      usersQueries?.setPaisMonedaPerfil(datosUsuario.id_user, paisMoneda.pais, paisMoneda.moneda).then((response: AxiosResponse<any>) => {
        //alert("response: " + JSON.stringify(response.data));
      }
      ).catch((error) => {
        console.error('Error al obtener artículos:', error);
      });
    }
  }

  //alert(JSON.stringify(articles)) 
  return (
    (getPaisMoneda !== undefined && (
      <Card style={{ width: "250px", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: "10px" }}>
        <div style={{ display: "flex", flexDirection: 'column', alignItems: 'center', textAlign: 'center', width: '100%' }}>
          <Texto>{t("common:pais_moneda.esta_conectado_a_quiero_libros")}</Texto>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <Texto fontWeight="300">{cargando ? t("common:opciones.cargando_puntos") : filtraCodigoPais(getPaisMoneda.pais, t)}</Texto>
          <Texto>{t("common:pais_moneda.moneda")}</Texto>
          <Texto fontWeight="300">
            {
              cargando ? t("common:opciones.cargando_puntos") :
                (getPaisMoneda.moneda != "" && getPaisMoneda.moneda != undefined)
                  ? `${filtarMoneda(getPaisMoneda.moneda)} (${getPaisMoneda.moneda})`
                  : t("common:pais_moneda.todas_monedas")
            }
          </Texto>
          <Boton width='120px' startIcon={<LoopIcon />} height='25px' onClick={() => { refrescarMonedaPais() }} texto={t("common:pais_moneda.refrescar")} />
          <Boton
            color={white}
            width='120px'
            colorTexto={modoManual ? rojoerror : primary}
            startIcon={modoManual ? <CancelIcon /> : <LocationCityIcon />}
            height='25px'
            onClick={cambiarModo}
            texto={modoManual ? t("common:opciones.cerrar") : t("common:pais_moneda.cambiar_manualmente")}
          />

          <Texto fontSize={textosPeque}>{t("common:pais_moneda.refresca_para_aplicar_cambio")}</Texto>
          <Boton color={purple} width='120px' startIcon={<RestorePageIcon />} height='25px' onClick={() => { window.location.reload(); }} texto={t("common:pais_moneda.recargar")} />

          <div style={{ width: "100px" }} ref={manualRef}>
            {modoManual && (
              <SelectBasico
                label={t("common:pais_moneda.seleccionar_pais_moneda")}
                value={getPaisMoneda?.pais + "," + getPaisMoneda?.moneda}
                onSelectOption={seleccionarPaisPorMoneda}
                options={paisesMonedas}
              />
            )}
          </div>
        </div>
      </Card>
    ))
  );
};

export default BannerPaisMoneda;
